export const heMessages: Record<string, string> = {
  'Total revenue for this year': 'סה״כ הכנסות לשנה זו',
  'Future Appointments': 'פגישות עתידיות',
  'Total Appointments': 'סה״כ פגישות',
  'Appointments Per Treatment': 'פגישות לפי טיפול',
  'Appointments Per Employee': 'פגישות לפי עובד',
  'Revenue This Month': 'הכנסות החודש הזה',
  'Insights Dashboard': 'לוח תובנות עיסקיות',
  'Appointments per month on average': 'ממוצע פגישות לפי חודש',
  'Treatments Revenue': 'הכנסות לפי טיפול',
  Calendar: 'יומן תורים',
  Employees: 'עובדים',
  employee: 'עובד',
  Profile: 'פרופיל',
  Treatments: 'טיפולים',
  treatments: 'טיפולים',
  Disable: 'השבת',
  Business: 'עסק',
  Reports: 'דוחות',
  Support: 'עזרה',
  Privacy: 'פרטיות',
  'Terms of Use': 'תנאי שימוש',
  Today: 'היום',
  Month: 'חודש',
  Week: 'שבוע',
  Day: 'יום',
  List: 'רשימה',
  Name: 'שם',
  Phone: 'טלפון',
  Approve: 'אשר',
  Call: 'התקשר',
  To: 'ל',
  'You have no notifications': 'אין לך התראות חדשות',
  'Your Name': 'השם שלך',
  'Appointment Details': 'פרטי פגישה',
  'Date & Time': 'תאריך ושעה',
  "Customer doesn't exist": 'הלקוח אינו קיים',
  'Your business description': 'תיאור העסק שלך',
  'Created By': 'נוצר על ידי',
  'No report for this year': 'אין דוחות לשנה הזו',
  'Delete Appointment?': 'למחוק את הפגישה?',
  "Are you sure? You can't undo this action afterwards.": 'האם אתה בטוח? לא תוכל לבטל פעולה זו לאחר מכן.',
  'Create Treatment': 'צור טיפול',
  'Description must be provided': 'יש לספק תיאור',
  'Name must be provided': 'יש לספק שם',
  'Category Name': 'שם הקטגוריה',
  'Category Description': 'תיאור הקטגוריה',
  'Name is too long': 'השם ארוך מדי',
  'Description is too long': 'התיאור ארוך מדי',
  'Name already exists': 'השם כבר קיים',
  'Category Updated Successfully': 'הקטגוריה עודכנה בהצלחה',
  'Category already exists': 'הקטגוריה כבר קיימת',
  'successfully created employee, be aware that this category still has no treatments.': 'העובד נוצר בהצלחה, שים לב שלקטגוריה זו עדיין אין טיפולים.',
  'Category Created Successfully': 'הקטגוריה נוצרה בהצלחה',
  Minutes: 'דקות',
  'OPEN = treatment is open for schedule': 'פתוח = הטיפול פתוח לתזמון',
  Block: 'חסום',
  'Ops Error': 'שגיאה',
  'Employees related': 'עובדים קשורים',
  'TAKE ACTION = any appointment will be settled with this treatment will be notified to you to approve':
    'נדרש פעולה = כל פגישה שתיקבע עם טיפול זה תודיע לך לאישור',
  'CLOSE = treatment is close for schedule': 'סגור = הטיפול סגור לתזמון',
  'Take Action': 'נדרש פעולה',
  'Schedule Option': 'אפשרות תזמון',
  'Time of treatment': 'זמן הטיפול',
  'Pick time': 'בחר זמן',
  Price: 'מחיר',
  'Min. 8 characters': 'מינימום 8 תווים',
  'Enter a valid price': 'הזן מחיר תקין',
  'Please provide a name': 'אנא ספק שם',
  'Create New Category': 'צור קטגוריה חדשה',
  'Add New Category': 'הוסף קטגוריה חדשה',
  'Be aware that if that treatment has appointments related, it can not be deleted': 'שים לב שאם לטיפול זה יש פגישות קשורות, הוא לא יכול להימחק',
  'are you sure you want to delete this treatment?': 'האם אתה בטוח שברצונך למחוק את הטיפול הזה?',
  "You don't have any Category, create new Category": 'אין לך שום קטגוריה, צור קטגוריה חדשה',

  Edit: 'ערוך',
  Female: 'נקבה',
  Male: 'זכר',
  'Treatments related': 'טיפולים קשורים',
  Delete: 'מחק',
  'Employee activated successfully': 'העובד הופעל בהצלחה',
  'Employee Created Successfully': 'העובד נוצר בהצלחה',
  'successfully deleted employee, be aware that this employee might have appointments related to it.':
    'העובד נמחק בהצלחה, שים לב שלעובד זה עשויות להיות פגישות קשורות.',
  'Phone number already exists': 'מספר הטלפון כבר קיים',
  'are you sure you want to disable this employee?': 'האם אתה בטוח שברצונך להשבית את העובד הזה?',
  'Ops error occurred try again later': 'אירעה שגיאה, נסה שוב מאוחר יותר',
  'Employee Created Error': 'שגיאה ביצירת העובד',
  'Please provide a first name and last name.': 'אנא ספק שם פרטי ושם משפחה.',
  'Full Name': 'שם מלא',
  'Employee Name': 'שם העובד',
  'Select treatments': 'בחר טיפולים',
  'Enter a valid phone': 'הזן מספר טלפון תקין',
  Cancel: 'ביטול',
  'successfully created employee, be aware that this employee might have appointments related to it.':
    'העובד נוצר בהצלחה, שים לב שלעובד זה עשויות להיות פגישות קשורות.',
  'Employee Deleted Error': 'שגיאה במחיקת העובד',
  'There was an error deleting the employee try again later': 'אירעה שגיאה במחיקת העובד, נסה שוב מאוחר יותר',
  'Have no treatment': 'אין טיפולים',
  'Create Employee': 'צור עובד',
  'Employee Email': 'אימייל העובד',
  Gender: 'מין',
  'Employee Updated Successfully': 'העובד עודכן בהצלחה',
  'successfully updated employee, be aware that this employee might have appointments related to it.':
    'העובד עודכן בהצלחה, שים לב שלעובד זה עשויות להיות פגישות קשורות.',

  Close: 'סגור',
  Open: 'פתוח',
  Sunday: 'א׳',
  Monday: 'ב׳',
  Tuesday: 'ג׳',
  Wednesday: 'ד׳',
  Thursday: 'ה׳',
  Friday: 'ו׳',
  Saturday: 'שבת',
  'Your Business': 'את העסק שלך',
  Email: 'אימייל',
  Address: 'כתובת',
  Color: 'צבע',
  Type: 'סוג',
  Description: 'תיאור',
  'You must choose a day of business': 'חובה לבחור יום עסקים',
  'Error Occurred Maybe the Phone/Email is already exists': 'אירעה שגיאה, אולי הטלפון/אימייל כבר קיימים',
  'Opening Hours': 'שעות פתיחה',
  'Save changes': 'שמור שינויים',
  'Edit Business': 'ערוך עסק',
  'Basic Info': 'מידע בסיסי',
  'Color & Type': 'צבע וסוג',
  'Business Operating Hours': 'שעות פעילות העסק',
  'Creating New Business!': 'יוצר עסק חדש!',
  'Please add Business Name': 'אנא הוסף שם עסק',
  'Business Address': 'כתובת העסק',
  'some-address': 'רחוב בייקר 14 ניו יורק סיטי',
  'Business Color': 'צבע העסק',
  'Business Type': 'סוג העסק',
  'Select your business type': 'בחר את סוג העסק שלך',
  'Barber Shop': 'מספרה',
  'Beauty Salon': 'מכון יופי',
  'Nail Salon': 'מכון ציפורניים',
  Spa: 'ספא',
  'Fitness Center': 'מרכז כושר',
  'Yoga Studio': 'סטודיו ליוגה',
  'Massage Therapy': 'טיפול בעיסוי',
  'Consulting Firm': 'חברת ייעוץ',
  'Web Design Agency': 'סוכנות עיצוב אתרים',
  'Accounting Services': 'שירותי ראיית חשבון',
  'Legal Services': 'שירותים משפטיים',
  'Real Estate Agency': 'סוכנות נדל"ן',
  'Travel Agency': 'סוכנות נסיעות',
  'Auto Repair Shop': 'מוסך לתיקון רכבים',
  Other: 'אחר',

  January: 'ינואר',
  Notifications: 'התראות',
  February: 'פברואר',
  March: 'מרץ',
  April: 'אפריל',
  May: 'מאי',
  June: 'יוני',
  July: 'יולי',
  August: 'אוגוסט',
  September: 'ספטמבר',
  October: 'אוקטובר',
  November: 'נובמבר',
  December: 'דצמבר',
  for: 'ל',
  at: 'ב',
  'Mark all read': 'סמן הכל כנקרא',

  APPOINTMENT_CREATE: 'פגישה חדשה',
  APPOINTMENT_UPDATE: 'עדכון פגישה',
  APPOINTMENT_DELETE: 'ביטול פגישה',
  APPOINTMENT_TAKE_ACTION: 'פעולה',

  'Created new appointment': 'פגישה חדשה',
  'Updated appointment': 'עודכנה הפגישה',
  'Deleted the appointment': 'הפגישה בוטלה',
  'Created aew appointment that needs to take an action': 'נוצרה פגישה חדשה הדורשת פעולה',

  'By Employees': 'עובדים',
  'No Employees Assigned': 'אין עובדים שהוקצו',
  'First Name': 'שם פרטי',
  'Log out': 'התנתק',
  'Logged In Using': 'התחברת באמצעות',
  'Edit Profile': 'ערוך פרופיל',
  'Last Name': 'שם משפחה',
  'Month Report': 'דוח חודשי',
  'Business is close': 'העסק סגור',
  Settings: 'הגדרות',
  Weekends: 'סופ״ש',
  Status: 'סטטוס',
  appointments: 'פגישות',
  Appointments: 'פגישות',
  Active: 'פעיל',
  Inactive: 'לא פעיל',

  'Updated Successfully': 'עודכן בהצלחה',
  'You have updated your info successfully': 'עדכנת את המידע שלך בהצלחה',
  'Updated Failed': 'העדכון נכשל',
  'Ops error occurred': 'אירעה שגיאה',
  'Phone Number is not valid': 'מספר הטלפון אינו תקין',
  'Update Appointment': 'עדכון פגישה',
  'Updating Appointment': 'מעדכן פגישה',
  'Creating New Appointment! 🎉': 'מייצר פגישה חדשה 🎉',
  'Create Appointment at': 'יצירת פגישה ב',
  'Customer And Treatment': 'לקוח וטיפול',
  'Add your comment here': 'הוסף כאן את ההערה שלך',
  'Free Text': 'טקסט חופשי',
  'Select Customer': 'בחר לקוח',
  'Select Employee': 'בחר עובד',
  'Select treatment': 'בחר טיפול',
  'An Error occurred, maybe the employee is already busy': 'קראה שגיאה, יש אפשרות שהעובד כבר עסוק',
  'No employee can give all those treatments': 'אין עובד שיכול להת את כל הטיפולים שבחרת',
  "You don't have any unread notifications": 'אין לך התראות שלא נקראו',
  Employee: 'עובד',
  Comment: 'הערה',
  Customer: 'לקוח',
  Morning: 'בוקר',
  Noon: 'אחה״צ',
  Evening: 'ערב',
  Treatment: 'טיפול',
  Time: 'זמן',
  Create: 'צור',
  Update: 'עדכן',
  Back: 'חזור',
  Next: 'הבא',

  'Change appointment date?': 'לשנות את תאריך הפגישה?',
  'Are you sure you want to change to appointment date?': 'האם אתה בטוח שברצונך לשנות את תאריך הפגישה?',
  From: 'מ',
  Change: 'שנה',
  'An Error occurred, maybe the employee is already busy? Or maybe Business is closed that day?':
    'אירעה שגיאה, אולי העובד כבר תפוס? או אולי העסק סגור באותו יום?',
  'Sign In': 'התחברות',
  'Reset Password': 'איפוס סיסמה',
  'Forgot your password?': 'שכחת את הסיסמה?',
  'Create an Account': 'צור חשבון',
  'Sign in with Google': 'התחבר עם גוגל',
  'Not registered yet?': 'עדיין לא רשום?',
  'Sign in with Facebook': 'התחבר עם פייסבוק',
  'Email or Password are invalid': 'האימייל או הסיסמה אינם חוקיים',
  'Enter your email and password to sign in!': 'הכנס את האימייל והסיסמה שלך כדי להתחבר!',
  or: 'או',
  Password: 'סיסמה',

  'Create account': 'צור חשבון',
  'Email Recovery': 'שחזור אימייל',
  'Know the password?': 'יודע את הסיסמה?',
  'Email does not exist': 'האימייל לא קיים',
  'Account Recovery': 'שחזור חשבון',
  'Enter your email to recover your account!': 'הכנס את האימייל שלך כדי לשחזר את החשבון שלך!',
  'Sign in': 'התחבר',
  'Sign Up': 'הירשם',
  Closed: 'סגור',
  'Already have an account?': 'כבר יש לך חשבון?',
  'Confirm Password': 'אשר סיסמה',
  'Your Full Name': 'שמך המלא',
  Refresh: 'רענן',
  'Lets create a new account for you': 'בוא ניצור לך חשבון חדש',

  'Please add Street Address': 'אנא הוסף כתובת רחוב',
  Optional: 'אופציונלי',
  City: 'עיר',
  Role: 'תפקיד',
  'State (Optional)': 'מדינה (אופציונלי)',
  Country: 'מדינה',
  'Please add City': 'אנא הוסף עיר',
  'Please add Country': 'אנא הוסף מדינה',
  'Please add ZIP Code': 'אנא הוסף מיקוד',
  'Please enter a valid ZIP Code': 'אנא הכנס מיקוד תקין',
  'ZIP Code': 'מיקוד',
  'Treatment Example': 'טיפול לדוגמא',
  'Please add State': 'אנא הוסף מדינה',
  'Street Address': 'כתובת רחוב',
  'Street Address & number': 'כתובת רחוב ומספר',
  'Select Employees': 'בחר עובדים',
  'Your user is not active': 'המשתמש שלך אינו פעיל',
  'You will need to activate your account before continuing': 'יהיה עליך להפעיל את החשבון שלך לפני שתמשיך',
  'Profile Page': 'עמוד פרופיל',
  month: 'חודש',
  'Full Analytics': 'דף תובנות עיסקיות',
  Users: 'משתמשים',
  Invoices: 'חשבוניות',
  'Automated Reminder': 'תזכורת אוטומטית',
  'Change Plan': 'שנה תוכנית',
  'Upgrade the plan for your business': 'שדרג את התוכנית לעסק שלך',
  'Call Us': 'התקשר אלינו',
  'Choose your plan': 'בחר את התוכנית בשבילך',
  'Create plan': 'צור תוכנית',
  'Pick a plan': 'בחר תוכנית',

  'Error creating plan for your business': 'שגיאה ביצירת תוכנית לעסק שלך',
  'We encountered an error creating plan for your business, try again later': 'נתקלנו בשגיאה ביצירת תוכנית לעסק שלך, נסה שוב מאוחר יותר',
  'Your plan was set successfully, congratulations!': 'התוכנית שלך נקבעה בהצלחה, מזל טוב!',
  'Your business plan was set successfully you can now log in and enjoy (EasyBook)':
    'תוכנית העסק שלך נקבעה בהצלחה, עכשיו תוכל להתחבר וליהנות (EasyBook)',
  'Login Successfully': 'התחברת בהצלחה',
  'Welcome to EasyBook': 'ברוך הבא ל-EasyBook',
  'Error logging in': 'שגיאה בהתחברות',
  'oops error logging in': 'אופס, שגיאה בהתחברות',
  'Business Created Successfully': 'העסק נוצר בהצלחה',
  'Now choose a plan for your business': 'עכשיו בחר תוכנית לעסק שלך',
  'Failed Creating Business': 'יצירת העסק נכשלה',
  'Failed to create business it is possible that your credentials is already taken like Phone or Email':
    'נכשל ביצירת העסק, ייתכן שהפרטים שלך כמו טלפון או דוא"ל כבר בשימוש',
  'Business Updated Successfully': 'העסק עודכן בהצלחה',
  'successfully updated business': 'העסק עודכן בהצלחה',
  'Oops there was an error updating business': 'אופס, הייתה שגיאה בעדכון העסק',
  'please try again later': 'אנא נסה שוב מאוחר יותר',
  'Your password has been reset': 'הסיסמה שלך אופסה',
  'Oops! Something went wrong': 'אופס! משהו השתבש',
  'Email Sent Successfully': 'הדוא"ל נשלח בהצלחה',
  'The email was sent successfully, check out your mailbox to reset your password': 'הדוא"ל נשלח בהצלחה, בדוק את תיבת הדואר שלך כדי לאפס את הסיסמה',
  'User Registered Successfully': 'המשתמש נרשם בהצלחה',
  'you successfully created a new user!': 'יצרת בהצלחה משתמש חדש!',
  'Registration Failure': 'ההרשמה נכשלה',
  'failed to signing up': 'ההרשמה נכשלה',
  'Please contact us for help': 'בבקשה צור איתנו קשר על מנת שנוכל לעזור',

  'Appointment updated successfully': 'התור עודכן בהצלחה',
  'You have reached the limit of your plan!': 'הגעת למגבלת התוכנית שלך!',
  'You current plan might be too small for your business needs, check out other plans and change it':
    'ייתכן שהתוכנית הנוכחית שלך קטנה מדי עבור צרכי העסק שלך, בדוק תוכניות אחרות ושנה אותה',
  'See our plans': 'ראה את התוכניות שלנו',
  'Registered Successfully': 'נרשמת בהצלחה',
  'Email must be a valid email address': 'האימייל חייב להיות כתובת אימייל חוקית',
  'Description must be at least 100 characters maximum': 'התיאור חייב להכיל לפחות 100 תווים מקסימום',
  'Password must be 8 at least': 'הסיסמה חייבת להיות לפחות 8 תווים',
  'Passwords Does not match': 'הסיסמאות אינן תואמות',
  'New Password': 'סיסמה חדשה',
  'Confirm New Password': 'אשר סיסמה חדשה',
  'Confirm new password': 'אשר סיסמה חדשה',
  'Error resetting your password': 'שגיאה באיפוס הסיסמה',
  'Change Password': 'שנה סיסמה',
  'This is not visible for Basic Plan': 'לא זמין עבור תוכנית Basic',
  'No appointments that given month': 'אין פגישות לחודש זה',
  'Create User': 'צור משתמש',
  'User Full Name': 'שם מלא של המשתמש',
  'User Email': 'אימייל של משתמש',
  'User Role': 'תפקיד המשתמש',
  'User Created Successfully': 'המשתמש נוצר בהצלחה',
  'User Creation Error': 'שגיאה ביצירת המשתמש',
  'User Password': 'סיסמת המשתמש',
  'User Updated Successfully': 'המשתמש עודכן בהצלחה',
  'are you sure you want to delete this user?': 'האם אתה בטוח שברצונך למחוק את המשתמש הזה?',
  'User Deleted Error': 'שגיאה במחיקת המשתמש',
  User: 'משתמש',
  Until: 'עד',
  'Absence?': 'היעדרות?',
  'Add a absence to one of the employees': 'הוסף היעדרות לאחד מהעובדים',
  'New Appointment?': 'פגישה חדשה?',
  'Choose an Option': 'בחר סוג',
  'Create a new appointment with customer, treatment etc...': "צור פגישה חדשה עם לקוח, טיפול וכו'...",
  Absence: 'היעדרות',
  Error: 'שגיאה',
  'Employee Absence': 'היעדרות עובד',
  'Absence Details': 'פרטי היעדרות',
  'Maybe employee is already busy in those times': 'יתכן שהעובד כבר עסוק בזמנים האלה',
  'Absence Created Successfully': 'ההיעדרות נוצרה בהצלחה',
  'Appointment Updated Successfully': 'התור עודכן בהצלחה',
  'successfully updated appointment': 'התור עודכן בהצלחה',
  'failed to update appointment': 'עדכון התור נכשל',
  'failed to create appointment': 'יצירת התור נכשלה',
  'Appointment Created Successfully': 'התור נוצר בהצלחה',
  'Appointment Deletion failure': 'מחיקת התור נכשלה',
  'Absence Update Successfully': 'העדכון להיעדרות בוצע בהצלחה',
  'failed to delete appointment': 'מחיקת התור נכשלה',
  'Absences Per Employee': 'היעדרויות לפי עובד',
  'No absences for this given month': 'אין היעדרויות בחודש זה',
  'Invalid business hours': 'שעות עבודה לא תקינות',
  'No appointments for this given month': 'אין פגישות לחודש הזה',
  'Business is not open on that day': 'העסק לא פתוח ביום הזה',
  'Category deleted': 'קטגוריה נמחקה',
  'You need to delete all treatments before deleting the category': 'עליך למחוק את כל הטיפולים לפני מחיקת הקטגוריה',
  "Failed to update the customer's status. Please try again.": 'עדכון סטטוס הלקוח נכשל. אנא נסה שוב.',
  'The customer has been successfully blocked.': 'הלקוח נחסם בהצלחה.',
  'The customer has been successfully unblocked.': 'הלקוח שוחרר מהחסימה בהצלחה.',
  'Are you sure you want to remove customer from blocking list?': 'האם אתה בטוח שברצונך להסיר את הלקוח מרשימת החסומים?',
  'Do you want to add this customer to the blocking list?': 'האם ברצונך להוסיף את הלקוח לרשימת החסומים?',
  'Remove Confirmation': 'אישור הסרה',
  'Add Confirmation': 'אישור הוספה',
  Customers: 'לקוחות',
  'Is Blocked': 'חסום',
  'Search by name Or Phone Number': 'חפש לפי שם או מספר טלפון',
  Blocked: 'חסום',
  Reason: 'סיבה',
  Unblocked: 'לא חסום',
  Confirmation: 'אישור',
  Upload: 'העלה',
  Confirm: 'אשר',
  Successfully: 'בהצלחה',

  'Revenue Per Month': 'הכנסות לחודש',
  Social: 'רשתות חברתיות',
  Instagram: 'אינסטגרם',
  Facebook: 'פייסבוק',
  'not required': 'לא חובה',
  'What is the reason?': 'מה הסיבה?',

  'Updating your business': 'מעדכן את העסק שלך',
  'Creating Your Business!': 'יוצר את העסק שלך!',
  'Maximum number of treatments per appointment': 'מספר הטיפולים המקסימלי לפגישה',
  'Your can not cross the limit': 'אינך יכול לעבור את הגבול',
  'There is a limit for this number': 'יש מגבלה למספר הזה',
  'Amount of hours in advance that clients will be allowed to cancel': 'מספר השעות מראש שהלקוחות יוכלו לבטל',
  'Maximum number of future per appointments client can have': 'מספר הפגישות העתידיות המקסימלי שלקוח יכול לקבוע',
  hours: 'שעות',
  Hours: 'שעות',
  'Hours of cancelation in advance': 'שעות לביטול מראש',
  'Max of future appointments': 'המקסימום של פגישות עתידיות',
  'Number of treatments per appointment': 'מספר הטיפולים לפגישה',
  inactive: 'לא פעיל',
  active: 'פעיל',
  Days: 'יום',

  minutes: 'דקות',
  'Employee deactivated successfully': 'העובד הושבת בהצלחה',
  'are you sure you want to activate this employee?': 'האם אתה בטוח שברצונך להפעיל את העובד הזה?',
  'Created from waiting list': 'נוצר מרשימת המתנה',
  'Choose Employee/s': 'בחר/י עובד/ים',
  'Edit Logo': 'ערוך/י לוגו',
  'Edit Background': 'ערוך/י תמונת רקע',
  'Max days ahead that appointment can be settled': 'מספר הימים המרבי מראש שניתן לקבוע תור',
};
