import { Flex, Text } from '@chakra-ui/react';
import { EventContentArg } from '@fullcalendar/core';

interface IDayGridMonthViewProps {
  eventContent: EventContentArg;
}

export default function AllDayHolidayView({ eventContent }: IDayGridMonthViewProps) {
  const { hebrew } = eventContent.event._def.extendedProps;
  return (
    <Flex sx={{}} px={2} borderRadius="4px" width={'100%'} height={'100%'} justifyContent="center" flex={8}>
      <Flex flex={2} justifyContent="center">
        <Text fontWeight="400">{`${hebrew}`}</Text>
      </Flex>
    </Flex>
  );
}
