import { AddIcon } from '@chakra-ui/icons';
import { IconButton, useColorModeValue } from '@chakra-ui/react';

export const NewAppointmentIcon = ({ onCreateOpen }: { onCreateOpen: () => void }) => {
  const color = useColorModeValue('brand.400', 'brand.500');
  const colorReverse = useColorModeValue('brand.100', 'brandScheme.100');
  return (
    <IconButton
      aria-label="add"
      onClick={() => onCreateOpen()}
      borderRadius="50%"
      sx={{
        position: 'fixed',
        float: 'right',
        width: '60px',
        height: '60px',
        bottom: '10px',
        left: '12px',
        backgroundColor: color,
        borderRadius: '50px',
        justifyContent: 'center',
        textAlign: 'center',
        boxShadow: `1px 1px 1px black`,
        display: 'flex',
        alignItems: 'center',
        zIndex: 1000,
      }}
      icon={<AddIcon color={colorReverse} w="25px" h="25px" />}
    />
  );
};
