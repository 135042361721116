import { AuthenticationContext } from 'providers/AuthProvider';
import { FunctionComponent, ReactElement, useContext } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedBusinessRouteProps {
  children: ReactElement;
}

const ProtectedBusinessRoute: FunctionComponent<ProtectedBusinessRouteProps> = ({ children }: ProtectedBusinessRouteProps) => {
  const { userData } = useContext(AuthenticationContext);
  if (!userData) {
    // user is not authenticated
    return <Navigate to="/auth/sign-in" />;
  }
  if (userData.business && userData.business.plan) {
    return <Navigate to="/admin" />;
  }
  return children;
};

export default ProtectedBusinessRoute;
