import { Outlet } from 'react-router-dom';

// Chakra imports
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
import { SidebarContext } from 'contexts/SidebarContext';
import { useState } from 'react';

// Layout components

// Custom Chakra theme
export default function BusinessLayout() {
  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const authBg = useColorModeValue('white', 'navy.900');
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Flex
          bg={authBg}
          minHeight="100vh"
          my={10}
          justifyContent={'center'}
          height="100%"
          w="100%"
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Outlet />
          <FixedPlugin />
        </Flex>
      </SidebarContext.Provider>
    </Box>
  );
}
