import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import FemaleEmployeeImage from 'assets/img/business/female-employee.jpeg';
import MaleEmployeeImage from 'assets/img/business/man-employee.jpeg';
import Card from 'components/cards/Card';
import EmployeeCard from 'components/cards/EmployeeCard';
import AddEmployeeForm from 'components/forms/AddEmployeeForm';
import { AuthenticationContext } from 'providers/AuthProvider';
import { FunctionComponent, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose, MdExposurePlus1, MdPerson } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { deleteEmployee } from 'services/api/employee';
import { ADMIN } from 'shared/consts';
import { CreateTypeEnum } from 'shared/enums';
import { canCreateBasedOnPlan } from 'shared/helpersFunctions';
import { useEmployees } from 'shared/hooks/useEmployees';
import { EmployeeEntityType } from 'shared/types/app.types';

interface EmployeesTabProps {
  businessId: string;
}

const Employees: FunctionComponent<EmployeesTabProps> = ({ businessId }: EmployeesTabProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userData, toggleReachedLimitPlanModal } = useContext(AuthenticationContext);
  const { isOpen: openDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const [employeeToEditOrDelete, setEmployeeToEditOrDelete] = useState<EmployeeEntityType | null>(null);

  const { employees, isLoadingEmployees } = useEmployees();
  const queryClient = useQueryClient();
  const toast = useToast();
  const initialRef = useRef();
  const finalRef = useRef();
  const { t } = useTranslation();
  const handleEditEmployee = (employee: EmployeeEntityType) => {
    onOpen();
    setEmployeeToEditOrDelete(employee);
  };

  const handleDisableEmployeeOpenDialog = (employee: EmployeeEntityType) => {
    onOpenDeleteDialog();
    setEmployeeToEditOrDelete(employee);
  };
  const { mutate: deleteEmployeeMutation, isLoading: isLoadingEmployeeDelete } = useMutation(deleteEmployee, {
    onSuccess: async (response, dataPackage) => {
      await queryClient.invalidateQueries('treatments');
      await queryClient.invalidateQueries('employees');
      const isDeleted = employees.find((e: EmployeeEntityType) => e.id === dataPackage.employeeId).deletedAt;
      toast({
        title: isDeleted ? t('Employee activated successfully') : t('Employee deactivated successfully'),
        status: 'success',
        duration: 7000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('Ops error occurred'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleDeleteEmployee = () => {
    if (!employeeToEditOrDelete) return;
    deleteEmployeeMutation({ employeeId: employeeToEditOrDelete.id });
    onCloseDeleteDialog();
  };

  const closeModal = () => {
    setEmployeeToEditOrDelete(null);
    onClose();
    onCloseDeleteDialog();
  };

  const newEmployeeDialog = () => {
    if (!canCreateBasedOnPlan(userData.business.plan, CreateTypeEnum.EMPLOYEE, employees.length)) {
      toggleReachedLimitPlanModal();
      return;
    }
    onOpen();
  };

  const getEmployeeImage = (employee: EmployeeEntityType) => {
    if (employee.photo?.path) return employee.photo.path;
    return employee.gender === 'M' ? MaleEmployeeImage : FemaleEmployeeImage;
  };

  return (
    <SimpleGrid columns={{ base: 1, md: 4 }} gap="20px">
      {employees &&
        employees
          .sort((e1, e2) => e2.treatments.length - e1.treatments.length)
          .map((employee) => {
            return (
              <EmployeeCard
                key={employee.id}
                employee={employee}
                handleEditEmployee={handleEditEmployee}
                handleDisableEmployee={handleDisableEmployeeOpenDialog}
                employeeFullName={`${employee.firstName} ${employee.lastName}`}
                treatments={employee.treatments ?? []}
                image={getEmployeeImage(employee)}
              />
            );
          })}
      <Card p="20px">
        <Flex flex={1} alignItems="center" justifyContent={'center'}>
          <Button
            variant="brand"
            color="white"
            fontSize="lg"
            isDisabled={userData.user.role.name !== ADMIN}
            fontWeight="700"
            borderRadius="15px"
            onClick={newEmployeeDialog}
            leftIcon={<Icon as={MdExposurePlus1} width="20px" height="20px" color="inherit" />}
            p="40px"
          >
            {t('Create Employee')}
          </Button>
        </Flex>
      </Card>

      {/* edit/create */}
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} closeOnOverlayClick={false} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex gap="5px" alignItems={'center'}>
              <Button ml={3} onClick={closeModal}>
                <Icon as={MdClose} width="20px" height="20px" color="inherit" />
              </Button>
              <Icon as={MdPerson} width="30px" height="30px" color="inherit" mx="7px" />
              {employeeToEditOrDelete ? t('Edit') : t('Create')} {t('Employee')}
            </Flex>
          </ModalHeader>
          <ModalBody pb={6}>
            <AddEmployeeForm handleCloseFunction={closeModal} employeeToEditOrDelete={employeeToEditOrDelete} />
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* delete */}
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={openDeleteDialog || false} onClose={onCloseDeleteDialog}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Button ml={3} onClick={closeModal}>
              <Icon as={MdClose} width="20px" height="20px" color="inherit" />
            </Button>
            {` ${t('Disable')} ${t('employee')} `}
            {employeeToEditOrDelete && `${employeeToEditOrDelete.firstName} ${employeeToEditOrDelete.lastName}`} ?
          </ModalHeader>
          <ModalBody pb={6}>
            <Box gap="30px">
              <Text>
                {employeeToEditOrDelete?.deletedAt
                  ? t('are you sure you want to activate this employee?')
                  : t('are you sure you want to disable this employee?')}
              </Text>
              <Flex gap="20px" justifyContent={'end'} mt="30px">
                <Button onClick={closeModal}>{t('Cancel')}</Button>
                <Button
                  isLoading={isLoadingEmployeeDelete}
                  colorScheme="teal"
                  type="submit"
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  onClick={handleDeleteEmployee}
                >
                  {t('Disable')}
                </Button>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </SimpleGrid>
  );
};

export default Employees;
