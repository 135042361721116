import { Box, Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Treatments from './components/Treatments';

export default function TreatmentsSettings() {
  const { userData } = useContext(AuthenticationContext);
  // Chakra Color Mode
  const { t } = useTranslation();
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box pt={{ base: '100px', md: '80px', xl: '80px' }}>
      {/* Main Fields */}
      <Flex direction="column" gap={2}>
        <Heading color={textColor} size="xl" ms="24px" fontWeight="700">
          {t('Treatments')}
        </Heading>
        <Box px={4}>{userData.business && <Treatments businessId={userData.business.id} />}</Box>
      </Flex>
    </Box>
  );
}
