/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Flex, Grid } from '@chakra-ui/react';

// Custom components

// Assets
import banner from 'assets/img/business/business-bg.png';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import Banner from './components/Banner';
import Profile from './components/Profile';

export default function ProfileSettings() {
  const { userData } = useContext(AuthenticationContext);

  const { user } = userData;

  return (
    <Box pt={{ base: '110px', md: '100px' }}>
      {/* Main Fields */}
      <Grid mb="20px" gap={{ base: '20px', xl: '20px' }}>
        <Flex flexDirection="column">
          <Banner
            banner={banner}
            name={userData.business.name}
            phone={userData.business.phone}
            color={userData.business.color}
            logo={userData.business.businessLogo}
            businessType={userData.business?.businessType}
            email={userData.business.email}
          />
          <Flex direction="column">
            <Box p={4}>{userData.business && <Profile user={user} businessData={userData.business} />}</Box>
          </Flex>
        </Flex>
        {/* <Notifications
          used={25.6}
          total={50}
          gridArea={{
            base: "3 / 1 / 4 / 2",
            lg: "2 / 1 / 3 / 3",
            "2xl": "1 / 3 / 2 / 4",
          }}
        /> */}
      </Grid>
    </Box>
  );
}

// import { Box, Flex, Grid } from "@chakra-ui/react";

// // Custom components
// import Banner from "views/admin/settings/profile/components/Banner";
// import General from "views/admin/settings/profile/components/General";
// import Notifications from "views/admin/settings/profile/components/Notifications";
// import Projects from "views/admin/settings/profile/components/Projects";

// // Assets
// import banner from "assets/img/auth/banner.png";
// import avatar from "assets/img/avatars/avatar4.png";
// import { AuthenticationContext } from "providers/AuthProvider";
// import { useContext } from "react";

// export default function Overview() {
//   const { userData } = useContext(AuthenticationContext);
//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       {/* Main Fields */}
//       <Flex gap="20px">
//         <Banner
//           //   gridArea="1 / 1 / 2 / 2"
//           banner={banner}
//           avatar={avatar}
//           name={`${userData.user.firstName} ${userData.user.lastName}`}
//           job="Business Manager"
//           posts="17"
//           followers="9.7k"
//           following="274"
//         />
//         {/* <Storage gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }} used={25.6} total={50} /> */}
//         {/* <Upload
//           minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
//           pe="20px"
//           pb={{ base: "100px", lg: "20px" }}
//         /> */}
//       </Flex>
//       <Grid
//         mb="20px"
//         templateColumns={{
//           base: "1fr",
//           lg: "repeat(2, 1fr)",
//           "2xl": "1.34fr 1.62fr 1fr",
//         }}
//         templateRows={{
//           base: "1fr",
//           lg: "repeat(2, 1fr)",
//           "2xl": "1fr",
//         }}
//         gap={{ base: "20px", xl: "20px" }}
//       >
//         <Projects
//           banner={banner}
//           avatar={avatar}
//           name="Adela Parkson"
//           job="Product Designer"
//           posts="17"
//           followers="9.7k"
//           following="274"
//         />
//         <General
//           gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
//           minH="365px"
//           pe="20px"
//         />
//         <Notifications
//           used={25.6}
//           total={50}
//           gridArea={{
//             base: "3 / 1 / 4 / 2",
//             lg: "2 / 1 / 3 / 3",
//             "2xl": "1 / 3 / 2 / 4",
//           }}
//         />
//       </Grid>
//     </Box>
//   );
// }
