import { Box, Flex, Icon, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import Calendar, { TileArgs } from 'react-calendar';
// Chakra imports
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
// Custom components
import Card from 'components/cards/Card';
import { AuthenticationContext } from 'providers/AuthProvider';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import { formatDateYYYY_MM_DD } from 'shared/helpersFunctions';
import { useBusinessHours } from 'shared/hooks/useBusinessHours';
import { useHolidays } from 'shared/hooks/useHolidays';
import './MiniCalendar.css';

export default function MiniCalendar({
  selectRange,
  onChange,
  initValue,
  ...rest
}: {
  selectRange: boolean;
  onChange?: (date: Date) => void;
  initValue?: Date;
  [x: string]: any;
}) {
  const { userData } = useContext(AuthenticationContext);
  const { i18n } = useTranslation();
  const workingDayColor = useColorModeValue('brand.700', 'brand');
  const { holidays } = useHolidays();

  const { businessHours } = useBusinessHours({
    businessId: userData?.business.id,
  });
  const businessDays: string[] = (businessHours ?? []).map((business) => business.dayOfWeek);
  const [value, valueChange] = useState<Date>(initValue ?? new Date());

  if (holidays.length === 0) {
    return null;
  }

  return (
    <Card
      alignItems="center"
      flexDirection="column"
      w="100%"
      maxW="max-content"
      // p="20px 15px"
      h="max-content"
      {...rest}
    >
      <Calendar
        className="custom-calendar"
        onChange={(value) => {
          valueChange(value as Date);
        }}
        onClickDay={(value) => {
          onChange?.(value as Date);
        }}
        value={value}
        selectRange={selectRange}
        view={'month'}
        tileDisabled={({ activeStartDate, date, view }) => !businessDays.includes(date.toLocaleString('en-US', { weekday: 'long' }))}
        locale={i18n.language === 'he' ? 'he-IL' : 'en-US'}
        tileContent={(args: TileArgs) => {
          const isHoliday = holidays.filter((holiday) => holiday.date === formatDateYYYY_MM_DD(args.date));
          return (
            <Flex justifyContent={'center'} flexDirection="column" p={20} alignItems={'center'}>
              <Text color={businessDays.includes(args.date.toLocaleString('en-US', { weekday: 'long' })) ? workingDayColor : 'gray.500'}>
                {args.date.getDate()}
              </Text>
              {isHoliday.length > 0 && (
                <Tooltip hasArrow placement="top-start" label={<Text fontWeight="400">{isHoliday[0].hebrew}</Text>} size="sm">
                  <Box h={'6px'} w={'6px'} borderRadius={'50%'} bgColor="green.500" />
                </Tooltip>
              )}
            </Flex>
          );
        }}
        prevLabel={<Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />}
        nextLabel={<Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />}
      />
    </Card>
  );
}
