import { Flex, Select } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { BUSINESS_OPENING_CLOSE_TIMES, DEFAULT_END_TIME, DEFAULT_START_TIME } from 'shared/consts';
import { DaysOfWeekType } from 'shared/types/app.types';

interface TimeAndDayProps {
  onChangeTime: (time: string, day: DaysOfWeekType, openOrClose: 'O' | 'C') => void;
  day: DaysOfWeekType;
  openTime?: string;
  closeTime?: string;
  isOpen: boolean;
  isReadOnly: boolean;
}

const TimeAndDay: FunctionComponent<TimeAndDayProps> = ({ onChangeTime, day, openTime, closeTime, isOpen, isReadOnly }: TimeAndDayProps) => {
  const { t } = useTranslation();
  const businessTimes = BUSINESS_OPENING_CLOSE_TIMES.filter((time) => {
    return !(time.split(':')[1] === '15' || time.split(':')[1] === '45');
  });
  return (
    <Flex gap="20px" alignItems={'center'}>
      <Select
        variant="flushed"
        placeholder={t('Close')}
        disabled={!isOpen || isReadOnly}
        defaultValue={closeTime ?? DEFAULT_END_TIME}
        sx={{ direction: 'ltr' }}
        onChange={(e) => onChangeTime(e.target.value, day, 'C')}
      >
        {businessTimes.map((item) => {
          return (
            <option key={item} value={`${item}`}>
              {item}
            </option>
          );
        })}
      </Select>
      {'-'}
      <Select
        onChange={(e) => onChangeTime(e.target.value, day, 'O')}
        sx={{ direction: 'ltr' }}
        defaultValue={openTime ?? DEFAULT_START_TIME}
        disabled={!isOpen || isReadOnly}
        variant="flushed"
        placeholder={t('Open')}
      >
        {businessTimes.map((item) => {
          return (
            <option key={item} value={`${item}`}>
              {item}
            </option>
          );
        })}
      </Select>
    </Flex>
  );
};

export default TimeAndDay;
