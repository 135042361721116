import { Flex, Text } from '@chakra-ui/react';
import { EventContentArg } from '@fullcalendar/core';
import { useTranslation } from 'react-i18next';

import { TreatmentEntityType } from 'shared/types/app.types';

interface IDayGridMonthViewProps {
  // businessData: BusinessEntityType;
  // employee: EmployeeEntityType;
  treatments: TreatmentEntityType[];
  freeText: string;
  eventContent: EventContentArg;
  absence: boolean;
  customer?: any;
}

export default function DayGridMonthView({ customer, treatments, freeText, absence, eventContent }: IDayGridMonthViewProps) {
  const { t } = useTranslation();
  if (absence) {
    return null;
  }

  const { timeText } = eventContent;
  const customerNameOrFreeText = customer ? customer.firstName : freeText || '';
  const firstTreatmentName = treatments[0]?.name || '';
  const mobileDisplay = `${customerNameOrFreeText}${firstTreatmentName ? ` - ${firstTreatmentName}` : ''}`;

  return (
    <Flex
      px={2}
      borderRadius="4px"
      width="100%"
      height="100%"
      justifyContent="space-between"
      flex={8}
      gap={2}
      flexDirection={{ base: 'column', md: 'row' }}
    >
      {/* Mobile view: Only first name and first treatment */}
      <Text display={{ base: 'block', md: 'none' }} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" fontWeight="700">
        {mobileDisplay}
      </Text>

      {/* Desktop view: Full details */}
      <Flex display={{ base: 'none', md: 'flex' }} flex={3} overflow="hidden" whiteSpace="nowrap">
        <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" fontWeight="700">
          {customerNameOrFreeText}
        </Text>
      </Flex>
      <Flex display={{ base: 'none', md: 'flex' }} flex={2}>
        <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" fontWeight="400">
          {timeText}
        </Text>
      </Flex>
      <Flex display={{ base: 'none', md: 'flex' }} flex={3} justifyContent="end" overflow="hidden" whiteSpace="nowrap">
        {treatments.map((treat, index) => (
          <Text overflow="hidden" whiteSpace="nowrap" key={treat.name} fontWeight="500" textOverflow="ellipsis">
            {` ${treat.name}${index < treatments.length - 1 ? ',' : ''}`}
          </Text>
        ))}
      </Flex>
    </Flex>
  );
}
