import axiosConfig from 'shared/axiosConfig';

export async function getBusinessCustomers(businessId: string) {
  const result = await axiosConfig.get(`/businesses/${businessId}/customers`);
  return result.data;
}

export async function getBusinessBlockedCustomers(businessId: string) {
  const result = await axiosConfig.get(`/businesses/${businessId}/blocked-customers`);
  return result.data;
}
