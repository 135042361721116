/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Flex, Grid, useColorModeValue } from '@chakra-ui/react';

// Custom components

// Assets
import banner from 'assets/img/business/business-bg.png';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useBusinessHours } from 'shared/hooks/useBusinessHours';
import Banner from './components/Banner';
import BusinessSettingsSection from './tabs/Business';

export default function BusinessSettings() {
  const { userData } = useContext(AuthenticationContext);
  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { businessHours, isLoadingBusinessHours } = useBusinessHours({
    businessId: userData?.business.id,
  });

  return (
    <Box pt={{ base: '100px', md: '80px', xl: '80px' }}>
      {/* Main Fields */}
      <Grid mb="20px" gap={{ base: '20px', xl: '20px' }}>
        <Flex flexDirection="column">
          <Banner
            banner={banner}
            name={userData.business.name}
            businessId={userData.business.id}
            phone={userData.business.phone}
            color={userData.business.color}
            logo={userData.business.businessLogo}
            bgImage={userData.business.bgBusinessPhoto}
            businessType={userData.business?.businessType}
            email={userData.business.email}
          />
          <Flex direction="column">
            <Box p={4}>
              {userData.business && !isLoadingBusinessHours && <BusinessSettingsSection business={userData.business} businessHours={businessHours} />}
            </Box>
          </Flex>
        </Flex>
        <Flex flexDirection="column"></Flex>
      </Grid>
    </Box>
  );
}
