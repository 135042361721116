import axiosConfig from 'shared/axiosConfig';
import { EmployeeCreateDTO, EmployeeUpdateDTO } from 'shared/types/app.types';

export async function getEmployees(businessId: string) {
  const result = await axiosConfig.get('/employees', {
    params: { businessId: businessId },
  });
  return result.data;
}

export async function createEmployee(employeeForm: EmployeeCreateDTO & { file: File }) {
  const formData = new FormData();
  formData.append('file', employeeForm.file);

  // Send FormData
  const result = await axiosConfig.post(
    '/employees',
    { ...employeeForm, formData },
    {
      headers: {
        'Content-Type': 'multipart/form-data', // Ensure proper header for file uploads
      },
    },
  );
  return result.data;
}

export async function updateEmployee({ updatedEmployee, employeeId, file }: { updatedEmployee: EmployeeUpdateDTO; file: File; employeeId: string }) {
  const formData = new FormData();
  formData.append('file', file);
  const result = await axiosConfig.patch(
    `/employees/${employeeId}`,
    { ...{ ...updatedEmployee, file }, formData },
    {
      headers: {
        'Content-Type': 'multipart/form-data', // Ensure proper header for file uploads
      },
    },
  );
  return result.data;
}

export async function deleteEmployee({ employeeId }: { employeeId: string }) {
  const result = await axiosConfig.delete(`/employees/${employeeId}`);
  return result.data;
}
