// Chakra imports
import { Box, Flex, Select, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/cards/Card';
import PieChart from 'components/charts/PieChart';
import { t } from 'i18next';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { getDashboardEmployeesAppointments } from 'services/api/business';
import { ACTIVE, ADMIN, MONTHS_NAMES } from 'shared/consts';
import { pieChartOptions } from 'variables/charts';
export default function PieCardEmployee({ businessId, title, rest }: { businessId: string; title: string; [x: string]: any }) {
  const { userData } = useContext(AuthenticationContext);
  const [chosenMonth, setChosenMonth] = useState<string>(MONTHS_NAMES.filter((month) => month === MONTHS_NAMES[new Date().getMonth()])[0]);
  const [chosenYear, setChosenYear] = useState<number>(new Date().getFullYear());
  const { data: employeesAppointments } = useQuery<any>(
    ['dashboard-employees-appointments', chosenMonth, chosenYear],
    () => getDashboardEmployeesAppointments(businessId, chosenMonth, chosenYear),
    {
      enabled: userData.user.status.name === ACTIVE && userData.business.plan.name !== 'Basic' && userData.user.role.name === ADMIN,
      select: (response: any[]) => response.sort((a, b) => a.amount - b.amount).filter((a) => a.amount !== 0),
      refetchOnMount: 'always',
    },
  );
  const totalAmount = employeesAppointments
    ? employeesAppointments.map((employee: any) => employee.amount).reduce((acc: number, num: number) => acc + num, 0)
    : 1;
  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const cardColor = useColorModeValue('white', 'navy.700');
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
  return (
    <Card p="20px" alignItems="center" flexDirection="column" w="100%" justifyContent={'space-between'} {...rest}>
      <Flex px={{ base: '0px', '2xl': '10px' }} justifyContent="space-between" alignItems="center" w="100%" mb="8px">
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          {title}
        </Text>
        <Flex gap={2}>
          <Select
            fontSize="sm"
            variant="auth"
            textAlign={'left'}
            width="100%"
            fontWeight="700"
            onChange={(event: any) => setChosenMonth(event.target.value)}
            value={t(chosenMonth)}
          >
            {MONTHS_NAMES.map((month, index) => (
              <option key={index} value={month}>
                {t(month)}
              </option>
            ))}
          </Select>
          <Select
            fontSize="sm"
            variant="auth"
            textAlign={'left'}
            width="100%"
            fontWeight="700"
            onChange={(event: any) => setChosenYear(+event.target.value)}
            value={chosenYear}
          >
            {[chosenYear - 1, chosenYear, chosenYear + 1].map((month, index) => (
              <option key={index} value={month}>
                {month}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>

      {employeesAppointments?.length > 0 ? (
        <PieChart
          key={chosenMonth + chosenYear}
          h="100%"
          w="100%"
          chartData={employeesAppointments.map((item: any) => item.amount)}
          chartOptions={pieChartOptions(employeesAppointments.map((treat: any) => treat.name))}
        />
      ) : (
        <Box>
          <Text fontSize="md" color="secondaryGray.600" fontWeight="700" mb="5px">
            {t('No appointments for this given month')}
          </Text>
        </Box>
      )}
      <Card bg={cardColor} flexDirection="row" boxShadow={cardShadow} w="100%" p="15px" mt="15px" justifyContent={'space-around'}>
        <Flex overflowX="scroll" whiteSpace="nowrap" maxWidth="100%" gap={6}>
          {userData.business.plan.name === 'Basic' && (
            <Text fontSize="md" color="secondaryGray.600" fontWeight="700" mb="5px">
              {t('This is not visible for Basic Plan')}
            </Text>
          )}
          {employeesAppointments &&
            employeesAppointments.map((item: any) => {
              return (
                <Flex key={item.name} w="100%" py="5px" justifyContent={'center'} alignItems="center">
                  <Flex align="center" flexDirection={'column'}>
                    <Text fontSize="xs" color="secondaryGray.600" fontWeight="700" mb="5px">
                      {item.name}
                    </Text>
                    <Text fontSize="lg" color={textColor} fontWeight="700">
                      {Math.round((item.amount / totalAmount) * 100)}%
                    </Text>
                  </Flex>
                </Flex>
              );
            })}
        </Flex>
      </Card>
    </Card>
  );
}
