import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Flex, Heading } from '@chakra-ui/react';
import PlanChoosingCard from 'components/cards/PlanChoosingCard';
import { useTranslation } from 'react-i18next';

export const ChoosePlan = () => {
  const textColor = useColorModeValue('navy.700', 'white');
  const { t } = useTranslation();
  return (
    <Box my={12}>
      <Flex alignContent={'center'} justifyContent="center">
        <Heading color={textColor} fontSize="36px" mb="5px">
          {t('Choose your plan')}
        </Heading>
      </Flex>
      <PlanChoosingCard />
    </Box>
  );
};
