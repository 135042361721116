import { AuthenticationContext } from 'providers/AuthProvider';
import { ReactElement, useContext } from 'react';
import { Navigate } from 'react-router-dom';

export const ProtectedAdminRoute = ({ children }: { children: ReactElement }) => {
  const { userData } = useContext(AuthenticationContext);
  if (!userData) {
    // user is not authenticated
    return <Navigate to="/auth/sign-in" />;
  }
  if (!userData.business) {
    return <Navigate to="/business/create" />;
  }
  if (!userData.business.plan) {
    return <Navigate to="/business/plan" />;
  }
  return children;
};
