import { useContext, useState } from 'react';
// motion
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import greenDot from 'assets/img/icons/green-dot.png';
import redDot from 'assets/img/icons/pink-dot.png';
import { motion } from 'framer-motion';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getAllPlans } from 'services/api/plans';
// variants
export const fadeIn = (direction: string, delay: number) => {
  return {
    hidden: {
      y: direction === 'up' ? 40 : direction === 'down' ? -40 : 0,
      opacity: 0,
      x: direction === 'left' ? 40 : direction === 'right' ? -40 : 0,
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 1.2,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};

const PlanChoosingCard = () => {
  const [pickedPlan, setPickedPlan] = useState<any>(null);
  const { setBusinessPlanMutation, userData } = useContext(AuthenticationContext);
  const { t, i18n } = useTranslation();
  const { data: plans, isLoading: isLoadingPlans } = useQuery<any[]>(['plans'], getAllPlans, {
    refetchOnMount: false,
  });

  if (isLoadingPlans) {
    return <div>Loading...</div>;
  }

  const displayKeys = (name: string): string => {
    switch (name) {
      case 'users':
        return t('Users');
      case 'reports':
        return t('Reports');
      case 'invoices':
        return t('Invoices');
      case 'employees':
        return t('Employees');
      case 'treatments':
        return t('Treatments');
      case 'fullAnalytics':
        return t('Full Analytics');
      case 'automatedReminder':
        return t('Automated Reminder');
      default:
        return name; // Return the original name if no match is found
    }
  };

  const createPlanForBusiness = () => {
    setBusinessPlanMutation({
      businessId: userData.business.id,
      planId: pickedPlan.id,
    });
  };

  const cardRenderer = (plan: any) => {
    return (
      <Box
        onClick={() => openChangePlanModal(plan)}
        cursor="pointer"
        key={plan.id}
        sx={{
          border: pickedPlan && pickedPlan.id === plan.id ? `2px solid #7551FF` : 'none',
        }}
        className="border py-8 md:px-6 px-4 rounded-xl shadow-3xl"
      >
        <h3 className="text-3xl  font-bold text-center">{plan.name}</h3>
        <p className=" text-center my-6">{i18n.language === 'he' ? plan.hebrewDescription : plan.englishDescription}</p>
        <p className="mt-5 text-center text-secondary text-4xl font-bold">
          ₪{plan.price}
          <span className="text-base text-tertiary font-medium">/{t('month')}</span>
        </p>
        <ul className="mt-4 space-y-2 px-4">
          {Object.entries(plan.info)
            .filter((info) => typeof info[1] === 'boolean')
            .sort((info1, info2) => (info1[1] ? -1 : 1))
            .map((item, index) => (
              <Flex key={index} className="flex items-center" gap={2}>
                <Image src={item[1] ? greenDot : redDot} w="20px" h="20px" objectFit="cover" borderRadius="20px" />
                {displayKeys(item[0])}
              </Flex>
            ))}
          {Object.entries(plan.info)
            .filter((info) => typeof info[1] !== 'boolean')
            .map((item, index) => (
              <Flex key={index} className="flex items-center" gap={2} px={7}>
                <Flex>{`${item[1]}`}</Flex>
                {displayKeys(item[0])}
              </Flex>
            ))}
        </ul>
      </Box>
    );
  };

  const openChangePlanModal = (plan: any): void => {
    setPickedPlan(plan);
  };

  return (
    <div className="py-2 md:px-14 p-4 max-w-screen-2xl mx-auto" id="pricing">
      <motion.div
        variants={fadeIn('up', 0.3)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.2 }}
        className="grid sm:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-10 mt-20 md:w-11/12 mx-auto"
      >
        {plans.map((plan) => cardRenderer(plan))}
      </motion.div>
      <Box position="fixed" bottom="5%" left="50%" transform="translate(-50%, -50%)" zIndex="999">
        <Button
          isDisabled={pickedPlan === null}
          variant="brand"
          onClick={createPlanForBusiness}
          size="lg"
          width="fit-content"
          paddingX={12}
          paddingY={4}
          boxShadow="lg"
        >
          {pickedPlan ? `${t('Create plan')} ₪${pickedPlan.price}` : t('Pick a plan')}
        </Button>
      </Box>
    </div>
  );
};

export default PlanChoosingCard;
