/* eslint-disable */

import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { filterRoutesByPlan, filterRoutesByRole } from 'shared/helpersFunctions';

export function SettingsSidebarLinks(props: { settingsRoutes: RoutesType[] }) {
  //   Chakra color mode
  let location = useLocation();
  const { t } = useTranslation();
  let activeColor = useColorModeValue('gray.700', 'white');
  let inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
  let activeIcon = useColorModeValue('brand.500', 'white');
  let textColor = useColorModeValue('secondaryGray.500', 'white');
  let brandColor = useColorModeValue('brand.500', 'brand.400');
  const { userData } = useContext(AuthenticationContext);
  const { settingsRoutes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname === routeName;
  };

  const filteredRoutes = filterRoutesByRole(userData.user.role, filterRoutesByPlan(userData.business.plan, settingsRoutes));
  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (settingsRoutes: RoutesType[]) => {
    return settingsRoutes.map(
      (route: RoutesType, index: number) => {
        return (
          <NavLink key={index} to={route.path} replace>
            {route.icon ? (
              <Box bgColor={activeRoute(route.path.toLowerCase()) && 'brand.100'}>
                <HStack spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'} py="5px" ps="10px">
                  <Flex w="100%" alignItems="center" justifyContent="center">
                    <Box color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor} me="18px">
                      {route.icon}
                    </Box>
                    <Text
                      me="auto"
                      color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor}
                      fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}
                    >
                      {t(route.name)}
                    </Text>
                  </Flex>
                  <Box h="36px" w="4px" bg={activeRoute(route.path.toLowerCase()) ? brandColor : 'transparent'} borderRadius="5px" />
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'} py="5px" ps="10px">
                  <Text
                    me="auto"
                    color={activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor}
                    fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}
                  >
                    {route.name}
                  </Text>
                  <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      },
      // }
    );
  };
  //  BRAND
  return <>{createLinks(filteredRoutes)}</>;
}

export default SettingsSidebarLinks;
