import Chart from 'react-apexcharts';
import { getPastMonths } from 'shared/helpersFunctions';

const MyBarChart = ({ chartData, chartOptions }: { chartData: any; chartOptions: any }) => {
  return (
    <Chart
      options={{
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            // endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: getPastMonths(),
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: (val: number) => `₪${val}`,
          },
        },
      }}
      series={chartData}
      type="bar"
      height={350}
    />
  );
};

export default MyBarChart;
