import { useQuery } from 'react-query';
import { getBusinessBlockedCustomers } from 'services/api/customers';
import { BusinessEntityType } from 'shared/types/app.types';

export const useBusinessBlockedCustomers = ({ businessData }: { businessData: BusinessEntityType }) => {
  const {
    data: customers,
    isLoading: isLoadingBlockedCustomers,
    isSuccess: isSuccessBlockedCustomers,
  } = useQuery<any[]>(['blocked-customers'], () => getBusinessBlockedCustomers(businessData.id), {
    refetchOnMount: false,
  });

  return {
    blockedCustomers: customers ?? [],
    isLoadingBlockedCustomers,
    isSuccessBlockedCustomers,
  };
};
