// Chakra imports
import { Box, Button, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/cards/Card';
// Custom components
import { OperatingHours } from 'components/openingHours/OpeningHours';
import { useTranslation } from 'react-i18next';
import { IoMdTime } from 'react-icons/io';
import { DaysOfBusinessType } from 'shared/types/app.types';

export default function OperatingHoursCard({ businessHours }: { businessHours: DaysOfBusinessType[] }) {
  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { t } = useTranslation();
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
  const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });
  return (
    <Card alignItems="center" flexDirection="column" w="100%" className="shadow">
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text me="auto" color={textColor} fontSize="xl" fontWeight="700" lineHeight="100%">
          {t('Opening Hours')}
        </Text>
        <Button
          alignItems="center"
          justifyContent="center"
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w="37px"
          h="37px"
          lineHeight="100%"
          borderRadius="10px"
        >
          <Icon as={IoMdTime} color={iconColor} w="24px" h="24px" />
        </Button>
      </Flex>

      <Box mt="auto" w="100%">
        <OperatingHours isReadOnly daysOfBusiness={businessHours} />
      </Box>
    </Card>
  );
}
