import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getNotifications } from 'services/api/notifications';
import { ACTIVE } from 'shared/consts';
import { NotificationItem } from 'shared/types/app.types';

export const useNotifications = ({ businessId }: { businessId: string }) => {
  const { userData } = useContext(AuthenticationContext);
  const { data: notifications, isLoading: isLoadingNotifications } = useQuery<NotificationItem[]>(
    ['notifications'],
    () => getNotifications(businessId),
    {
      select: (notificationsResponse: NotificationItem[]) => {
        return notificationsResponse.sort((a, b) => {
          const dateA = new Date(a.createdAt).getTime();
          const dateB = new Date(b.createdAt).getTime();
          return dateB - dateA;
        });
      },
      enabled: userData.user.status.name === ACTIVE,
      refetchOnMount: true,
      refetchInterval: 30000,
    },
  );

  return { notifications: notifications ?? [], isLoadingNotifications };
};
