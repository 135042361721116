// Chakra imports
import { Avatar, AvatarGroup, Box, Button, Divider, Flex, Icon, Image, Switch, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { startCase } from 'lodash';
// Custom components
import Card from 'components/cards/Card';
import { MdEdit } from 'react-icons/md';
// Assets
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ADMIN } from 'shared/consts';
import { stringToColor } from 'shared/helpersFunctions';
import { EmployeeEntityType, TreatmentEntityType } from 'shared/types/app.types';

interface EmployeeCardProps {
  image: string;
  employeeFullName: string;
  employee: EmployeeEntityType;
  handleEditEmployee: (employee: EmployeeEntityType) => void;
  handleDisableEmployee: (employee: EmployeeEntityType) => void;
  treatments: TreatmentEntityType[];
  // price: string | number;
  // time: string;
}

export default function EmployeeCard({
  image,
  employeeFullName,
  employee,
  handleEditEmployee,
  handleDisableEmployee,
  treatments,
}: EmployeeCardProps) {
  const isDeleted = !!employee.deletedAt;
  const editBtnColor = useColorModeValue('brandScheme.100', 'brand.400');
  const { userData } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorBid = useColorModeValue('brand.500', 'white');
  return (
    <Card p="18px" maxW="sm" border={!isDeleted && `2px solid ${stringToColor(employeeFullName)}`} opacity={isDeleted ? '0.85' : '1'}>
      <Flex direction={{ base: 'column' }} justify="center" h="100%">
        <Box
          mb={{ base: '20px', '2xl': '20px' }}
          position="relative"
          width="100%" // Set the fixed width
          height={{ base: '300px', xl: '250px' }} // Set the fixed height
        >
          <Image
            src={image}
            w={{ base: '100%', '3xl': '100%' }}
            h={{ base: '100%', '3xl': '100%' }}
            sx={isDeleted ? { filter: 'grayscale(80%)' } : {}}
            objectFit="cover"
            borderRadius="20px"
          />
          {!isDeleted && (
            <>
              <Button
                position="absolute"
                bg={editBtnColor}
                _hover={{ bg: 'brand.600' }}
                _active={{ bg: 'brand' }}
                _focus={{ bg: 'brand' }}
                isDisabled={userData.user.role.name !== ADMIN}
                p="0px !important"
                top="14px"
                right="14px"
                borderRadius="50%"
                minW="30px"
                h="30px"
                onClick={() => handleEditEmployee(employee)}
              >
                <Icon transition="0.2s linear" w="20px" h="20px" as={MdEdit} />
              </Button>
            </>
          )}
        </Box>

        <Flex flexDirection="column" justify="space-between" h="100%">
          <Box display={'flex'} width={'100%'} gap={2}>
            <Switch
              isChecked={!isDeleted}
              sx={{
                direction: 'ltr',
                '&[data-checked=false] .chakra-switch__track': {
                  backgroundColor: 'red', // Use a red color from Chakra's theme
                },
              }}
              colorScheme="green"
              borderRadius="50%"
              size="md"
              onChange={() => handleDisableEmployee(employee)}
            />
            {`(${t(isDeleted ? 'inactive' : 'active')})`}
          </Box>
          <Flex
            justify="space-between"
            direction={{
              base: 'row',
              md: 'column',
              lg: 'row',
              xl: 'column',
              '2xl': 'row',
            }}
            mb="auto"
          >
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize={{
                  base: 'xl',
                  md: 'lg',
                  lg: 'lg',
                  xl: 'lg',
                  '2xl': 'md',
                  '3xl': 'lg',
                }}
                mb="5px"
                fontWeight="bold"
                me="14px"
              >
                {`${startCase(employeeFullName)}`}
              </Text>
              <Divider width={'100%'} />
              {treatments.length > 0 ? (
                <Text
                  color="secondaryGray.600"
                  fontSize={{
                    base: 'sm',
                  }}
                  fontWeight="400"
                >
                  {t('Treatments')}
                </Text>
              ) : (
                <Text
                  fontSize={{
                    base: 'sm',
                  }}
                  fontWeight="800"
                >
                  {t('Have no treatment')}
                </Text>
              )}
            </Flex>
            {treatments && (
              <Tooltip
                hasArrow
                placement="top-start"
                label={treatments.map((treat) => {
                  return (
                    <Text key={treat.id} fontWeight="500">
                      {treat.name}
                    </Text>
                  );
                })}
                size="sm"
              >
                <AvatarGroup
                  max={3}
                  w="40%"
                  color={textColorBid}
                  spacing="-1"
                  size="sm"
                  mt={{
                    base: '0px',
                    md: '10px',
                    lg: '0px',
                    xl: '10px',
                    '2xl': '0px',
                  }}
                  fontSize="12px"
                >
                  {treatments.map((treat) => (
                    <Avatar key={treat.id} name={`${treat.name}`} />
                  ))}
                </AvatarGroup>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
