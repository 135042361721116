import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getMajorHolidays } from 'services/api/business';
import { ACTIVE } from 'shared/consts';

export const useHolidays = () => {
  const { userData } = useContext(AuthenticationContext);
  const { data: holidays, isLoading: isLoadingHolidays } = useQuery<any[]>(['holidays'], () => getMajorHolidays(), {
    select: (response: any) => {
      return response.items.map((item: any) => ({
        ...item,
        start: item.date,
        allDay: true,
        editable: false,
      }));
    },
    enabled: userData.user.status.name === ACTIVE,
    refetchOnMount: false,
  });

  return { holidays: holidays ?? [], isLoadingHolidays };
};
