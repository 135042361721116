import { Box, Button, Icon, Modal, ModalBody, ModalContent, ModalOverlay, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import MiniCard from 'components/cards/MiniCard';
import PricingCard from 'components/cards/PricingCard';
import IconBox from 'components/icons/IconBox';
import { capitalize } from 'lodash';
import { AuthenticationContext } from 'providers/AuthProvider';
import { FunctionComponent, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPerson } from 'react-icons/bs';
import { FaConnectdevelop, FaLock, FaLockOpen } from 'react-icons/fa';
import { MdCheck, MdClose, MdEmail, MdOutlineAdminPanelSettings, MdPhone } from 'react-icons/md';
import { BusinessEntityType, User } from 'shared/types/app.types';
import { UserForm } from './UserForm';
// import OperatingHoursCard from "../components/OpeningHours";

interface IProfileProps {
  user: User;
  businessData: BusinessEntityType;
}

const Profile: FunctionComponent<IProfileProps> = ({ user, businessData }: IProfileProps) => {
  const { isLoadingUpdateBusiness } = useContext(AuthenticationContext);
  const [isOnEditMode, setIsOnEditMode] = useState<boolean>(false);
  const { t } = useTranslation();
  const initialRef = useRef();
  const finalRef = useRef();

  const textColorSecondary = 'gray.400';

  const brandColor = useColorModeValue('brand.600', 'brand.100');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 3, lg: 3, '2xl': 3 }} gap="10px" mb="20px">
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={BsPerson} color={brandColor} />} />}
          name={t('First Name')}
          value={user.firstName}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={BsPerson} color={brandColor} />} />}
          name={t('Last Name')}
          value={user.lastName}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={MdPhone} color={brandColor} />} />}
          name={t('Phone')}
          value={user.phone}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={MdEmail} color={brandColor} />} />}
          name={t('Email')}
          value={user.email}
        />
        <MiniCard
          startContent={
            <IconBox
              w="36px"
              h="36px"
              bg={boxBg}
              // TODO: add swtich for icons rendering
              icon={<Icon w="22px" h="22px" as={FaConnectdevelop} color={brandColor} />}
            />
          }
          name={t('Logged In Using')}
          value={capitalize(user.provider)}
        />
        <MiniCard
          startContent={
            <IconBox
              w="36px"
              h="36px"
              bg={boxBg}
              // TODO: add swtich for icons rendering
              icon={<Icon w="22px" h="22px" as={MdOutlineAdminPanelSettings} color={brandColor} />}
            />
          }
          name={t('Role')}
          value={user.role.name}
        />

        <MiniCard
          startContent={
            <IconBox
              w="36px"
              h="36px"
              bg={user.status.name === 'Active' ? 'green' : 'red'}
              icon={<Icon w="22px" h="22px" as={user.status.name === 'Active' ? MdCheck : MdClose} color={brandColor} />}
            />
          }
          name={t('Status')}
          value={t(user.status.name)}
        />

        {/* <OperatingHoursCard businessHours={businessHours} /> */}
      </SimpleGrid>
      <Box width={'100%'} justifyContent={'center'} display="flex">
        <Button
          isLoading={isLoadingUpdateBusiness}
          // isDisabled={setIsDisabledForButton(props.values, props.errors)}
          colorScheme="teal"
          onClick={() => setIsOnEditMode(!isOnEditMode)}
          type={isOnEditMode ? 'button' : 'submit'}
          fontSize={isOnEditMode ? 'md' : 'sm'}
          variant={isOnEditMode ? 'brand' : 'light'}
          fontWeight={isOnEditMode ? '900' : '500'}
          leftIcon={isOnEditMode ? <Icon color={textColorSecondary} as={FaLockOpen} /> : <Icon color={textColorSecondary} as={FaLock} />}
          border={'1px solid'}
          w="100%"
          maxWidth={'250px'}
          h="50"
        >
          {isOnEditMode ? t('Save changes') : t('Edit Profile')}
        </Button>
      </Box>

      <PricingCard chosenPlan={businessData.plan} />
      <Modal
        size="lg"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOnEditMode}
        scrollBehavior={'outside'}
        onClose={() => setIsOnEditMode(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody pb={2}>
            <UserForm user={user} onCloseModal={() => setIsOnEditMode(false)} />
            {/* <UpdateOrCreateBusiness
              closeModal={() => setIsOnEditMode(false)}
              business={business}
              businessHours={businessHours}
            /> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Profile;
