import { ChakraProvider } from '@chakra-ui/react';
import { RtlProvider } from 'components/rtlProvider/RtlProvider';
import { EasyBookRoutes } from 'EasyBookRoutes';
import { AuthenticationProvider } from 'providers/AuthProvider';
import { BusinessProvider } from 'providers/BusinessProvider';
import { CalendarProvider } from 'providers/CalendarProvider';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import './assets/css/App.css';
import i18n from './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './styles/tailwind.css';
import theme from './theme/theme';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <AuthenticationProvider>
          <BusinessProvider>
            <CalendarProvider>
              <ChakraProvider theme={theme}>
                <React.StrictMode>
                  <RtlProvider>
                    <EasyBookRoutes />
                  </RtlProvider>
                  <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                </React.StrictMode>
              </ChakraProvider>
            </CalendarProvider>
          </BusinessProvider>
        </AuthenticationProvider>
      </I18nextProvider>
    </BrowserRouter>
  </QueryClientProvider>,
);

serviceWorkerRegistration.register();
