import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { AuthenticationContext } from 'providers/AuthProvider';
import { Dispatch, FunctionComponent, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBrush } from 'react-icons/bs';
import { MdDescription } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { createCategory, updateCategory } from 'services/api/treatment';
import { CategoryEntity } from 'shared/types/app.types';

interface IAddCategoryFormProps {
  handleCloseFunction: () => void;
  categories: string[];
  categoryToEdit?: CategoryEntity;
  setActiveCategory: Dispatch<SetStateAction<string>>;
}

interface IAddCategoryForm {
  name: string;
  description: string;
}

const AddOrEditCategoryForm: FunctionComponent<IAddCategoryFormProps> = ({
  handleCloseFunction,
  categories,
  categoryToEdit,
  setActiveCategory,
}: IAddCategoryFormProps) => {
  const textColorSecondary = 'gray.400';
  const { userData } = useContext(AuthenticationContext);
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutate: createCategoryMutation, isLoading: isLoadingCategoryCreate } = useMutation(createCategory, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries('categories');
      toast({
        title: t('Category Created Successfully'),
        description: t('successfully created employee, be aware that this category still has no treatments.'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      handleCloseFunction();
    },
    onError: (error) => {
      const description =
        error &&
        typeof error === 'object' &&
        'response' in error &&
        error.response &&
        typeof error.response === 'object' &&
        'status' in error.response &&
        error.response.status === 400
          ? t('Category already exists')
          : 'Ops error occurred try again later';
      toast({
        title: t('Ops Error'),
        description: description,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });
  const { mutate: updateCategoryMutation, isLoading: isLoadingCategoryUpdate } = useMutation(updateCategory, {
    onSuccess: async (response, payload) => {
      await queryClient.invalidateQueries('categories');
      await queryClient.invalidateQueries('treatments');
      setActiveCategory(payload.categoryForm.name);
      toast({
        title: t('Category Updated Successfully'),
        description: t('successfully created employee, be aware that this category still has no treatments.'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      handleCloseFunction();
    },
    onError: (error) => {
      const description =
        error &&
        typeof error === 'object' &&
        'response' in error &&
        error.response &&
        typeof error.response === 'object' &&
        'status' in error.response &&
        error.response.status === 400
          ? 'Category already exists'
          : 'Ops error occurred try again later';
      toast({
        title: 'Ops Error',
        description: description,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleFormSubmit = (values: IAddCategoryForm, actions: FormikHelpers<IAddCategoryForm>) => {
    if (!categoryToEdit) {
      createCategoryMutation({
        categoryForm: {
          name: values.name,
          description: values.description ?? '',
        },
        businessId: userData.business.id,
      });
    } else {
      updateCategoryMutation({
        categoryForm: {
          name: values.name,
          description: values.description ?? '',
        },
        categoryId: categoryToEdit.id,
      });
    }
  };

  const validateIfToDisableButton = (values: IAddCategoryForm, errors: FormikErrors<IAddCategoryForm>) => {
    return !!Object.keys(errors).length || !values.name;
  };
  const displaySpinner = isLoadingCategoryCreate || isLoadingCategoryCreate;
  return (
    <Box>
      <Formik
        initialValues={{
          name: categoryToEdit?.name ?? '',
          description: categoryToEdit?.description ?? '',
        }}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors }) => (
          <Form>
            <Field
              name="name"
              validate={(name: string) => {
                if (!name) {
                  return t('Name must be provided');
                } else if (name.length > 60) {
                  return t('Name is too long');
                } else if (categories.includes(name) || categories.map((cat) => cat.toLowerCase()).includes(name.toLowerCase())) {
                  return t('Name already exists');
                }
                return undefined;
              }}
            >
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isRequired={true} isInvalid={form.errors.name && form.touched.name}>
                  <FormLabel htmlFor="name">{t('Name')}</FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="name"
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type="name"
                      placeholder={t('Category Name')}
                      fontWeight="500"
                      size="lg"
                    />
                    <InputLeftElement display="flex" alignItems="center" mt="4px">
                      <Icon color={textColorSecondary} as={BsBrush} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="description">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isInvalid={form.errors.description && form.touched.description}>
                  <FormLabel htmlFor="description">{t('Description')}</FormLabel>
                  <InputGroup size="md">
                    <Textarea
                      {...field}
                      id="description"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type="description"
                      placeholder={t('Category Description')}
                      fontWeight="500"
                      size="lg"
                    />
                    <InputLeftElement display="flex" alignItems="center" mt="4px">
                      <Icon color={textColorSecondary} as={MdDescription} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex gap="20px" justifyContent={'end'} mt="30px">
              <Button onClick={handleCloseFunction}>{t('Cancel')}</Button>
              <Button
                isLoading={displaySpinner}
                colorScheme="teal"
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                isDisabled={validateIfToDisableButton(values, errors)}
              >
                {!categoryToEdit ? t('Create') : t('Update')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddOrEditCategoryForm;
