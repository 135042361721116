export enum BusinessTypeEnum {
  BARBER_SHOP = 'Barber Shop',
  BEAUTY_SALON = 'Beauty Salon',
  NAIL_SALON = 'Nail Salon',
  SPA = 'Spa',
  FITNESS_CENTER = 'Fitness Center',
  YOGA_STUDIO = 'Yoga Studio',
  MASSAGE_THERAPY = 'Massage Therapy',
  CONSULTING_FIRM = 'Consulting Firm',
  WEB_DESIGN_AGENCY = 'Web Design Agency',
  ACCOUNTING_SERVICES = 'Accounting Services',
  LEGAL_SERVICES = 'Legal Services',
  REAL_ESTATE_AGENCY = 'Real Estate Agency',
  TRAVEL_AGENCY = 'Travel Agency',
  AUTO_REPAIR_SHOP = 'Auto Repair Shop',
  OTHER = 'Other',
  // Add more relevant business types
}

export enum CreateTypeEnum {
  USER = 'User',
  TREATMENT = 'Treatment',
  EMPLOYEE = 'Employee',
}
