import axiosConfig from 'shared/axiosConfig';

export async function getAllPlans() {
  const result = await axiosConfig.get(`/plans`);
  return result.data;
}

export async function setBusinessPlan(data: { planId: number; businessId: string }) {
  const result = await axiosConfig.post('/plans/set-business-plan', data);
  return result.data;
}
