import { Box } from '@chakra-ui/react';

import { EventContentArg } from '@fullcalendar/core';
import { stringToColor } from 'shared/helpersFunctions';
import { BusinessEntityType } from 'shared/types/app.types';
import AllDayHolidayView from './timeViews/AllDayHolidayView';
import DayGridMonthView from './timeViews/DayGridMonthView';
import TimeGridDayView from './timeViews/TimeGridDayView';
import TimeGridWeekView from './timeViews/TimeGridWeekView';

interface EventProps {
  eventContent: EventContentArg;
}

const Event = ({ eventContent }: EventProps) => {
  const viewType = eventContent.view.type;
  const { allDay } = eventContent.event._def;
  const { customerId: customer, employeeId: employee, treatments, freeText, absence } = eventContent.event._def.extendedProps;
  const businessData: BusinessEntityType = eventContent.event._def.extendedProps.businessId;
  if ((!(customer || freeText) || !employee || !treatments) && !allDay && !absence) {
    return;
  }
  const handleViewRender = (viewType: string) => {
    if (allDay) {
      return <AllDayHolidayView eventContent={eventContent} />;
    }
    switch (viewType) {
      case 'timeGridDay':
        return (
          <TimeGridDayView
            treatments={treatments}
            absence={absence}
            employee={employee}
            customer={customer}
            freeText={freeText}
            businessData={businessData}
            eventContent={eventContent}
          />
        );
      case 'timeGridWeek':
        return (
          <TimeGridWeekView
            treatments={treatments}
            employee={employee}
            absence={absence}
            freeText={freeText}
            customer={customer}
            businessData={businessData}
            eventContent={eventContent}
          />
        );
      case 'dayGridMonth':
        return (
          <DayGridMonthView
            treatments={treatments}
            customer={customer}
            absence={absence}
            freeText={freeText}
            eventContent={eventContent}
            // employee={employee}
            // businessData={businessData}
          />
        );
      default:
        return (
          <TimeGridDayView
            treatments={treatments}
            employee={employee}
            customer={customer}
            absence={absence}
            freeText={freeText}
            businessData={businessData}
            eventContent={eventContent}
          />
        );
    }
  };
  return absence ? (
    <Box
      w="100%"
      h="100%"
      bgColor={'rgba(170, 170, 170, 1)'}
      sx={{
        borderRadius: '4px',
        borderLeft: '4px solid #ffff',
      }}
    >
      {handleViewRender(viewType)}
    </Box>
  ) : (
    <Box
      w="100%"
      h="100%"
      onClick={() => console.log()}
      bgColor={employee ? stringToColor(`${employee.firstName} ${employee.lastName}`) : '#80b78a'}
      sx={{
        borderRadius: '4px',
        borderRight: `4px solid ${employee ? stringToColor(`${employee.firstName} ${employee.lastName}`, true) : '#00ff66'}`,
      }}
    >
      {handleViewRender(viewType)}
    </Box>
  );
};

export default Event;
