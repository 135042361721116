// Chakra imports
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { Logo } from 'components/logo/Logo';

// Custom components
import { HSeparator } from 'components/separator/Separator';
import { useNavigate } from 'react-router-dom';

export function SidebarBrand() {
  //   Chakra color mode
  const logoColor = useColorModeValue('navy.700', 'white');
  const navigate = useNavigate();

  return (
    <Flex alignItems="center" flexDirection="column">
      <Box onClick={() => navigate('/admin')}>
        <Logo size={'2xl'} />
      </Box>
      <HSeparator my="20px" />
    </Flex>
  );
}

export default SidebarBrand;
