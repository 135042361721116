import { QuestionIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Select as SingleSelect,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Select as MultiSelect } from 'chakra-react-select';
import { Field, Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { AuthenticationContext } from 'providers/AuthProvider';
import { BusinessContext } from 'providers/BusinessProvider';
import { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPeople } from 'react-icons/bs';
import { IoMdClock } from 'react-icons/io';
import { EmployeesSelectCustomComponents } from 'shared/components/SelectComponents';
import { TIMES_OF_TREAT_OPTIONS } from 'shared/consts';
import { serializeEmployees } from 'shared/helpersFunctions';
import { useEmployees } from 'shared/hooks/useEmployees';
import { EmployeeSelectType, ScheduleType, TimeOfTreatmentType, TreatmentEntityType } from 'shared/types/app.types';

interface IAddTreatmentFormProps {
  handleCloseFunction: () => void;
  treatToEdit?: TreatmentEntityType;
  categoryId: string;
}

interface ITreatmentForm {
  name: string;
  price: number;
  timeOfTreatment: TimeOfTreatmentType;
  employees: EmployeeSelectType[];
}

const AddOrEditTreatmentForm: FunctionComponent<IAddTreatmentFormProps> = ({
  handleCloseFunction,
  treatToEdit,
  categoryId,
}: IAddTreatmentFormProps) => {
  const textColorSecondary = 'gray.400';
  const { t } = useTranslation();
  const [schedule, setSchedule] = useState<ScheduleType>(treatToEdit?.schedule ?? 'OPEN');
  const [chosenEmployees, setChosenEmployees] = useState<EmployeeSelectType[]>(treatToEdit ? serializeEmployees(treatToEdit.employees) : []);
  const { createTreatmentMutation, updateTreatmentMutation, isLoadingTreatUpdate, isLoadingTreatCreate } = useContext(BusinessContext);
  const { userData } = useContext(AuthenticationContext);
  const { employees, isLoadingEmployees } = useEmployees();

  const startName = treatToEdit ? treatToEdit.name : '';
  const startPrice = treatToEdit ? treatToEdit.price : 100;
  const startTimeOfTreatment: TimeOfTreatmentType = treatToEdit ? (treatToEdit.timeOfTreatment as TimeOfTreatmentType) : '15';

  const handleFormSubmit = (values: ITreatmentForm, actions: FormikHelpers<ITreatmentForm>) => {
    if (treatToEdit) {
      updateTreatmentMutation({
        updatedTreatment: {
          ...values,
          schedule,
          price: +values.price,
          employees: chosenEmployees.map((employee) => employee.value),
        },
        treatId: treatToEdit.id,
      });
    } else {
      createTreatmentMutation({
        ...values,
        schedule,
        price: +values.price,
        category: categoryId,
        businessId: userData.business.id,
        employees: chosenEmployees.map((employee) => employee.value),
      });
    }
    handleCloseFunction();
  };

  const validateIfToDisableButton = (values: ITreatmentForm, errors: FormikErrors<ITreatmentForm>) => {
    const chosenEmployeesPhones = chosenEmployees.map((e) => e.phone).sort();
    const treatmentEmployeesPhones = treatToEdit ? treatToEdit.employees.map((e) => e.phone).sort() : [];
    return (
      !!Object.keys(errors).length ||
      !values.name ||
      !values.price ||
      (treatToEdit &&
        values.name === treatToEdit.name &&
        values.timeOfTreatment === treatToEdit.timeOfTreatment &&
        schedule === treatToEdit.schedule &&
        +values.price === treatToEdit.price &&
        chosenEmployeesPhones.length === treatmentEmployeesPhones.length &&
        chosenEmployeesPhones.every((value, index) => value === treatmentEmployeesPhones[index]))
    );
  };
  const displaySpinner = isLoadingTreatCreate || isLoadingTreatUpdate;
  return (
    <Box>
      <Formik
        initialValues={{
          name: startName,
          price: startPrice,
          timeOfTreatment: startTimeOfTreatment,
          employees: chosenEmployees,
        }}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, values, errors }) => (
          <Form>
            <Field name="name" validate={(name: number) => (!name ? t('Please provide a name') : '')}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isRequired={true} isInvalid={form.errors.name && form.touched.name}>
                  <FormLabel htmlFor="name">{t('Name')}</FormLabel>
                  <Input
                    {...field}
                    id="name"
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="name"
                    placeholder={t('Treatment Example')}
                    fontWeight="500"
                    size="lg"
                  />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="price" validate={(price: number) => (price < 0 ? t('Enter a valid price') : '')}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="20px" isInvalid={form.errors.price && form.touched.price} isRequired={true}>
                  <FormLabel htmlFor="price">{t('Price')}</FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="price"
                      fontSize="sm"
                      placeholder={t('1000₪')}
                      size="lg"
                      type={'tel'}
                      variant="auth"
                      sx={{ textAlign: 'right' }}
                    />
                    <InputLeftElement display="flex" alignItems="center" mt="4px">
                      ₪
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.price}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="timeOfTreatment" validator={(timeOfTreatment: string) => (!timeOfTreatment ? t('Pick time') : '')}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="20px" isInvalid={form.errors.timeOfTreatment && form.touched.timeOfTreatment} isRequired={true}>
                  <FormLabel htmlFor="timeOfTreatment">{t('Time of treatment')}</FormLabel>
                  <InputGroup size="md">
                    <SingleSelect {...field} variant="auth" iconColor="transparent" value={values.timeOfTreatment}>
                      {TIMES_OF_TREAT_OPTIONS.map((time, index) => {
                        return (
                          <option key={index} value={time}>
                            {time}
                            {` ${t('Minutes')}`}
                          </option>
                        );
                      })}
                    </SingleSelect>
                    <InputLeftElement display="flex" alignItems="center">
                      <Icon color={textColorSecondary} as={IoMdClock} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.timeOfTreatment}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="schedule">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="20px" isInvalid={form.errors.schedule && form.touched.schedule} isRequired={true}>
                  <FormLabel htmlFor="schedule">{t('Schedule Option')}</FormLabel>
                  <InputGroup size="md">
                    <RadioGroup onChange={(value) => setSchedule(value as ScheduleType)} value={schedule}>
                      <Stack direction="row">
                        <Radio value="OPEN">
                          <Text color="green.400">{t('Open')}</Text>
                        </Radio>
                        <Radio value="BLOCK">
                          <Text color="red.400">{t('Block')}</Text>
                        </Radio>
                        <Radio value="TAKE_ACTION">
                          <Text color="orange.500">{t('Take Action')}</Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    <Tooltip
                      hasArrow
                      placement="right"
                      label={
                        <Box>
                          <Text fontWeight="400">{t('OPEN = treatment is open for schedule')}</Text>
                          <Text fontWeight="400">{t('CLOSE = treatment is close for schedule')}</Text>
                          <Text fontWeight="400">
                            {t('TAKE ACTION = any appointment will be settled with this treatment will be notified to you to approve')}
                          </Text>
                        </Box>
                      }
                      size="md"
                    >
                      <InputLeftElement display="flex" alignItems="center">
                        <Icon color={textColorSecondary} as={QuestionIcon} />
                      </InputLeftElement>
                    </Tooltip>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.schedule}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="employees">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="20px" isInvalid={form.errors.employees && form.touched.employees}>
                  <FormLabel htmlFor="employees">{t('Employees related')}</FormLabel>
                  <InputGroup>
                    <Box w="90%">
                      {!isLoadingEmployees ? (
                        <MultiSelect
                          isMulti
                          value={chosenEmployees}
                          name="option-color-scheme"
                          components={EmployeesSelectCustomComponents}
                          options={employees}
                          variant="auth"
                          placeholder={t('Select Employees')}
                          menuPortalTarget={document.body}
                          onChange={(employees) => setChosenEmployees([...employees])}
                          styles={{
                            menuPortal: (provided) => ({
                              ...provided,
                              zIndex: 2000,
                            }),
                          }}
                        />
                      ) : (
                        <Spinner />
                      )}
                    </Box>
                    <InputLeftElement display="flex" alignItems="center" mt="4px">
                      <Icon color={textColorSecondary} as={BsPeople} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.employees}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {/* {false && (
                  <>
                    <Text
                      color={errorColorBrand}
                      fontSize="sm"
                      mb="12px"
                      fontWeight="500"
                    >
                      Email or Password are invalid
                    </Text>
                  </>
                )} */}
            <Flex gap="20px" justifyContent={'end'} mt="30px">
              <Button onClick={handleCloseFunction}>{t('Cancel')}</Button>
              <Button
                isLoading={displaySpinner}
                colorScheme="teal"
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                isDisabled={validateIfToDisableButton(values, errors)}
              >
                {!treatToEdit ? t('Create') : t('Update')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddOrEditTreatmentForm;
