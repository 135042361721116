import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Tab,
  TabList,
  Tabs,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import Treatment from 'assets/img/business/treatment.jpeg';
import Card from 'components/cards/Card';
import TreatmentCard from 'components/cards/TreatmentCard';
import AddOrEditCategoryForm from 'components/forms/AddCategoryForm';
import AddOrEditTreatmentForm from 'components/forms/AddTreatmentForm';
import { queryClient } from 'index';
import { groupBy } from 'lodash';
import { AuthenticationContext } from 'providers/AuthProvider';
import { BusinessContext } from 'providers/BusinessProvider';
import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBrush } from 'react-icons/bs';
import { IoMdTrash } from 'react-icons/io';
import { MdClose, MdEdit, MdExposurePlus1 } from 'react-icons/md';
import { useMutation, useQuery } from 'react-query';
import { deleteCategory, getTreatments } from 'services/api/treatment';
import { ACTIVE, ADMIN } from 'shared/consts';
import { CreateTypeEnum } from 'shared/enums';
import { canCreateBasedOnPlan } from 'shared/helpersFunctions';
import { useCategories } from 'shared/hooks/useCategories';
import { CategoryEntity, TreatmentEntityType } from 'shared/types/app.types';

interface TreatmentsTabProps {
  businessId: string;
}

const Treatments: FunctionComponent<TreatmentsTabProps> = ({ businessId }: TreatmentsTabProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userData, toggleReachedLimitPlanModal } = useContext(AuthenticationContext);
  const color = useColorModeValue('brandScheme.200', 'brand.400');
  const editIconColor = useColorModeValue('brandScheme.100', 'brand.100');
  const headingColor = useColorModeValue('navy.400', 'navy.100');
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = useState('');
  const [categoryToEdit, setCategoryToEdit] = useState<CategoryEntity>();
  const { isOpen: openDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const { isOpen: openCategoryDialog, onOpen: onOpenCategoryDialog, onClose: onCloseCategoryDialog } = useDisclosure();
  const { isLoadingTreatUpdate, isLoadingTreatDelete, deleteTreatmentMutation } = useContext(BusinessContext);
  const [treatToEditOrDelete, setTreatmentToEditOrDelete] = useState<TreatmentEntityType | null>(null);

  const initialRef = useRef();
  const finalRef = useRef();
  const { data: treatments, isLoading: isLoadingTreatments } = useQuery<TreatmentEntityType[]>(['treatments'], () => getTreatments(businessId), {
    refetchOnMount: true,
    enabled: userData.user.status.name === ACTIVE,
  });
  const groupByCategoryLodash = groupBy(treatments, (treat: any) => treat.category.name);
  const { categories } = useCategories({ businessId: businessId });
  const toast = useToast();
  const { mutate: deleteCategoryMutation, isLoading: isLoadingDeletion } = useMutation(deleteCategory, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('categories');
      toast({
        title: t('Category deleted'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      closeModal();
    },
    onError: () => {
      toast({
        title: t('Ops error occurred try again later'),
        description: t('You need to delete all treatments before deleting the category'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const categoriesNames = categories.map((category) => category.name);

  useEffect(() => {
    if (!activeCategory && categories.length > 0) {
      setActiveCategory(categories[0].name);
    }
  }, [categories]);

  const handleEditTreatment = (treatment: TreatmentEntityType) => {
    onOpen();
    setTreatmentToEditOrDelete(treatment);
  };

  const handleDeleteTreatmentOpenDialog = (treatment: TreatmentEntityType) => {
    onOpenDeleteDialog();
    setTreatmentToEditOrDelete(treatment);
  };

  const handleDeleteTreatment = () => {
    if (!treatToEditOrDelete) return;
    deleteTreatmentMutation({ treatId: treatToEditOrDelete.id });
    onCloseDeleteDialog();
    setTreatmentToEditOrDelete(null);
  };

  const closeModal = () => {
    setTreatmentToEditOrDelete(null);
    onClose();
    onCloseCategoryDialog();
    onCloseDeleteDialog();
  };

  const handleDeleteCategory = () => {
    if (categoryToEdit) {
      deleteCategoryMutation({ categoryId: categoryToEdit.id });
    }
  };

  const newTreatmentsDialog = () => {
    if (!canCreateBasedOnPlan(userData.business.plan, CreateTypeEnum.TREATMENT, treatments.length)) {
      toggleReachedLimitPlanModal();
      return;
    }
    onOpen();
  };

  const AddNewTreatmentCard = (
    <Card p="20px">
      <Flex flex={1} alignItems="center" justifyContent={'center'}>
        <Button
          variant="brand"
          color="white"
          isDisabled={userData.user.role.name !== ADMIN}
          fontSize="lg"
          fontWeight="700"
          borderRadius="15px"
          onClick={newTreatmentsDialog}
          leftIcon={<Icon as={MdExposurePlus1} width="20px" height="20px" color="inherit" />}
          p="40px"
        >
          {t('Create Treatment')}
        </Button>
      </Flex>
    </Card>
  );

  const handleChangeCategory = (value: number) => {
    if (value < categories.length) {
      setActiveCategory(categoriesNames[value]);
    }
  };

  const editCategoryButton = () => {
    onOpenCategoryDialog();
    setCategoryToEdit(categories.filter((cat) => cat.name === activeCategory)[0]);
  };

  const modalShouldStayOpenWhenLoading = isLoadingTreatUpdate || isLoadingTreatDelete;

  return (
    <>
      <Tabs onChange={handleChangeCategory}>
        <TabList
          style={{
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            display: 'flex',
            padding: '0.5rem',
            scrollbarWidth: 'thin',
          }}
        >
          {categories.map((category) => (
            <Tab key={category.id} id={category.id} style={{ flexShrink: 0 }}>
              <Text>{category.name}</Text>
            </Tab>
          ))}
        </TabList>

        <Box sx={{ p: 2 }}>
          <Box my={2}>
            <Button
              colorScheme="teal"
              type="submit"
              fontSize="sm"
              isDisabled={userData.user.role.name !== ADMIN}
              variant="brand"
              onClick={editCategoryButton}
              fontWeight="500"
            >
              {t(categories.length === 0 ? 'Create' : 'Edit')} {activeCategory}
              <Icon color={editIconColor} as={MdEdit} marginRight={1} />
            </Button>
          </Box>
          {categories.length === 0 ? (
            <Box my={2}>
              <Heading size="lg" color={headingColor} fontWeight={'900'}>
                {t("You don't have any Category, create new Category")}
              </Heading>
            </Box>
          ) : (
            <SimpleGrid columns={{ base: 1, md: 3, '2xl': 4 }} gap="20px">
              {(activeCategory in groupByCategoryLodash ? groupByCategoryLodash[activeCategory] : [])
                .sort((t1, t2) => t2.employees.length - t1.employees.length)
                .map((treat) => {
                  return (
                    <TreatmentCard
                      key={treat.id}
                      treatment={treat}
                      handleEditTreatment={handleEditTreatment}
                      handleDeleteTreatment={handleDeleteTreatmentOpenDialog}
                      treatmentName={treat.name}
                      employees={treat.employees ?? []}
                      image={Treatment}
                      price={treat.price}
                      schedule={treat.schedule}
                      time={treat.timeOfTreatment}
                    />
                  );
                })}

              {AddNewTreatmentCard}
            </SimpleGrid>
          )}
        </Box>
      </Tabs>

      <Tooltip hasArrow placement="top" label={<Text fontWeight="500">{t('Add New Category')}</Text>} size="sm">
        <IconButton
          aria-label="add category"
          isDisabled={userData.user.role.name !== ADMIN}
          onClick={() => {
            setCategoryToEdit(null);
            onOpenCategoryDialog();
          }}
          sx={{
            position: 'fixed',
            width: '60px',
            height: '60px',
            bottom: '40px',
            backgroundColor: color,
            color: '#FFF',
            borderRadius: '50px',
            justifyContent: 'center',
            textAlign: 'center',
            boxShadow: `2px 2px 3px black`,
            display: 'flex',
            alignItems: 'center',
            zIndex: 3000,
          }}
          icon={<AddIcon color={'white'} w="25px" h="25px" />}
        />
      </Tooltip>
      {/* edit/create treatment */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen || modalShouldStayOpenWhenLoading}
        closeOnOverlayClick={false}
        onClose={closeModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex gap="5px" alignItems={'center'}>
              <Button ml={3} onClick={closeModal}>
                <Icon as={MdClose} width="20px" height="20px" color="inherit" />
              </Button>
              <Icon as={BsBrush} width="30px" height="30px" color="inherit" mx="7px" />
              <Box>
                <Text>
                  {treatToEditOrDelete ? t('Edit') : t('Create')} {t('Treatment')}
                </Text>
                <Flex w="100%" justifyContent={'center'}>
                  <Tag
                    px={6}
                    size={'l'}
                    boxShadow={`0 1px 4px black`}
                    borderRadius="full"
                    variant="solid"
                    colorScheme={'purple'}
                    sx={{
                      border: '2px solid white',
                    }}
                  >
                    <TagLabel>{activeCategory}</TagLabel>
                  </Tag>
                </Flex>
              </Box>
            </Flex>
          </ModalHeader>
          <ModalBody pb={6}>
            <AddOrEditTreatmentForm
              handleCloseFunction={closeModal}
              treatToEdit={treatToEditOrDelete}
              categoryId={categories.filter((category) => category.name === activeCategory)[0]?.id}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* delete */}
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={openDeleteDialog} onClose={onCloseDeleteDialog}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Button ml={3} onClick={closeModal}>
              <Icon as={MdClose} width="20px" height="20px" color="inherit" />
            </Button>
            {t('Delete')} {treatToEditOrDelete && treatToEditOrDelete.name} {t('Treatment')}?
          </ModalHeader>
          <ModalBody pb={6}>
            <Box gap="30px">
              <Text>{t('are you sure you want to delete this treatment?')}</Text>
              <Text mt={6} fontSize={'14px'} color="red.400">
                {t('Be aware that if that treatment has appointments related, it can not be deleted')}
              </Text>
              <Flex gap="20px" justifyContent={'end'} mt="30px">
                <Button onClick={closeModal}>{t('Cancel')}</Button>
                <Button
                  isLoading={isLoadingTreatments}
                  colorScheme="teal"
                  type="submit"
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  onClick={handleDeleteTreatment}
                >
                  {t('Delete')}
                </Button>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* Create/edit category */}
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={openCategoryDialog} onClose={onCloseCategoryDialog}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box justifyContent={'space-between'} display="flex" alignItems={'center'}>
              <Button onClick={closeModal}>
                <Icon as={MdClose} width="20px" height="20px" color="inherit" />
              </Button>
              {t('Create New Category')}
              {categoryToEdit ? (
                <Button color="red.500" isLoading={isLoadingDeletion} onClick={handleDeleteCategory}>
                  <Icon transition="0.2s linear" w="20px" h="20px" as={IoMdTrash} />
                </Button>
              ) : (
                <Box />
              )}
            </Box>
          </ModalHeader>
          <ModalBody pb={6}>
            <AddOrEditCategoryForm
              setActiveCategory={setActiveCategory}
              handleCloseFunction={closeModal}
              categories={categoriesNames ?? []}
              categoryToEdit={categoryToEdit}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Treatments;
