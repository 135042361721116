import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Select as MultiSelect } from 'chakra-react-select';
import { Field, Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { CalendarContext } from 'providers/CalendarProvider';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdPerson } from 'react-icons/md';
import { EmployeesSelectCustomComponents } from 'shared/components/SelectComponents';
import { serializeEmployees } from 'shared/helpersFunctions';
import { useEmployees } from 'shared/hooks/useEmployees';
import { AppointmentEntity, CreateAppointmentPayloadStep2, EmployeeSelectType } from 'shared/types/app.types';

interface ICreateAbsenceStep1FormProps {
  setUpStep2: (payloadStep2: CreateAppointmentPayloadStep2) => void;
  prevStep: () => void;
  appointmentData?: AppointmentEntity;
}

export default function CreateAbsenceStep1Form({ setUpStep2, prevStep, appointmentData }: ICreateAbsenceStep1FormProps) {
  const { t } = useTranslation();

  const { employees, isLoadingEmployees } = useEmployees();
  const existingEmployee = appointmentData ? serializeEmployees([appointmentData.employeeId]) : undefined;
  const singleEmployee = employees.length === 1 ? employees[0] : undefined;
  const [chosenEmployee, setChosenEmployee] = useState<EmployeeSelectType>(existingEmployee ? existingEmployee[0] : singleEmployee);
  const bgColor = useColorModeValue('brand.400', 'brand.400');
  const errorColorBrand = useColorModeValue('red.500', 'red.300');
  const { isLoadingAppointmentCreation, createAppointmentError } = useContext(CalendarContext);
  const createAppointmentDispatcher = (values: Record<string, string>, actions: FormikHelpers<Record<string, string>>) => {
    const payload: CreateAppointmentPayloadStep2 = {
      employeeId: chosenEmployee.id,
    };
    setUpStep2(payload);
  };

  const disableCreateButton = (errors: FormikErrors<Record<string, string>>) => {
    return !!Object.keys(errors).length || !Object.keys(chosenEmployee ?? {}).length;
  };

  return (
    <Formik initialValues={{}} onSubmit={createAppointmentDispatcher}>
      {({ isSubmitting, values, errors }) => (
        <Form>
          <Field name="employeeId">
            {({ field, form }: { field: any; form: any }) => (
              <FormControl mb="10px" isInvalid={form.errors.employeeId && form.touched.employeeId} isRequired={true}>
                <FormLabel htmlFor="employeeId">{t('Employee')}</FormLabel>
                <InputGroup>
                  <Box w="90%">
                    <MultiSelect
                      value={chosenEmployee}
                      name="option-color-scheme"
                      components={EmployeesSelectCustomComponents}
                      isLoading={isLoadingEmployees}
                      options={employees}
                      variant="auth"
                      placeholder={t('Select Employee')}
                      onChange={(employee) => {
                        setChosenEmployee(employee as unknown as EmployeeSelectType);
                      }}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 2000,
                        }),
                      }}
                      chakraStyles={{
                        valueContainer: (provided) => ({
                          ...provided,
                          display: 'flex',
                        }),
                      }}
                    />
                  </Box>
                  <InputLeftElement display="flex" alignItems="center" px={2}>
                    <Icon color={bgColor} as={MdPerson} h="20px" w="20px" />
                  </InputLeftElement>
                </InputGroup>
                <FormErrorMessage>{form.errors.employeeId}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          {createAppointmentError && (
            <>
              <Text color={errorColorBrand} fontSize="sm" mb="12px" fontWeight="500">
                {t('An Error occurred, maybe the employee is already busy')}
              </Text>
            </>
          )}
          <Flex pt="10px" gap="5px" justifyContent={'end'}>
            <Button
              variant="brand"
              fontSize="md"
              type="submit"
              fontWeight="500"
              isLoading={isLoadingAppointmentCreation}
              isDisabled={disableCreateButton(errors)}
              px="24px"
              py="5px"
            >
              {t('Next')}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
