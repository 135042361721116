import { EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { css, keyframes } from '@emotion/react';
import MiniCalendar from 'components/calendar/MiniCalendar';
import { CalendarContext } from 'providers/CalendarProvider';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdClock } from 'react-icons/io';
import { useQuery } from 'react-query';
import { getEmployeeAvailabilityTimes } from 'services/api/appointments';
import { getDayName } from 'shared/helpersFunctions';
import { AppointmentEntity, TreatmentEntityType } from 'shared/types/app.types';

const vibrationAnimation = keyframes`
0% {
  transform: translateY(-2px) rotate(10deg) scale(1);
  }
  50% {
    transform: translateY(2px) rotate(-5deg) scale(1.6);
  }
  100% {
    transform: translateY(-2px) rotate(5deg) scale(1);
  }
  `;

const tagStyles = css`
  &:hover {
    animation: ${vibrationAnimation} 0.7s ease-in-out;
  }
`;

interface ICreateAppointmentStep4FormProps {
  setSelectedTime: (val: string) => void;
  setSelectedDate: (val: Date) => void;
  prevStep: () => void;
  selectedDate: Date;
  isValidDayOfBusiness: boolean;
  selectedTime: string;
  employeeId: string;
  treatments: TreatmentEntityType[];
  businessId: string;
  createAppointmentDispatcher: () => void;
  appointmentData?: AppointmentEntity;
}

export default function CreateAppointmentStep4Form({
  createAppointmentDispatcher,
  appointmentData,
  employeeId,
  treatments,
  businessId,
  selectedTime,
  isValidDayOfBusiness,
  setSelectedDate,
  selectedDate,
  prevStep,
  setSelectedTime,
}: ICreateAppointmentStep4FormProps) {
  const bgColor = useColorModeValue('brandScheme.100', 'brand.400');
  const borderColor = useColorModeValue('brand.500', 'brand.600');
  const initialRef = useRef();
  const finalRef = useRef();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const timeOfTreatment = appointmentData?.startOfAppointment;
  const { isLoadingAppointmentCreation, isLoadingAppointmentUpdate } = useContext(CalendarContext);
  const dateOfAppointment = new Date(appointmentData?.dateOfAppointment);
  const totalTime = treatments.map((treat) => +treat.timeOfTreatment).reduce((acc, time) => acc + time, 0);
  const { data: employeeAvailableSlots, isLoading: isLoadingEmployeeAvailableSlots } = useQuery<string[]>(
    ['employee-availability', selectedDate],
    () => getEmployeeAvailabilityTimes(businessId, employeeId, totalTime, selectedDate),
    {
      select: (response: string[]) => {
        const onTheSameDay = dateOfAppointment.getDate() === selectedDate.getDate() && dateOfAppointment.getMonth() === selectedDate.getMonth();
        return timeOfTreatment && onTheSameDay ? [...new Set([timeOfTreatment.substr(0, 5), ...response])] : response;
      },
      enabled: !!employeeId && isValidDayOfBusiness,
    },
  );
  const morningAvailableSlots = (employeeAvailableSlots ?? []).filter((item) => {
    const [hours] = item.split(':').map(Number);
    return hours < 12; // all under 11:59
  });

  const afternoonAvailableSlots = (employeeAvailableSlots ?? []).filter((item) => {
    const [hours] = item.split(':').map(Number);
    return hours >= 12 && hours < 16; // all between 12:00 - 15:59
  });

  const eveningAvailableSlots = (employeeAvailableSlots ?? []).filter((item) => {
    const [hours] = item.split(':').map(Number);
    return hours >= 16; // all the rest from 16:00 - 23:00
  });

  return (
    <Box>
      <Flex py={1} alignItems={'center'} justifyContent="space-around" gap={2} border={`1px solid`} borderColor={borderColor} borderRadius="20px">
        <Flex gap={2}>
          <Text fontSize={'18px'} fontWeight="600">
            {selectedDate.toLocaleDateString('en-GB')}
          </Text>
          <Text fontSize={'18px'} fontWeight="600">
            {t('Day')} {t(getDayName(selectedDate))}
          </Text>
        </Flex>
        <Button
          variant="action"
          fontSize="md"
          fontWeight="500"
          onClick={() => onOpen()}
          borderRadius="8px"
          sx={{ alignItems: 'center' }}
          rightIcon={<EditIcon w="20px" h="20px" />}
        >
          {t('Change')}
        </Button>
      </Flex>
      <FormControl mb="20px" isRequired={true}>
        <FormLabel htmlFor="timeOfTreatment" sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
          {t('Time')} <Icon color={bgColor} as={IoMdClock} />
        </FormLabel>
        {/* <InputGroup size="md"> */}
        {!isValidDayOfBusiness && (
          <Flex>
            <Text fontSize={'18px'} color={'red.400'} fontWeight="700">
              {t('Business is close')}
            </Text>
          </Flex>
        )}
        {!isLoadingEmployeeAvailableSlots && employeeAvailableSlots ? (
          <Box>
            <Flex alignContent="end" mb={2} mx={4}>
              <Text fontSize={'18px'} fontWeight="700">
                {t('Morning')}
              </Text>
            </Flex>
            <Flex gap={3} flexWrap={'wrap'}>
              {morningAvailableSlots.map((time) => {
                return (
                  <Box w="25%" key={time}>
                    <Tag
                      onClick={() => setSelectedTime(time)}
                      px={4}
                      className={time === selectedTime ? 'vibrate' : ''}
                      size={'md'}
                      boxShadow={`0 1px 4px black`}
                      borderRadius="full"
                      variant="solid"
                      colorScheme={'purple'}
                      sx={{
                        border: time === selectedTime ? '2px solid white' : 'none',
                      }}
                      css={{
                        ...tagStyles,
                      }}
                    >
                      <TagLabel overflow={'none'}>{time}</TagLabel>
                    </Tag>
                  </Box>
                );
              })}
            </Flex>
            <Flex alignContent="end" my={2} mx={4}>
              <Text fontSize={'18px'} fontWeight="700">
                {t('Noon')}
              </Text>
            </Flex>
            <Flex gap={3} flexWrap={'wrap'}>
              {afternoonAvailableSlots.map((time) => {
                return (
                  <Box w="25%" key={time}>
                    <Tag
                      onClick={() => setSelectedTime(time)}
                      px={4}
                      className={time === selectedTime ? 'vibrate' : ''}
                      size={'md'}
                      boxShadow={`0 1px 4px black`}
                      borderRadius="full"
                      variant="solid"
                      colorScheme={'purple'}
                      sx={{
                        border: time === selectedTime ? '2px solid white' : 'none',
                      }}
                      css={{
                        ...tagStyles,
                      }}
                    >
                      <TagLabel>{time}</TagLabel>
                    </Tag>
                  </Box>
                );
              })}
            </Flex>
            <Flex alignContent="end" my={2} mx={4}>
              <Text fontSize={'18px'} fontWeight="700">
                {t('Evening')}
              </Text>
            </Flex>
            <Flex gap={2} flexWrap={'wrap'}>
              {eveningAvailableSlots.map((time) => {
                return (
                  <Box w="25%" key={time}>
                    <Tag
                      onClick={() => setSelectedTime(time)}
                      px={4}
                      className={time === selectedTime ? 'vibrate' : ''}
                      size={'md'}
                      boxShadow={`0 1px 4px black`}
                      borderRadius="full"
                      variant="solid"
                      colorScheme={'purple'}
                      sx={{
                        border: time === selectedTime ? '2px solid white' : 'none',
                      }}
                      css={{
                        ...tagStyles,
                      }}
                    >
                      <TagLabel>{time}</TagLabel>
                    </Tag>
                  </Box>
                );
              })}
            </Flex>
          </Box>
        ) : (
          <Spinner hidden={!isValidDayOfBusiness} />
        )}
        {/* </Flex> */}
        {/* </InputGroup> */}
      </FormControl>
      <Flex sx={{ justifyContent: 'end' }} mt={10} gap={3}>
        <Button variant="action" fontSize="md" fontWeight="500" px="24px" onClick={prevStep} py="5px">
          {t('Back')}
        </Button>
        <Button
          variant="brand"
          isLoading={isLoadingAppointmentCreation || isLoadingAppointmentUpdate}
          onClick={createAppointmentDispatcher}
          px="24px"
        >
          {appointmentData ? t('Update') : t('Create')}
        </Button>
      </Flex>
      <Modal size="xl" initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} scrollBehavior={'outside'} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Select your date')}</ModalHeader>
          <Box p={10}>
            <MiniCalendar
              initValue={selectedDate}
              h="100%"
              minW="100%"
              selectRange={false}
              onChange={(value) => {
                setSelectedDate(value);
                onClose();
              }}
            />
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
}
