import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { MultiValue, Select as MultiSelect } from 'chakra-react-select';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { employeesSelectWrapper } from 'shared/components/SelectComponents';
import { useEmployees } from 'shared/hooks/useEmployees';
import { BusinessEntityType, EmployeeSelectType } from 'shared/types/app.types';

export const EmployeesInput = ({
  businessData,
  chosenEmployees,
  setChosenEmployees,
}: {
  businessData: BusinessEntityType;
  chosenEmployees: EmployeeSelectType[];
  setChosenEmployees: Dispatch<SetStateAction<EmployeeSelectType[]>>;
}) => {
  const { employees, isLoadingEmployees } = useEmployees();
  const { t } = useTranslation();

  const color = useColorModeValue('brand.400', 'brand.500');

  const handleEmployeesChosen = (data: MultiValue<EmployeeSelectType>) => {
    setChosenEmployees([...data]);
  };

  return (
    <Box
      w="90%"
      sx={{
        position: 'fixed',
        width: '300px',
        bottom: '10px',
        borderRadius: '50px',
        justifyContent: 'center',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <MultiSelect
        isMulti
        value={chosenEmployees}
        size="md"
        name="employees-calendar"
        components={employeesSelectWrapper({
          selectInputWidth: '250px',
          selectInputBgColor: color,
        })}
        isClearable={false}
        options={employees}
        isLoading={isLoadingEmployees}
        variant="auth"
        menuPlacement="top"
        placeholder={
          <Text textOverflow="ellipsis" overflow="hidden" color="white" whiteSpace="nowrap" fontWeight="500">
            {t('Choose Employee/s')}
          </Text>
        }
        onChange={(employees) => handleEmployeesChosen([...employees])}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 3000,
          }),
          input: (provided) => ({
            ...provided,
            zIndex: 1000,
          }),
        }}
      />
    </Box>
  );
};
