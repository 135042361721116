import { Box, Heading } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAuth';
import { SidebarContext } from 'contexts/SidebarContext';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

export default function ResetPassLayout({ hashParam }: { hashParam?: string }) {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Box
          margin="auto"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          alignItems={'center'}
          textAlign="center"
          w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
          p={3}
        >
          <Heading py={2}>Set New Password</Heading>
          <Box mx="auto" p={{ base: '20px', md: '30px', sm: '10px' }} w={{ base: '20%', md: '70%', sm: '90%', xl: '40%' }} minH="90vh">
            <Outlet context={hashParam} />
          </Box>
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
