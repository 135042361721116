import BusinessLayout from 'layouts/business/BusinessLayout';
import ResetPassLayout from 'layouts/password/ResetPassLayout';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedAdminRoute } from 'routes/ProtectedAdminRoute';
import ProtectedBusinessRoute from 'routes/ProtectedBusinessRoute';
import ProtectedResetPassRoute from 'routes/ProtectedResetPassRoute';
import Calendar from 'views/admin/calendar';
import Customers from 'views/admin/customers';
import MainDashboard from 'views/admin/dashboard';
import Notifications from 'views/admin/notifications';
import Reports from 'views/admin/reports';
import BusinessSettings from 'views/admin/settings/business';
import EmployeesSettings from 'views/admin/settings/employees';
import Profile from 'views/admin/settings/profile';
import TreatmentsSettings from 'views/admin/settings/treatments';
import UsersSettings from 'views/admin/settings/users';
import ResetPassword from 'views/auth/resetPassword/ResetPassword';
import SignIn from 'views/auth/signIn/SignIn';
import SignUp from 'views/auth/signUp/SignUp';
import { ChoosePlan } from 'views/business/ChoosePlan';
import UpdateOrCreateBusiness from 'views/business/UpdateOrCreateBusiness';
import ChangePassword from 'views/changePassword/ChangePassword';
import './assets/css/App.css';
import AdminLayout from './layouts/admin/Dashboard';
import AuthLayout from './layouts/auth/Auth';
import './styles/tailwind.css';

export const EasyBookRoutes = () => {
  return (
    <Routes>
      {/* redirect */}
      <Route path="*" element={<Navigate to="/auth/sign-in" replace />} />
      <Route path={'/auth'} element={<AuthLayout />}>
        <Route path={'sign-in'} element={<SignIn />} />
        <Route path={'sign-up'} element={<SignUp />} />
        <Route path={'reset-password'} element={<ResetPassword />} />
      </Route>
      {/* business */}
      <Route
        path={'/business'}
        element={
          <ProtectedBusinessRoute>
            <BusinessLayout />
          </ProtectedBusinessRoute>
        }
      >
        <Route path={'create'} element={<UpdateOrCreateBusiness />} />
        <Route path={'plan'} element={<ChoosePlan />} />
      </Route>
      {/* password change */}
      <Route
        path={'/password-change'}
        element={
          <ProtectedResetPassRoute>
            <ResetPassLayout />
          </ProtectedResetPassRoute>
        }
      >
        <Route index path={''} element={<ChangePassword />} />
      </Route>
      {/* admin panel */}
      <Route
        path={'/admin'}
        element={
          <ProtectedAdminRoute>
            <AdminLayout />
          </ProtectedAdminRoute>
        }
      >
        <Route index path={''} element={<MainDashboard />} />
        <Route path={'reports'} element={<Reports />} />
        <Route path={'customers'} element={<Customers />} />
        <Route path={'calendar'} element={<Calendar />} />
        <Route path={'notifications'} element={<Notifications />} />
        <Route path={'settings'}>
          <Route index path={'profile'} element={<Profile />} />
          <Route index path={'users'} element={<UsersSettings />} />
          <Route path={'business'} element={<BusinessSettings />} />
          <Route path={'employees'} element={<EmployeesSettings />} />
          <Route path={'treatments'} element={<TreatmentsSettings />} />
        </Route>
      </Route>
    </Routes>
  );
};
