// chakra imports
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Heading, Icon, Stack } from '@chakra-ui/react';
import MiniCalendar from 'components/calendar/MiniCalendar';
//   Custom components
import Links from 'components/sidebar/components/Links';
import SidebarBrand from 'components/sidebar/components/SidebarBrand';
import { settingsRoutes } from 'navigations';
import { AuthenticationContext } from 'providers/AuthProvider';
import { BusinessContext } from 'providers/BusinessProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FcSettings } from 'react-icons/fc';
import { filterRoutesByPlan, filterRoutesByRole } from 'shared/helpersFunctions';
import SettingsSidebarLinks from './SettingsLinks';

// FUNCTIONS

function SidebarContent(props: { routes: RoutesType[] }) {
  const { routes } = props;
  const { t } = useTranslation();
  const { setChosenDateAndRouteToCalendar } = useContext(BusinessContext);
  const { userData } = useContext(AuthenticationContext);
  const filteredRoutes = filterRoutesByRole(userData.user.role, filterRoutesByPlan(userData.business.plan, settingsRoutes));
  return (
    <Flex overflowY="scroll" whiteSpace="nowrap" direction="column" height="100%" borderRadius="30px">
      <SidebarBrand />
      <Stack direction="column" mt="8px" mb="auto">
        <Box ps="20px" pe={{ lg: '16px', '2xl': '16px' }}>
          <Links routes={routes} />
        </Box>
        <Box ps="10px">
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Heading size="md" sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {t('Settings')}
                    <Icon as={FcSettings} display="flex" width="20px" height="20px" color="inherit" />
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <SettingsSidebarLinks settingsRoutes={filteredRoutes} />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Stack>

      {/* <Stack direction="column" mt="8px" mb="auto"> */}
      {/* </Stack> */}
      <Box px="10px">
        <MiniCalendar h="100%" minW="100%" selectRange={false} onChange={(value) => setChosenDateAndRouteToCalendar(value)} />
      </Box>
    </Flex>
  );
}

export default SidebarContent;
