import { useColorMode } from '@chakra-ui/color-mode';
import { Heading } from '@chakra-ui/react';

export const Logo = ({ size }: { size?: string }) => {
  const { colorMode } = useColorMode();
  return (
    <Heading
      as="h2"
      mt={6}
      size={size ?? '3xl'}
      color="white"
      textShadow={colorMode === 'dark' ? '2px 2px 4px rgba(0, 0, 0, 0.1)' : '2px 2px 4px rgba(255, 255, 255, 0.8)'}
      bgGradient={colorMode === 'dark' ? 'linear(to-b, rgba(0, 0, 0, 0.01), white)' : 'linear(to-t, rgba(0, 0, 0, 1), white)'}
      bgClip="text"
      fontWeight="extrabold"
      letterSpacing="widest"
    >
      EasyBook
    </Heading>
  );
};
