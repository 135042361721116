import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MdEmail, MdPerson, MdPhone } from 'react-icons/md';
import { useMutation } from 'react-query';
import { updateUser } from 'services/api/user';
import { validateEmail } from 'shared/helpersFunctions';
import { User } from 'shared/types/app.types';
import validator from 'validator';

interface IUserForm {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
}

interface IUserFormProps {
  user: User;
  onCloseModal: () => void;
}

export const UserForm = ({ user, onCloseModal }: IUserFormProps) => {
  const { setUser } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const errorColorBrand = useColorModeValue('red.500', 'red.300');
  const textColorSecondary = 'gray.400';
  const toast = useToast();
  const {
    mutate: updateUserMutation,
    isLoading: isLoadingUpdateUser,
    isError: isErrorUpdateUser,
  } = useMutation(updateUser, {
    onSuccess: async (response, payload) => {
      toast({
        title: t('Updated Successfully'),
        description: t('You have updated your info successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onCloseModal();
      setUser(payload);
    },
    onError: (error) => {
      toast({
        title: t('Updated Failed'),
        description: t('Ops error occurred'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });
  const submitFormLogin = (values: IUserForm, actions: FormikHelpers<IUserForm>) => {
    updateUserMutation({ id: user.id, ...values });
  };

  const userValuesAreTheSame = (values: any) => {
    return values.phone === user.phone && values.email === user.email && values.firstName === user.firstName && values.lastName === user.lastName;
  };

  return (
    <Formik
      initialValues={{
        email: user.email ?? '',
        phone: user.phone ?? '',
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
      }}
      onSubmit={submitFormLogin}
    >
      {(props) => (
        <Form>
          <Field name="firstName">
            {({ field, form }: { field: any; form: any }) => (
              <FormControl mb="10px" isRequired={true} isInvalid={form.errors.firstName && form.touched.firstName}>
                <FormLabel htmlFor="firstName">{t('First Name')}</FormLabel>

                <InputGroup size="md">
                  <Input
                    {...field}
                    id="firstName"
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="firstName"
                    placeholder={t('First Name')}
                    fontWeight="500"
                    size="lg"
                  />
                  <InputLeftElement display="flex" alignItems="center" mt="4px">
                    <Icon color={textColorSecondary} as={MdPerson} />
                  </InputLeftElement>
                </InputGroup>
                <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="lastName">
            {({ field, form }: { field: any; form: any }) => (
              <FormControl mb="10px" isRequired={true} isInvalid={form.errors.lastName && form.touched.lastName}>
                <FormLabel htmlFor="lastName">{t('Last Name')}</FormLabel>

                <InputGroup size="md">
                  <Input
                    {...field}
                    id="lastName"
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="lastName"
                    placeholder={t('Last Name')}
                    fontWeight="500"
                    size="lg"
                  />
                  <InputLeftElement display="flex" alignItems="center" mt="4px">
                    <Icon color={textColorSecondary} as={MdPerson} />
                  </InputLeftElement>
                </InputGroup>
                <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="email" validate={(value: string) => validateEmail(value, t)}>
            {({ field, form }: { field: any; form: any }) => (
              <FormControl mb="10px" isRequired={true} isInvalid={form.errors.email && form.touched.email}>
                <FormLabel htmlFor="email">{t('Email')}</FormLabel>

                <InputGroup size="md">
                  <Input
                    {...field}
                    id="email"
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="email"
                    placeholder="mail@simmmple.com"
                    fontWeight="500"
                    size="lg"
                  />
                  <InputLeftElement display="flex" alignItems="center" mt="4px">
                    <Icon color={textColorSecondary} as={MdEmail} />
                  </InputLeftElement>
                </InputGroup>
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="phone" validator={(phone: string) => (!validator.isMobilePhone(phone, 'he-IL') ? t('Enter a valid phone') : '')}>
            {({ field, form }: { field: any; form: any }) => (
              <FormControl mb="20px" isInvalid={form.errors.phone && form.touched.phone} isRequired={true}>
                <FormLabel htmlFor="phone">{t('Phone')}</FormLabel>
                <InputGroup size="md">
                  <Input
                    {...field}
                    id="phone"
                    fontSize="sm"
                    placeholder="0502223333"
                    size="lg"
                    type={'tel'}
                    variant="auth"
                    sx={{ textAlign: 'right' }}
                  />
                  <InputLeftElement display="flex" alignItems="center" mt="4px">
                    <Icon color={textColorSecondary} as={MdPhone} />
                  </InputLeftElement>
                </InputGroup>
                <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          {isErrorUpdateUser && (
            <>
              <Text color={errorColorBrand} fontSize="sm" mb="12px" fontWeight="500">
                {t('Ops Error Occurred')}
              </Text>
            </>
          )}
          <Button
            isLoading={isLoadingUpdateUser}
            isDisabled={!!Object.keys(props.errors).length || userValuesAreTheSame(props.values)}
            colorScheme="teal"
            type="submit"
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
          >
            {t('Update')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
