// Chakra imports
import { Box, Button, Icon, Modal, ModalContent, ModalHeader, ModalOverlay, Portal, Text, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import mainRoutes from 'navigations';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdPhone } from 'react-icons/md';
import { RiAlertFill } from 'react-icons/ri';
import { Outlet, Route, useLocation, useNavigate } from 'react-router-dom';
import { ACTIVE } from 'shared/consts';
import { filterRoutesByPlan, filterRoutesByRole } from 'shared/helpersFunctions';

// Custom Chakra theme
export default function AdminLayout(props: { [x: string]: any }) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const { userData, toggleReachedLimitPlanModal, reachedPlanModal } = useContext(AuthenticationContext);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenPlanModal, onOpen: onOpenPlanModal, onClose: onClosePlanModal } = useDisclosure();
  const initialRef = useRef();
  const finalRef = useRef();
  const initialRefPlan = useRef();
  const finalRefPlan = useRef();

  useEffect(() => {
    if (userData.user.status.name !== ACTIVE && !location.pathname.includes('admin/settings/profile')) {
      onOpen();
    }
    if (reachedPlanModal) {
      onOpenPlanModal();
    }
  }, [location.pathname, reachedPlanModal, onOpen, onOpenPlanModal, userData.user.status.name]);

  if (!userData.business) {
    return <></>;
  }

  const getActiveRoute = (routes: RoutesType[]): string => {
    const activeRoute = userData.business.name;
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    const activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
    const activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      if (route.layout === '/admin') {
        const Component = route.component;
        return <Route path={route.layout + route.path} element={<Component />} key={key} />;
      } else {
        return null;
      }
    });
  };
  const filteredRoutes = filterRoutesByRole(userData.user.role, filterRoutesByPlan(userData.business.plan, mainRoutes));

  const handleClick = () => {
    window.location.href = 'tel:+972544970183';
    navigate('/admin/settings/profile');
    onClose();
  };

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={filteredRoutes} display="flex" {...rest} />
        <Box
          float="left"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={'EasyBook'}
                brandText={getActiveRoute(filteredRoutes)}
                secondary={getActiveNavbar(filteredRoutes)}
                message={getActiveNavbarText(filteredRoutes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>
          <Box
            // mx="auto"
            // pe="20px"
            px={{ base: '0px', md: '10px', xl: '10px' }}
            minH="100vh"
            // pt="50px"
          >
            <Outlet />
          </Box>
          <Box>
            <Footer />
          </Box>
          {/* Modal for inactive users */}
          <Modal
            size="xl"
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            scrollBehavior={'outside'}
            closeOnOverlayClick={false}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t('Your user is not active')}
                <Icon as={RiAlertFill} h="22px" w="22px" mx={2} color="red.400" />
              </ModalHeader>
              <Box p={5}>
                <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" fontWeight="500">
                  {t('You will need to activate your account before continuing')}
                </Text>
                <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" fontWeight="500">
                  {t('Please contact us for help')}
                </Text>
              </Box>
              <Button
                variant="darkBrand"
                color="white"
                fontSize="md"
                fontWeight="500"
                rightIcon={<Icon as={MdPhone} h="18px" w="18px" />}
                px="24px"
                onClick={handleClick}
                mx={10}
                my={5}
              >
                {t('Call Us')}
              </Button>
            </ModalContent>
          </Modal>
          {/* Modal for reaching plan limits for users/treatments/employees */}
          <Modal
            size="xl"
            initialFocusRef={initialRefPlan}
            finalFocusRef={finalRefPlan}
            isOpen={isOpenPlanModal}
            scrollBehavior={'outside'}
            onClose={() => {
              toggleReachedLimitPlanModal();
              onClosePlanModal();
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t('You have reached the limit of your plan!')}
                <Icon as={RiAlertFill} h="22px" w="22px" mx={2} color="red.400" />
              </ModalHeader>
              <Box p={5}>
                <Text fontWeight="500">{t('You current plan might be too small for your business needs, check out other plans and change it')}</Text>
                <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" fontWeight="500">
                  {t('Please contact us for help')}
                </Text>
              </Box>
              <Button
                variant="darkBrand"
                color="white"
                fontSize="md"
                fontWeight="500"
                px="24px"
                onClick={() => {
                  navigate('/admin/settings/profile');
                  onClosePlanModal();
                }}
                mx={10}
                my={5}
              >
                {t('See our plans')}
              </Button>
            </ModalContent>
          </Modal>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
