import { Icon, IconButton } from '@chakra-ui/react';
import { IconType } from 'react-icons';

export const ThreeDIconButton = ({
  icon,
  onClick,
  iconColor,
  icoButtonColor,
}: {
  icon: IconType;
  onClick: () => void;
  iconColor: string;
  icoButtonColor: string;
}) => {
  return (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      aria-label=""
      sx={{
        background: icoButtonColor,
        borderRadius: '12px',
        transition: 'transform 0.2s, background 0.2s', // Smooth transition for the transform
      }}
      _focus={{
        boxShadow: 'none',
      }}
    >
      <Icon
        as={icon}
        bgColor={iconColor}
        h="100%"
        w="100%"
        sx={{
          borderRadius: '12px',
          border: 'none',
          cursor: 'pointer',
          transform: 'translateY(-6px)',
          outlineOffset: '4px',
          _focus: {
            boxShadow: 'none',
          },
          _hover: {
            transform: 'translateY(-2px)',
          },
          _active: {
            transform: 'translateY(-6px)',
          },
        }}
        borderRadius={'12px'}
      />
    </IconButton>
  );
};
