// Chakra imports
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, InputGroup, Text, useColorModeValue } from '@chakra-ui/react';
import { OperatingHours } from 'components/openingHours/OpeningHours';
// Custom components
// Assets
import { Field, Form, Formik, FormikErrors } from 'formik';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_END_TIME, DEFAULT_START_TIME } from 'shared/consts';
import { BusinessDaysOfWeekType, CreateBusinessStep5, DaysOfBusinessType, DaysOfWeekType } from 'shared/types/app.types';

export default function CreateBusinessStep5Form({
  nextStep5,
  prevStep,
  businessHours,
}: {
  nextStep5: (values: CreateBusinessStep5) => void;
  prevStep: () => void;
  businessHours?: DaysOfBusinessType[];
}) {
  const errorColorBrand = useColorModeValue('red.500', 'red.300');
  const { t } = useTranslation();
  const [daysOfBusiness, setDaysOfBusiness] = useState<BusinessDaysOfWeekType>(businessHours ?? []);
  const { isErrorCreateBusiness, isLoadingUpdateBusiness } = useContext(AuthenticationContext);
  const handleChangeOnDayToggle = (dayOfWeek: DaysOfWeekType) => {
    if (daysOfBusiness.map((dayItem) => dayItem.dayOfWeek).some((dayItem) => dayItem === dayOfWeek)) {
      setDaysOfBusiness(daysOfBusiness.filter((dayItem) => dayItem.dayOfWeek !== dayOfWeek));
    } else {
      setDaysOfBusiness((prev) => [
        ...prev,
        {
          dayOfWeek,
          openTime: DEFAULT_START_TIME,
          closeTime: DEFAULT_END_TIME,
        },
      ]);
    }
  };

  const onChangeTime = (time: string, dayOfWeek: DaysOfWeekType, openOrClose: 'O' | 'C') => {
    setDaysOfBusiness(
      daysOfBusiness.map((dayItem) => {
        if (dayItem.dayOfWeek === dayOfWeek) {
          if (openOrClose === 'C') {
            dayItem.closeTime = time;
          } else {
            dayItem.openTime = time;
          }
        }
        return dayItem;
      }),
    );
  };

  const submitStep5nextStep5 = (values: CreateBusinessStep5) => {
    nextStep5({ daysOfBusiness });
  };

  const disableCreateButton = (errors: FormikErrors<CreateBusinessStep5>) => {
    return !!Object.keys(errors).length;
  };

  return (
    <>
      <Formik
        initialValues={{
          daysOfBusiness: daysOfBusiness,
        }}
        onSubmit={submitStep5nextStep5}
      >
        {(props) => (
          <Form>
            <Field name="daysOfBusiness" validate={() => (!props.values.daysOfBusiness ? t('You must choose a day of business') : '')}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  mb="20px"
                  isInvalid={form.errors.daysOfBusiness && form.touched.daysOfBusiness}
                  // isRequired={true}
                >
                  <FormLabel htmlFor="daysOfBusiness">{t('Opening Hours')}</FormLabel>
                  <InputGroup size="md">
                    <Flex flexDir={'column'} gap="20px">
                      <OperatingHours
                        handleChangeOnDayToggle={handleChangeOnDayToggle}
                        onChangeTime={onChangeTime}
                        isReadOnly={false}
                        daysOfBusiness={daysOfBusiness}
                      />
                    </Flex>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.daysOfBusiness}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {isErrorCreateBusiness && (
              <>
                <Text color={errorColorBrand} fontSize="sm" mb="12px" fontWeight="500">
                  {t('Error Occurred Maybe the Phone/Email is already exists')}
                </Text>
              </>
            )}
            <Flex pt="10px" gap="5px" justifyContent={'end'}>
              <Button color="white" fontSize="sm" fontWeight="500" borderRadius="8px" px="24px" onClick={prevStep} py="5px">
                {t('Back')}
              </Button>
              <Button
                variant="darkBrand"
                color="white"
                fontSize="sm"
                type="submit"
                fontWeight="500"
                isLoading={isLoadingUpdateBusiness}
                isDisabled={disableCreateButton(props.errors)}
                borderRadius="8px"
                px="24px"
                py="5px"
              >
                {t('Next')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}
