export const enMessages: Record<string, string> = {
  true: 'wow amazing',
  'Total revenue for this year': 'Total revenue for this year',
  'Future Appointments': 'Future Appointments',
  'Total Appointments': 'Total Appointments',
  'Appointments Per Treatment': 'Appointments Per Treatment',
  'Appointments Per Employee': 'Appointments Per Employee',
  'Revenue this Month': 'Revenue this Month',
  'Appointments per month on average': 'Appointments per month on average',
  'Treatments Revenue': 'Treatments Revenue',
  'Insights Dashboard': 'Insights Dashboard',
  Calendar: 'Calendar',
  Employees: 'Employees',
  Profile: 'Profile',
  Treatments: 'Treatments',
  Notifications: 'Notifications',
  Business: 'Business',
  Reports: 'Reports',
  Support: 'Support',
  Name: 'Name',
  Phone: 'Phone',
  for: 'for',
  at: 'at',
  APPOINTMENT_CREATE: 'New Appointment',
  APPOINTMENT_UPDATE: 'Appointment Update',
  APPOINTMENT_DELETE: 'Appointment Delete',
  APPOINTMENT_TAKE_ACTION: 'Take Action',
  'Mark all read': 'Mark all read',

  'Appointment Details': 'Appointment Details',
  'Date & Time': 'Date & Time',
  Day: 'Day',
  Error: 'Error',
  "Customer doesn't exist": "Customer doesn't exist",
  'Created By': 'Created By',
  'Delete Appointment?': 'Delete Appointment?',
  "Are you sure? You can't undo this action afterwards.": "Are you sure? You can't undo this action afterwards.",

  'Created new appointment': 'Created new appointment',
  'Updated appointment': 'Updated appointment',
  'Deleted the appointment': 'Deleted the appointment',
  'Created aew appointment that needs to take an action': 'Created aew appointment that needs to take an action',

  'Ops error occurred try again later': 'Ops error occurred try again later',
  'Create Treatment': 'Create Treatment',
  'Description must be provided': 'Description must be provided',
  'Name must be provided': 'Name must be provided',
  'Category Name': 'Category Name',
  'Category Description': 'Category Description',
  'Name is too long': 'Name is too long',
  'Description is too long': 'Description is too long',
  'Name already exists': 'Name already exists',
  'Category Updated Successfully': 'Category Updated Successfully',
  'Category already exists': 'Category already exists',
  'successfully created employee, be aware that this category still has no treatments.':
    'successfully created employee, be aware that this category still has no treatments.',
  'Category Created Successfully': 'Category Created Successfully',
  Minutes: 'Minutes',
  'OPEN = treatment is open for schedule': 'OPEN = treatment is open for schedule',
  Block: 'Block',
  'Ops Error': 'Ops Error',
  'Employees related': 'Employees related',
  'TAKE ACTION = any appointment will be settled with this treatment will be notified to you to approve':
    'TAKE ACTION = any appointment will be settled with this treatment will be notified to you to approve',
  'CLOSE = treatment is close for schedule': 'CLOSE = treatment is close for schedule',
  'Take Action': 'Take Action',
  'Schedule Option': 'Schedule Option',
  'Time of treatment': 'Time of treatment',
  'Pick time': 'Pick time',
  Price: 'Price',
  'Min. 8 characters': 'Min. 8 characters',
  'Enter a valid price': 'Enter a valid price',
  'Please provide a name': 'Please provide a name',
  'Create New Category': 'Create New Category',
  'Add New Category': 'Add New Category',
  'Be aware that if that treatment has appointments related, it can not be deleted':
    'Be aware that if that treatment has appointments related, it can not be deleted',
  'are you sure you want to delete this treatment?': 'are you sure you want to delete this treatment?',
  "You don't have any Category, create new Category": "You don't have any Category, create new Category",

  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',

  'Updated Successfully': 'Updated Successfully',
  'First Name': 'First Name',
  'Logged In Using': 'Logged In Using',
  'You have updated your info successfully': 'You have updated your info successfully',
  'Updated Failed': 'Updated Failed',
  'Ops error occurred': 'Ops error occurred',
  'Phone Number is not valid': 'Phone Number is not valid',

  'By Employees': 'By Employees',
  'No Employees Assigned': 'No Employees Assigned',

  Active: 'Active',
  Inactive: 'Inactive',
  'Edit Profile': 'Edit Profile',
  Female: 'Female',
  'Log out': 'Log out',
  'Last Name': 'Last Name',
  Edit: 'Edit',
  Status: 'Status',
  Male: 'Male',
  'Treatments related': 'Treatments related',
  Delete: 'Delete',
  'Employee activated successfully': 'Employee activated successfully',
  'Employee Created Successfully': 'Employee Created Successfully',
  'successfully deleted employee, be aware that this employee might have appointments related to it.':
    'successfully deleted employee, be aware that this employee might have appointments related to it.',
  'Phone number already exists': 'Phone number already exists',
  'are you sure you want to disable this employee?': 'are you sure you want to disable this employee?',
  'Employee Created Error': 'Employee Created Error',
  'Please provide a first name and last name.': 'Please provide a first name and last name.',
  'Full Name': 'Full Name',
  'Employee Name': 'Employee Name',
  'Select treatments': 'Select treatments',
  'Enter a valid phone': 'Enter a valid phone',
  Cancel: 'Cancel',
  'successfully created employee, be aware that this employee might have appointments related to it.':
    'successfully created employee, be aware that this employee might have appointments related to it.',
  'Employee Deleted Error': 'Employee Deleted Error',
  'There was an error deleting the employee try again later': 'There was an error deleting the employee try again later',
  'Have no treatment': 'Have no treatment',
  'Create Employee': 'Create Employee',
  'Employee Email': 'Employee Email',
  Gender: 'Gender',
  'Employee Updated Successfully': 'Employee Updated Successfully',
  'successfully updated employee, be aware that this employee might have appointments related to it.':
    'successfully updated employee, be aware that this employee might have appointments related to it.',

  Close: 'Close',
  Open: 'Open',
  Sunday: 'Sun',
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',

  Email: 'Email',
  Address: 'Address',
  Color: 'Color',
  Type: 'Type',
  Description: 'Description',
  'You must choose a day of business': 'You must choose a day of business',
  'Your Business': 'Your Business',
  'Error Occurred Maybe the Phone/Email is already exists': 'Error Occurred Maybe the Phone/Email is already exists',
  'Opening Hours': 'Opening Hours',
  'Save changes': 'Save changes',
  'Edit Business': 'Edit Business',
  'Basic Info': 'Basic Info',
  'Color & Type': 'Color & Type',
  'Business Operating Hours': 'Business Operating Hours',
  'Creating New Business!': 'Creating New Business!',
  'Please add Business Name': 'Please add Business Name',
  'Business Address': 'Business Address',
  'some-address': 'Baker Street 14 New York City',
  'Business Color': 'Business Color',
  'Business Type': 'Business Type',
  'Select your business type': 'Select your business type',
  'Barber Shop': 'Barber Shop',
  'Beauty Salon': 'Beauty Salon',
  'Nail Salon': 'Nail Salon',
  Spa: 'Spa',
  'Fitness Center': 'Fitness Center',
  'Yoga Studio': 'Yoga Studio',
  'Massage Therapy': 'Massage Therapy',
  'Consulting Firm': 'Consulting Firm',
  'Web Design Agency': 'Web Design Agency',
  'Accounting Services': 'Accounting Services',
  'Legal Services': 'Legal Services',
  'Real Estate Agency': 'Real Estate Agency',
  'Travel Agency': 'Travel Agency',
  'Auto Repair Shop': 'Auto Repair Shop',
  Other: 'Other',

  Privacy: 'Privacy',
  'Terms of Use': 'Terms of Use',
  Today: 'Today',
  Month: 'Month',
  Week: 'Week',
  List: 'List',

  'Business is close': 'Business is close',
  Settings: 'Settings',
  'Month Report': 'Month Report',
  Weekends: 'Weekends',
  'Update Appointment': 'Update Appointment',
  'No report for this year': 'No report for this year',
  'Updating Appointment': 'Updating Appointment',
  'Creating New Appointment! 🎉': 'Creating New Appointment! 🎉',
  'Create Appointment at': 'Create Appointment at',
  'Customer And Treatment': 'Customer And Treatment',
  'No employee can give all those treatments': 'No employee can give all those treatments',
  Employee: 'Employee',
  'Add your comment here': 'Add your comment here',
  'Your Name': 'Your Name',
  'An Error occurred, maybe the employee is already busy': 'An Error occurred, maybe the employee is already busy',
  Comment: 'Comment',
  Treatment: 'Treatment',
  'Free Text': 'Free Text',
  'Select Customer': 'Select Customer',
  'Select Employee': 'Select Employee',
  'Your business description': 'Your business description',
  'Select treatment': 'Select treatment',
  'You have no notifications': 'You have no notifications',
  Customer: 'Customer',
  Morning: 'Morning',
  Noon: 'Noon',
  Evening: 'Evening',
  Create: 'Create',
  Update: 'Update',
  Time: 'Time',
  Approve: 'Approve',
  Back: 'Back',
  Next: 'Next',
  To: 'To',
  'Change appointment date?': 'Change appointment date?',
  'Are you sure you want to change to appointment date?': 'Are you sure you want to change to appointment date?',
  From: 'From',
  Change: 'Change',
  'An Error occurred, maybe the employee is already busy? Or maybe Business is closed that day?':
    'An Error occurred, maybe the employee is already busy? Or maybe Business is closed that day?',

  'Sign In': 'Sign In',
  'Reset Password': 'Reset Password',
  'Forgot your password?': 'Forgot your password?',
  'Create an Account': 'Create an Account',
  'Sign in with Google': 'Sign in with Google',
  'Not registered yet?': 'Not registered yet?',
  'Sign in with Facebook': 'Sign in with Facebook',
  'Email or Password are invalid': 'Email or Password are invalid',
  'Enter your email and password to sign in!': 'Enter your email and password to sign in!',
  or: 'or',
  Password: 'Password',

  'Create account': 'Create account',
  'Email Recovery': 'Email Recovery',
  'Know the password?': 'Know the password?',
  'Email does not exist': 'Email does not exist',
  'Account Recovery': 'Account Recovery',
  'Enter your email to recover your account!': 'Enter your email to recover your account!',
  'Sign in': 'Sign in',
  'Sign Up': 'Sign Up',
  Closed: 'Closed',
  Refresh: 'Refresh',
  'Already have an account?': 'Already have an account?',
  'Confirm Password': 'Confirm Password',
  'Your Full Name': 'Your Full Name',
  'Lets create a new account for you': 'Lets create a new account for you',

  'Please add Street Address': 'Please add Street Address',
  Optional: 'Optional',
  City: 'City',
  Role: 'Role',
  'State (Optional)': 'State (Optional)',
  Country: 'Country',
  'Please add City': 'Please add City',
  'Please add Country': 'Please add Country',
  'Please add ZIP Code': 'Please add ZIP Code',
  'Please enter a valid ZIP Code': 'Please enter a valid ZIP Code',
  'ZIP Code': 'ZIP Code',
  'Select Employees': 'Select Employees',
  'Treatment Example': 'Treatment Example',
  'Please add State': 'Please add State',
  'Street Address': 'Street Address',
  'Street Address & number': 'Street Address & number',
  'Your user is not active': 'Your user is not active',
  'You will need to activate your account before continuing': 'You will need to activate your account before continuing',
  'Profile Page': 'Profile Page',

  month: 'month',
  'Full Analytics': 'Full Analytics Page',
  Users: 'Users',
  Invoices: 'Invoices',
  'Automated Reminder': 'Automated Reminder',
  'Change Plan': 'Change Plan',

  'Upgrade the plan for your business': 'Upgrade the plan for your business',
  'Call Us': 'Call Us',
  'Choose your plan': 'Choose your plan',
  'Create plan': 'Create plan',
  'Pick a plan': 'Pick a plan',

  'Error creating plan for your business': 'Error creating plan for your business',
  'We encountered an error creating plan for your business, try again later':
    'We encountered an error creating plan for your business, try again later',
  'You plan was set successfully, congratulations!': 'You plan was set successfully, congratulations!',
  'You business plan was set successfully you can now log in and enjoy (EasyBook)':
    'You business plan was set successfully you can now log in and enjoy (EasyBook)',
  'Login Successfully': 'Login Successfully',
  'Welcome to EasyBook': 'Welcome to EasyBook',
  'Error logging in': 'Error logging in',
  'oops error logging in': 'oops error logging in',
  'Business Created Successfully': 'Business Created Successfully',
  'Now choose a plan for your business': 'Now choose a plan for your business',
  'Failed Creating Business': 'Failed Creating Business',
  'Failed to create business it is possible that your credentials is already taken like Phone or Email':
    'Failed to create business it is possible that your credentials is already taken like Phone or Email',
  'Business Updated Successfully': 'Business Updated Successfully',
  'successfully updated business': 'successfully updated business',
  'Oops there was an error updating business': 'Oops there was an error updating business',
  'please try again later': 'please try again later',
  'Your password has been reset': 'Your password has been reset',
  'Oops! Something went wrong': 'Oops! Something went wrong',
  'Email Sent Successfully': 'Email Sent Successfully',
  'The email was sent successfully, check out your mailbox to reset your password':
    'The email was sent successfully, check out your mailbox to reset your password',
  'User Registered Successfully': 'User Registered Successfully',
  'you successfully created a new user!': 'you successfully created a new user!',
  'Registration Failure': 'Registration Failure',
  'failed to signing up': 'failed to signing up',
  'Please contact us for help': 'Please contact us for help',

  'Appointment updated successfully': 'Appointment updated successfully',
  'You have reached the limit of your plan!': 'You have reached the limit of your plan!',
  'You current plan might be too small for your business needs, check out other plans and change it':
    'You current plan might be too small for your business needs, check out other plans and change it',
  'See our plans': 'See our plans',
  'Registered Successfully': 'Registered Successfully',
  'Email must be a valid email address': 'Email must be a valid email address',
  'Description must be at least 100 characters maximum': 'Description must be at least 100 characters maximum',
  'Password must be 8 at least': 'Password must be 8 at least',
  'Passwords Does not match': 'Passwords Does not match',
  'New Password': 'New Password',
  'Confirm New Password': 'Confirm New Password',
  'Confirm new password': 'Confirm new password',
  'Error resetting your password': 'Error resetting your password',
  'Change Password': 'Change Password',
  'This is not visible for Basic Plan': 'This is not visible for Basic Plan',
  'No appointments in that given month': 'No appointments in that given month',

  'Create User': 'Create User',
  'User Full Name': 'User Full Name',
  'User Email': 'User Email',
  'User Role': 'User Role',
  'User Created Successfully': 'User Created Successfully',
  'User Creation Error': 'User Creation Error',
  'User Password': 'User Password',
  'User Updated Successfully': 'User Updated Successfully',
  'are you sure you want to delete this user?': 'are you sure you want to delete this user?',
  'User Deleted Error': 'User Deleted Error',
  User: 'User',
  'Absence?': 'Absence?',
  'Add a absence to one of the employees': 'Add a absence to one of the employees',
  'New Appointment?': 'New Appointment?',
  'Create a new appointment with customer, treatment etc...': 'Create a new appointment with customer, treatment etc...',
  'Choose an Option': 'Choose an Option',
  'Error, times must be valid': 'Error, times must be valid',

  Absence: 'Absence',
  'Employee Absence': 'Employee Absence',
  'Absence Details': 'Absence Details',
  'Maybe employee is already busy in those times': 'Maybe employee is already busy in those times',
  'Absence Created Successfully': 'Absence Created Successfully',
  'Appointment Updated Successfully': 'Appointment Updated Successfully',
  'successfully updated appointment': 'successfully updated appointment',
  'failed to update appointment': 'failed to update appointment',
  'failed to create appointment': 'failed to create appointment',
  'Appointment Created Successfully': 'Appointment Created Successfully',
  'Appointment Deletion failure': 'Appointment Deletion failure',
  'Absence Update Successfully': 'Absence Update Successfully',
  'failed to delete appointment': 'failed to delete appointment',

  'Absences Per Employee': 'Absences Per Employee',
  'No absences for this given month': 'No absences for this given month',
  'Invalid business hours': 'Invalid business hours',
  'Business is not open on that day': 'Business is not open on that day',
  'No appointments for this given month': 'No appointments for this given month',

  'Category deleted': 'Category deleted',
  'You need to delete all treatments before deleting the category': 'You need to delete all treatments before deleting the category',
  "Failed to update the customer's status. Please try again.": "Failed to update the customer's status. Please try again.",
  'The customer has been successfully blocked.': 'The customer has been successfully blocked.',
  'The customer has been successfully unblocked.': 'The customer has been successfully unblocked.',
  'Are you sure you want to remove customer from blocking list?': 'Are you sure you want to remove customer from blocking list?',
  'Do you want to add this customer to the blocking list?': 'Do you want to add this customer to the blocking list?',
  'Remove Confirmation': 'Remove Confirmation',
  'Add Confirmation': 'Add Confirmation',
  Customers: 'Customers',
  'Is Blocked': 'Is Blocked',
  'Search by name Or Phone Number': 'Search by name Or Phone Number',
  Blocked: 'Blocked',
  Unblocked: 'Unblocked',
  Reason: 'Reason',
  Confirmation: 'Confirmation',
  Confirm: 'Confirm',
  Successfully: 'Successfully',
  Upload: 'Upload',
  'Revenue Per Month': 'Revenue Per Month',
  Social: 'Social',
  Instagram: 'Instagram',
  Facebook: 'Facebook',
  'not required': 'not required',
  'What is the reason?': 'What is the reason?',

  'Updating your business': 'Updating your business',
  'Creating Your Business!': 'Creating Your Business',
  'Maximum number of treatments per appointment': 'Maximum number of treatments per appointment',
  'Your can not cross the limit': 'Your can not cross the limit',
  'There is a limit for this number': 'There is a limit for this number',
  'Amount of hours in advance that clients will be allowed to cancel': 'Amount of hours in advance that clients will be allowed to cancel',
  appointments: 'appointments',
  'Maximum number of future per appointments client can have': 'Maximum number of future per appointments client can have',
  hours: 'hours',
  'Hours of cancelation in advance': 'Hours of cancelation in advance',
  'Max of future appointments': 'Max of future appointments',
  'Number of treatments per appointment': 'Number of treatments per appointment',

  minutes: 'minutes',
  inactive: 'inactive',
  active: 'active',
  'Employee deactivated successfully': 'Employee deactivated successfully',
  'are you sure you want to activate this employee?': 'are you sure you want to activate this employee?',
  'Created from waiting list': 'Created from waiting list',
  Days: 'Days',
  'Edit Logo': 'Edit Logo',
  'Choose Employee/s': 'Choose Employee/s',
  'Edit Background': 'Edit Background',
  'Max days ahead that appointment can be settled': 'Max days ahead that appointment can be settled',

  'Select your date': 'Select your date',
};
