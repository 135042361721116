// Chakra imports
import { DownloadIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, Icon, IconButton, Select, useColorModeValue } from '@chakra-ui/react';
import MonthCard from 'components/cards/MonthCard';
import IconBox from 'components/icons/IconBox';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCalendarMonth, MdReport } from 'react-icons/md';
import { useQuery } from 'react-query';
import { getMonthsSummary } from 'services/api/business';
import { ACTIVE, ADMIN, MONTHS_NAMES } from 'shared/consts';

export default function Reports() {
  const { userData } = useContext(AuthenticationContext);
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const { t } = useTranslation();
  const businessId = userData.business.id;
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const { data: monthsData, isLoading: isLoadingMonths } = useQuery<any>(['months', year], () => getMonthsSummary(businessId, year), {
    select: (response) => Object.fromEntries(Object.entries(response)),
    enabled: userData.user.status.name === ACTIVE && userData.user.role.name === ADMIN && userData.business.plan.name !== 'Basic',
  });

  if (!monthsData) {
    return <></>;
  }
  const monthsNames = Object.keys(monthsData).sort((a, b) => MONTHS_NAMES.indexOf(a) - MONTHS_NAMES.indexOf(b));
  return (
    <Box pt={{ base: '100px', md: '90px', xl: '90px' }} px={{ base: '10px', md: '10px' }} display="flex" flexDirection={'column'} gap={2}>
      <Box>
        <Select
          fontSize="3xl"
          variant="auth"
          textAlign={'left'}
          width="200px"
          fontWeight="700"
          onChange={(event: any) => setYear(+event.target.value)}
          value={year}
        >
          {[year - 1, year, year + 1].map((month, index) => (
            <option key={index} value={month}>
              {month}
            </option>
          ))}
        </Select>
      </Box>
      {monthsNames.length === 0 && (
        <Flex px={{ base: '10px', md: '10px' }} alignItems="center">
          <Heading size="md" fontWeight={'700'}>
            {t('No report for this year')}
          </Heading>
          <IconBox w="36px" h="36px" icon={<Icon as={MdReport} display="flex" color={brandColor} width="20px" height="20px" />} />
        </Flex>
      )}
      {monthsNames.map((month: any) => {
        return (
          <MonthCard
            key={month}
            startContent={<IconBox w="56px" h="56px" bg={brandColor} icon={<Icon w="32px" h="32px" as={MdCalendarMonth} color={'white'} />} />}
            name={`${t('Month Report')} (${monthsData[month]} ${t('appointments')})`}
            value={`${t(month)}`}
            endContent={
              <IconButton
                aria-label="add category"
                // onClick={() => onOpenCategoryDialog()}
                sx={{
                  width: '70px',
                  backgroundColor: brandColor,
                  color: brandColor,
                  // zIndex: 3000,
                }}
                icon={<DownloadIcon color={'white'} w="25px" h="25px" />}
              />
            }
          />
        );
      })}
    </Box>
  );
}
