/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Flex, Heading, useColorModeValue } from '@chakra-ui/react';

// Custom components

// Assets
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Employees from './components/Employees';

export default function EmployeesSettings() {
  const { userData } = useContext(AuthenticationContext);
  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const { t } = useTranslation();
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box pt={{ base: '100px', md: '80px', xl: '80px' }}>
      <Flex direction="column" gap={6}>
        <Heading color={textColor} size="xl" ms="24px" fontWeight="700">
          {t('Employees')}
        </Heading>
        <Box p={4}>{userData.business && <Employees businessId={userData.business.id} />}</Box>
      </Flex>
    </Box>
  );
}
