import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { updateNotificationsStatus } from 'services/api/notifications';

export const useNotifStatusMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { mutate: updateNotificationsStatusMutation, isLoading: isLoadingStatusChange } = useMutation(updateNotificationsStatus, {
    onSuccess: async (response, payload) => {
      await queryClient.invalidateQueries('notifications');
      toast({
        title: t('All notifications read successfully'),
        description: t('Notifications read successfully, but you can still view them in the notifications page'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  return { updateNotificationsStatusMutation, isLoadingStatusChange };
};
