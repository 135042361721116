// Chakra imports
import { Flex, Stat, StatLabel, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/cards/Card';

export default function MiniCard(props: {
  startContent?: JSX.Element;
  endContent?: JSX.Element;
  name: string;
  growth?: string | number;
  value: string | number;
  businessColor?: string;
}) {
  const { startContent, endContent, name, value, businessColor } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';

  return (
    <Card py="10px" className="shadow">
      <Flex my="auto" alignItems={'center'} h="100%" align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
        {startContent}

        <Stat my="auto" ms={startContent ? '18px' : '0px'}>
          <StatLabel
            lineHeight="100%"
            color={textColorSecondary}
            fontSize={{
              base: 'sm',
            }}
            sx={{ marginBottom: '5px' }}
          >
            {name}
          </StatLabel>
          <Text color={businessColor ?? textColor} fontWeight="700">
            {value}
          </Text>
          {/* {growth ? (
            <Flex align="center">
              <Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
                {growth}
              </Text>
              <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                since last month
              </Text>
            </Flex>
          ) : null} */}
        </Stat>
        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
