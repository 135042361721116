/* eslint-disable */
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import rtl from 'stylis-plugin-rtl';
// NB: A unique `key` is important for it to work!
let options = {
  rtl: { key: 'css-ar', stylisPlugins: [rtl] },
  ltr: { key: 'css-en' },
};
export function RtlProvider({ children }: any) {
  const dir = document.documentElement.dir == 'ar' ? 'rtl' : 'ltr';
  document.documentElement.dir = 'rtl';
  const cache = createCache(options[dir]);
  return <CacheProvider value={cache} children={children} />;
}
