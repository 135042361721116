import axiosConfig from 'shared/axiosConfig';
import { AuthForgotPasswordDto, AuthRegisterLoginDto, AuthResetPasswordDto } from 'shared/types/app.types';

export async function login({ email, password }: { email: string; password: string }) {
  const result = await axiosConfig.post('/auth/user/email/login', {
    email,
    password,
  });
  return result.data;
}

export async function registerUser(user: AuthRegisterLoginDto) {
  const result = await axiosConfig.post('/auth/user/email/register', user);
  return result.data;
}

export async function resetPassword(resetPassData: AuthResetPasswordDto) {
  const result = await axiosConfig.post('/auth/user/reset/password', resetPassData);
  return result.data;
}

export async function forgotPassword(payload: AuthForgotPasswordDto) {
  const result = await axiosConfig.post('/auth/user/forgot/password', payload);
  return result.data;
}
