/*eslint-disable*/

import { Flex, Link, List, ListItem, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const textColor = useColorModeValue('gray.400', 'white');
  const { t } = useTranslation();

  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: 'column',
        xl: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent="space-between"
      px={{ base: '30px', md: '50px' }}
      pb="30px"
    >
      <List display="flex" w={'100%'} justifyContent={'center'}>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={textColor} href="mailto:hello@simmmple.com">
            {t('Support')}
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={textColor} href="https://simmmple.com/terms-of-service">
            {t('Terms of Use')}
          </Link>
        </ListItem>
        <ListItem>
          <Link fontWeight="500" color={textColor} href="https://www.blog.simmmple.com/">
            {t('Privacy')}
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
