import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  Table,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { queryClient } from 'index';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { blockUnblockCustomer } from 'services/api/business';
import { useBusinessBlockedCustomers } from 'shared/hooks/useBusinessBlockedCustomers';
import { useBusinessCustomers } from 'shared/hooks/useBusinessCustomers';
import { CustomerEntity } from 'shared/types/app.types';

export default function Customers() {
  const { userData } = useContext(AuthenticationContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { t } = useTranslation();
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerEntity | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [reason, setReason] = useState<string>('');

  const bgColor = useColorModeValue('white', 'brand.400');
  const tableBorderColor = useColorModeValue('secondaryGray.500', 'brand.700');
  const headerBgColor = useColorModeValue('secondaryGray.200', 'brand.900');
  const headerTextColor = useColorModeValue('secondaryGray.900', 'white');
  const hoverBgColor = useColorModeValue('secondaryGray.100', 'brand.700');

  // Simulated query to fetch data
  const { customers, isSuccessCustomers, isLoadingCustomers } = useBusinessCustomers({
    businessData: userData.business,
  });
  const { blockedCustomers, isSuccessBlockedCustomers } = useBusinessBlockedCustomers({
    businessData: userData.business,
  });
  const blockedIds = blockedCustomers.map((customer) => customer.customer.id);
  // Mutation for toggling the blocked state
  const {
    mutate: blockUnblockMutation,
    isError: isErrorBlockUnblockCustomer,
    isLoading: isLoadingBlockUnblockCustomer,
  } = useMutation(blockUnblockCustomer, {
    onSuccess: async (data) => {
      onClose();
      await queryClient.invalidateQueries('customers');
      await queryClient.invalidateQueries('blocked-customers');
      // Dynamically update the toast message based on action (block or unblock)
      const action = data.isBlocked ? t('Blocked') : t('Unblocked');
      const description = data.isBlocked ? t('The customer has been successfully blocked.') : t('The customer has been successfully unblocked.');
      toast({
        title: `${action} ${t('Successfully')}`,
        description: description,
        status: 'success',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('Oops! Something went wrong'),
        description: t("Failed to update the customer's status. Please try again."),
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      });
    },
  });

  // Handle switch toggle
  const handleToggle = (customer: CustomerEntity) => {
    setSelectedCustomer(customer);
    onOpen();
  };

  // Handle confirmation modal
  const handleConfirm = () => {
    if (selectedCustomer) {
      blockUnblockMutation({
        businessId: userData.business.id,
        businessPayload: {
          customerId: selectedCustomer.id,
          reason: reason,
        },
      });
    }
  };

  // Filter customers by search term
  const filteredCustomers = customers.filter(
    (customer) =>
      `${customer.firstName} ${customer.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) || customer.phone.includes(searchTerm),
  );

  const isSelectedCustomerIsBlocked = blockedIds.includes(selectedCustomer?.id);

  return (
    <Box width="100%" h={'90vh'} p={4} mt={20} borderRadius="md">
      {/* Search Input */}
      <InputGroup mb={4}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon />
        </InputLeftElement>
        <Input border={'1px solid black'} placeholder={t('Search by name Or Phone Number')} onChange={(e) => setSearchTerm(e.target.value)} />
      </InputGroup>

      {/* Modal for confirmation */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader sx={{ textAlign: 'center' }}>{t(isSelectedCustomerIsBlocked ? 'Remove Confirmation' : 'Add Confirmation')}</ModalHeader>
          <ModalBody>
            {t(
              isSelectedCustomerIsBlocked
                ? 'Are you sure you want to remove customer from blocking list?'
                : 'Do you want to add this customer to the blocking list?',
            )}
            {!isSelectedCustomerIsBlocked && (
              <Textarea placeholder={t('What is the reason?')} size="md" onChange={(e: any) => setReason(e.target.value)} />
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleConfirm}>
              {t('Confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Table */}
      {isLoadingCustomers ? (
        <Spinner />
      ) : (
        <Table variant="simple" bg={bgColor} border="1px solid" borderColor={tableBorderColor} borderRadius="md" shadow="sm">
          <Thead bg={headerBgColor}>
            <Tr>
              <Th color={headerTextColor}>{t('Full Name')}</Th>
              <Th color={headerTextColor}>{t('Phone')}</Th>
              <Th color={headerTextColor}>{t('Reason')}</Th>
              <Th color={headerTextColor}>{t('Is Blocked')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredCustomers.map((customer) => (
              <Tr key={customer.id} _hover={{ bg: hoverBgColor }} borderBottom="1px solid" borderColor={tableBorderColor}>
                <Td>{`${customer.firstName} ${customer.lastName}`}</Td>
                <Td>{customer.phone}</Td>
                <Td>{blockedCustomers.find((bc) => bc.customer.id === customer.id)?.reason}</Td>
                <Td>
                  <Switch size="lg" isChecked={blockedIds.includes(customer.id)} sx={{ direction: 'ltr' }} onChange={() => handleToggle(customer)} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}
