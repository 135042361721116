import axiosConfig from 'shared/axiosConfig';
import { CreateCategoryDTO, CreateTreatmentDTO, UpdateTreatmentDTO } from 'shared/types/app.types';

export async function getTreatments(businessId: string) {
  const result = await axiosConfig.get('/treatments', {
    params: { businessId: businessId },
  });
  return result.data;
}

export async function getCategories(businessId: string) {
  const result = await axiosConfig.get('/treatments/categories', {
    params: { businessId: businessId },
  });
  return result.data;
}

export async function createTreatment(treatmentForm: CreateTreatmentDTO) {
  const result = await axiosConfig.post('/treatments', treatmentForm);
  return result.data;
}

export async function createCategory({ categoryForm, businessId }: { categoryForm: CreateCategoryDTO; businessId: string }) {
  const result = await axiosConfig.post('/treatments/category', categoryForm, {
    params: { businessId: businessId },
  });
  return result.data;
}

export async function updateCategory({ categoryForm, categoryId }: { categoryForm: CreateCategoryDTO; categoryId: string }) {
  const result = await axiosConfig.patch(`/treatments/category/${categoryId}`, categoryForm);
  return result.data;
}

export async function deleteCategory({ categoryId }: { categoryId: string }) {
  const result = await axiosConfig.delete(`/treatments/category/${categoryId}`);
  return result.data;
}

export async function updateTreatment({ updatedTreatment, treatId }: { updatedTreatment: UpdateTreatmentDTO; treatId: string }) {
  const result = await axiosConfig.patch(`/treatments/${treatId}`, updatedTreatment);
  return result.data;
}

export async function deleteTreatment({ treatId }: { treatId: string }) {
  const result = await axiosConfig.delete(`/treatments/${treatId}`);
  return result.data;
}
