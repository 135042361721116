import validator from 'validator';
// Chakra imports
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Icon, Input, InputGroup, InputLeftElement, Textarea } from '@chakra-ui/react';
// Custom components
// Assets
import { Field, Form, Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { MdBusiness, MdDescription, MdEmail, MdPhone } from 'react-icons/md';
import { validateDescription, validateEmail } from 'shared/helpersFunctions';
import { CreateBusinessStep1 } from 'shared/types/app.types';

export default function CreateBusinessStep1Form({
  nextStep1,
  step1,
}: {
  nextStep1: (values: CreateBusinessStep1) => void;
  step1: CreateBusinessStep1;
}) {
  const textColorSecondary = 'gray.400';
  const { t } = useTranslation();
  const submitStep1 = (values: CreateBusinessStep1) => {
    nextStep1(values);
  };

  const disableCreateButton = (errors: FormikErrors<Record<string, never>>) => {
    return !!Object.keys(errors).length;
  };

  return (
    <>
      <Formik
        initialValues={{
          name: step1.name ?? '',
          phone: step1.phone ?? '',
          email: step1.email ?? '',
          description: step1.description ?? '',
        }}
        onSubmit={submitStep1}
      >
        {(props) => (
          <Form>
            <Field name="name" validate={(value: string) => (!value ? t('Please add Business Name') : '')}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isRequired={true} isInvalid={form.errors.name && form.touched.name}>
                  <FormLabel htmlFor="name">{t('Name')}</FormLabel>

                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="name"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type="name"
                      placeholder={t('Your Name')}
                      fontWeight="500"
                      size="lg"
                    />
                    <InputLeftElement display="flex" alignItems="center" mt="4px">
                      <Icon color={textColorSecondary} as={MdBusiness} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="phone" validate={() => (!validator.isMobilePhone(props.values.phone, 'he-IL') ? t('Phone Number is not valid') : '')}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isRequired={true} isInvalid={form.errors.phone && form.touched.phone}>
                  <FormLabel htmlFor="phone">{t('Phone')}</FormLabel>

                  <InputGroup size="md">
                    <Input
                      {...field}
                      value={form.values.phone}
                      id="phone"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type={'tel'}
                      placeholder="0503339999"
                      fontWeight="500"
                      size="lg"
                      sx={{ textAlign: 'right' }}
                    />
                    <InputLeftElement display="flex" alignItems="center" mt="4px">
                      <Icon color={textColorSecondary} as={MdPhone} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="email" validate={(value: string) => validateEmail(value, t)}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isRequired={true} isInvalid={form.errors.email && form.touched.email}>
                  <FormLabel htmlFor="email">{t('Email')}</FormLabel>

                  <InputGroup size="md">
                    <InputLeftElement alignItems="center" mt="4px">
                      <Icon color={textColorSecondary} as={MdEmail} />
                    </InputLeftElement>
                    <Input
                      {...field}
                      id="email"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type={'text'}
                      placeholder="email@gmail.com"
                      fontWeight="500"
                      size="lg"
                    />
                  </InputGroup>
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="description" validate={(value: string) => validateDescription(value, t)}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isRequired={true} isInvalid={form.errors.description && form.touched.description}>
                  <FormLabel htmlFor="description">{t('Description')}</FormLabel>

                  <InputGroup size="md">
                    <InputLeftElement alignItems="center" mt="4px">
                      <Icon color={textColorSecondary} as={MdDescription} />
                    </InputLeftElement>
                    <Textarea
                      {...field}
                      id="description"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type={'text'}
                      placeholder={t('Your business description')}
                      fontWeight="500"
                      size="lg"
                    />
                  </InputGroup>
                  <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex pt="10px" gap="5px" justifyContent={'end'}>
              <Button
                variant="darkBrand"
                color="white"
                fontSize="sm"
                type="submit"
                fontWeight="500"
                isDisabled={disableCreateButton(props.errors)}
                borderRadius="8px"
                px="24px"
                py="5px"
              >
                {t('Next')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}
