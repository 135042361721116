import { useQuery } from 'react-query';
import { getBusinessHours } from 'services/api/business';
import { DaysOfBusinessType } from 'shared/types/app.types';

export const useBusinessHours = ({ businessId }: { businessId: string }) => {
  const { data: businessHours, isLoading: isLoadingBusinessHours } = useQuery<DaysOfBusinessType[]>(
    ['businessHours'],
    () => getBusinessHours(businessId),
    {},
  );

  return { businessHours: businessHours ?? [], isLoadingBusinessHours };
};
