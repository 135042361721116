import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getUsers } from 'services/api/user';
import { ACTIVE, ADMIN } from 'shared/consts';

export const useUsers = () => {
  const { userData } = useContext(AuthenticationContext);
  const { data: users, isLoading: isLoadingUsers } = useQuery<any[]>(['users'], () => getUsers(userData.business.id), {
    select: (response) => response.filter((user) => user.id !== userData.user.id),
    enabled: userData.user.status.name === ACTIVE && userData.user.role.name === ADMIN,
    refetchOnMount: false,
  });

  return { users: users ?? [], isLoadingUsers };
};
