import axiosConfig from 'shared/axiosConfig';
import { UpdateNotificationsReadDto } from 'shared/types/app.types';

export async function getNotifications(businessId: string) {
  const result = await axiosConfig.get(`/notifications/${businessId}`);
  return result.data;
}

export async function updateNotificationsStatus({
  notificationsIds,
  businessId,
}: {
  notificationsIds: UpdateNotificationsReadDto;
  businessId: string;
}) {
  const result = await axiosConfig.patch(`/notifications/status-change/${businessId}`, notificationsIds);
  return result.data;
}
