import axiosConfig from 'shared/axiosConfig';
import { CreateUserDto, UpdateUserDto } from 'shared/types/app.types';

export async function updateUser(user: UpdateUserDto & { id: number }) {
  const result = await axiosConfig.patch(`/users/${user.id}`, user);
  return result.data;
}

export async function updateUserByAdmin(user: UpdateUserDto & { id: number }) {
  const result = await axiosConfig.patch(`/users/admin/${user.id}`, user);
  return result.data;
}

export async function getUsers(businessId: string) {
  const result = await axiosConfig.get('/users', {
    params: {
      filters: JSON.stringify({
        business: businessId,
      }),
    },
  });
  return result.data.data;
}

export async function createUser(userData: CreateUserDto) {
  const result = await axiosConfig.post('/users', userData);
  return result.data;
}

export async function deleteUser(userId: number) {
  const result = await axiosConfig.delete(`/users/${userId}`);
  return result.data;
}
