import { cloneElement, FunctionComponent, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface IProtectedResetPassRouteProps {
  children: ReactElement;
}

const ProtectedResetPassRoute: FunctionComponent<IProtectedResetPassRouteProps> = ({ children }: IProtectedResetPassRouteProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hashParam = searchParams.get('hash');
  if (!hashParam) {
    return <Navigate to="/auth/sign-in" />;
  }
  return cloneElement(children, { hashParam });
};

export default ProtectedResetPassRoute;
