// Chakra imports
import { Box, Flex, Heading, Icon, Spinner, useColorModeValue } from '@chakra-ui/react';
import NotificationCard from 'components/cards/NotificationCard';
import IconBox from 'components/icons/IconBox';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MdNotifications } from 'react-icons/md';
import { useNotifications } from 'shared/hooks/useNotifications';
import { NotificationItem } from 'shared/types/app.types';

export default function Notifications() {
  const { userData } = useContext(AuthenticationContext);
  const brandColor = useColorModeValue('brand.500', 'brand.100');
  const { t } = useTranslation();
  const businessId = userData.business.id;
  const { notifications, isLoadingNotifications } = useNotifications({
    businessId,
  });

  return (
    <Box pt={{ base: '100px', md: '90px', xl: '90px' }} display="flex" flexDirection={'column'}>
      {isLoadingNotifications && <Spinner />}
      {notifications.length === 0 && (
        <Flex px={{ base: '10px', md: '10px' }} alignItems="center">
          <Heading size="md" fontWeight={'700'}>
            {t('You have no notifications')}
          </Heading>
          <IconBox w="36px" h="36px" icon={<Icon as={MdNotifications} display="flex" color={brandColor} width="20px" height="20px" />} />
        </Flex>
      )}
      {notifications.map((notification: NotificationItem) => {
        return <NotificationCard key={notification.id} notification={notification} />;
      })}
    </Box>
  );
}
