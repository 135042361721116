import { Box, Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import { AuthenticationContext } from 'providers/AuthProvider';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Users from './components/Users';

const UsersSettings: FunctionComponent = () => {
  const { userData } = useContext(AuthenticationContext);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { t } = useTranslation();

  return (
    <Box pt={{ base: '100px', md: '80px', xl: '80px' }}>
      <Flex direction="column" gap={6}>
        <Heading color={textColor} size="xl" ms="24px" fontWeight="700">
          {t('Users')}
        </Heading>
        <Box p={4}>
          <Users businessId={userData.business.id} />
        </Box>
      </Flex>
    </Box>
  );
};

export default UsersSettings;
