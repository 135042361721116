import { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

// Chakra imports
import { Box, useColorModeValue } from '@chakra-ui/react';

// Layout components
import { SidebarContext } from 'contexts/SidebarContext';
import { AuthenticationContext } from 'providers/AuthProvider';
import { ADMIN, USER } from 'shared/consts';

// Custom Chakra theme
export default function Auth() {
  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { userData } = useContext(AuthenticationContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.user.role.name === ADMIN) {
      navigate('/admin');
    } else if (userData?.user.role.name === USER) {
      navigate('/admin/calendar');
    }
  }, [userData, navigate]);

  const authBg = useColorModeValue('white', 'navy.900');
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Box
          bg={authBg}
          float="right"
          minHeight="100vh"
          height="100%"
          position="relative"
          w="100%"
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Outlet />
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
