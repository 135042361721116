import { useQuery } from 'react-query';
import { getBusinessCustomers } from 'services/api/customers';
import { serializeCustomers } from 'shared/helpersFunctions';
import { BusinessEntityType, CustomerEntitySelect } from 'shared/types/app.types';

export const useBusinessCustomers = ({ businessData }: { businessData: BusinessEntityType }) => {
  const {
    data: customers,
    isLoading: isLoadingCustomers,
    isSuccess: isSuccessCustomers,
  } = useQuery<CustomerEntitySelect[]>(['customers'], () => getBusinessCustomers(businessData.id), {
    select: (customers) => {
      return customers ? serializeCustomers(customers) : [];
    },
    refetchOnMount: false,
  });

  return { customers: customers ?? [], isLoadingCustomers, isSuccessCustomers };
};
