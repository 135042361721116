import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { buildChakraColorFromString, setZoomHeight } from 'shared/helpersFunctions';

export const CalendarContainer = styled.div(
  (props: any) => css`
    .fc-timegrid-slots table tbody tr {
      /* Your styles here */
      height: ${setZoomHeight(props.zoomValue)};
    }
    .fc-button-group {
      display: contents;
    }
    .fc-col-header-cell {
      background-color: ${buildChakraColorFromString(props.bgColor)};
    }
    .fc-timegrid-axis {
      background-color: ${buildChakraColorFromString(props.bgColor)};
    }
    .fc-list-day-cushion {
      background-color: ${buildChakraColorFromString(props.bgColor)};
    }
    .fc-event-main {
      padding: 0;
    }
    .fc-direction-rtl .fc-timegrid-col-events {
      margin: 0;
    }
    .fc-dayGridMonth-button {
      background-color: ${buildChakraColorFromString(props.bgColor)};
      border-radius: 10px;
    }
    .fc-non-business {
      background-color: #00000073;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .fc-daygrid-day-top {
      justify-content: center;
      color: ${buildChakraColorFromString(props.dateTextColor)};
    }
    .fc-theme-standard td,
    .fc-theme-standard th {
      border: 1px solid #8776be;
    }
    .fc-timeGridWeek-button {
      background-color: ${buildChakraColorFromString(props.bgColor)};
      border-radius: 10px;
    }
    .fc-toolbar-title {
      background-color: ${buildChakraColorFromString(props.bgColor)};
      border-radius: 6px;
      border: 1px solid black;
      padding: 1px 10px;
    }
    .fc-media-screen {
      color: white;
    }
    .fc-header-toolbar {
      display: flex;
      flex-direction: row; /* Default for larger screens */
      gap: 10px;

      @media (max-width: 768px) {
        position: fixed; /* Make the toolbar sticky */
        top: 68px; /* Sticks to the top of the viewport */
        z-index: 1000; /* Ensure it stays above other content */
        border-radius: 0px 0px 5px 5px;
        background-color: ${props.calendarNavigationBgColor}; /* Semi-transparent background */
        backdrop-filter: blur(1px); /* Apply blur effect */
        padding: 0px 0; /* Padding around the toolbar */
        height: 120px; /* Ensure the container size adjusts */
        width: 100%;
      }
    }
    .fc-toolbar-chunk:nth-child(1) {
      display: flex;
      justify-content: flex-end; /* Align to the right */
      align-items: center;

      @media (max-width: 768px) {
        position: absolute;
        top: 10px; /* Stick to the top */
        right: 10px; /* Align to the right */
      }
    }
    .fc-toolbar-chunk:nth-child(2) {
      display: flex;
      justify-content: flex-start; /* Align to the left */
      align-items: center;

      @media (max-width: 768px) {
        position: absolute;
        top: 10px; /* Stick to the top */
        left: 10px; /* Align to the left */
      }
    }
    .fc-toolbar-chunk:nth-child(3) {
      display: flex;
      justify-content: center; /* Center align */
      align-items: center;

      @media (max-width: 768px) {
        position: absolute;
        font-size: 16px;
        width: 100%;
        top: 70px; /* Position below the first two */
        left: 50%; /* Center horizontally */
        transform: translateX(-50%); /* Center the content */
      }
    }
    .fc-timegrid-event-harness-inset .fc-timegrid-event,
    .fc-timegrid-event.fc-event-mirror,
    .fc-timegrid-more-link {
      box-shadow: 0px 0px 0 2px #318abb5c;
    }
    .fc-timegrid-event-harness-inset .fc-timegrid-event {
      box-shadow: 0px;
    }
    .fc-button-primary:not(:disabled).fc-button-active {
      background-color: ${props.buttonActiveBgColor};
    }
    .fc a[data-navlink]:hover {
      font-weight: 300;
      color: #101c44;
    }
    .fc-timeGridDay-button {
      background-color: ${buildChakraColorFromString(props.bgColor)};
      border-radius: 10px;
    }
    .fc-listMonth-button {
      background-color: ${buildChakraColorFromString(props.bgColor)};
      border-radius: 10px;
    }
    .fc-popover {
      background-color: ${buildChakraColorFromString(props.bgColor)};
    }
    .fc-prev-button {
      background-color: ${buildChakraColorFromString(props.bgColor)};
      border-radius: 10px;
    }
    .fc-next-button {
      background-color: ${buildChakraColorFromString(props.bgColor)};
      border-radius: 10px;
    }
    .fc-today-button {
      background-color: ${buildChakraColorFromString(props.bgColor)};
      border-radius: 10px;
    }
    .fc-button-group {
      gap: 4px;
    }

    .fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.full-hour-label {
      font-size: 16px;
      font-weight: 900;
    }

    .fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.minute-only-label {
      font-size: 12px;
    }
    .fc-direction-rtl .fc-timegrid-slot-label-frame {
      text-align: center;
    }
  `,
);
