// Chakra imports
import { Box, Button, Flex, Icon, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/cards/Card';
import { IoMdTrash } from 'react-icons/io';
import { MdCheck, MdClose, MdEdit, MdEmail, MdOutlineAdminPanelSettings, MdPhone } from 'react-icons/md';

// Assets
import IconBox from 'components/icons/IconBox';
import { useTranslation } from 'react-i18next';
import { BsPerson } from 'react-icons/bs';
import { UserType } from 'shared/types/app.types';
import MiniCard from './MiniCard';

interface UserCardProps {
  image: string;
  userFullName: string;
  user: UserType;
  handleEditUser: (user: UserType) => void;
  handleDeleteUser: (user: UserType) => void;
}

export default function UserCard({ image, userFullName, user, handleEditUser, handleDeleteUser }: UserCardProps) {
  const editBtnColor = useColorModeValue('brandScheme.100', 'brand.400');
  const { t } = useTranslation();
  const brandColor = useColorModeValue('brand.500', 'brand.100');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  return (
    <Card
      p="18px"
      maxW="sm"
      className="shadow-2xl"
      // border={`2px solid ${stringToColor(employeeFullName)}`}
    >
      <Flex direction={{ base: 'column' }} justify="center" h="100%">
        <Box mb={{ base: '20px', '2xl': '20px' }} w={{ base: '100%', '3xl': '100%' }} h={{ base: '50px', '3xl': '50px' }} position="relative">
          {/* <Image
            src={image ?? defaultImage}
            w={{ base: "100%", "3xl": "100%" }}
            h={{ base: "100%", "3xl": "100%" }}
            objectFit="cover"
            borderRadius="20px"
          /> */}
          <Button
            position="absolute"
            bg={editBtnColor}
            _hover={{ bg: 'brand.600' }}
            _active={{ bg: 'brand' }}
            _focus={{ bg: 'brand' }}
            p="0px !important"
            top="14px"
            right="14px"
            borderRadius="50%"
            minW="45px"
            h="45px"
            onClick={() => handleEditUser(user)}
          >
            <Icon transition="0.2s linear" w="20px" h="20px" as={MdEdit} />
          </Button>
          <Button
            position="absolute"
            color="red.500"
            _hover={{ bg: 'red.100' }}
            _active={{ bg: 'brand' }}
            _focus={{ bg: 'brand' }}
            backdropFilter={'blur(20px)'}
            p="0px !important"
            border={'2px solid'}
            top="14px"
            left="14px"
            borderRadius="50%"
            minW="45px"
            h="45px"
            onClick={() => handleDeleteUser(user)}
          >
            <Icon transition="0.2s linear" w="20px" h="20px" as={IoMdTrash} />
          </Button>
        </Box>

        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex direction="column">
            <MiniCard
              startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={BsPerson} color={brandColor} />} />}
              name={t('Full Name')}
              value={userFullName}
            />
            <MiniCard
              startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={MdPhone} color={brandColor} />} />}
              name={t('Phone')}
              value={user.phone}
            />

            <MiniCard
              startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={MdEmail} color={brandColor} />} />}
              name={t('Email')}
              value={user.email}
            />
            {/* <MiniCard
              startContent={
                <IconBox
                  w="36px"
                  h="36px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="22px"
                      h="22px"
                      as={FaConnectdevelop}
                      color={brandColor}
                    />
                  }
                />
              }
              name={t("Logged In Using")}
              value={capitalize(user.provider)}
            /> */}
            <MiniCard
              startContent={
                <IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={MdOutlineAdminPanelSettings} color={brandColor} />} />
              }
              name={t('Role')}
              value={user.role.name}
            />

            <MiniCard
              startContent={
                <IconBox
                  w="36px"
                  h="36px"
                  bg={user.status.name === 'Active' ? 'green' : 'red'}
                  icon={<Icon w="22px" h="22px" as={user.status.name === 'Active' ? MdCheck : MdClose} color={'white'} />}
                />
              }
              name={t('Status')}
              value={t(user.status.name)}
            />
            {/* <Divider width={"100%"} /> */}
            {/* {treatments.length > 0 ? (
                <Text
                  color="secondaryGray.600"
                  fontSize={{
                    base: "sm",
                  }}
                  fontWeight="400"
                >
                  {t("Treatments")}
                </Text>
              ) : (
                <Text
                  fontSize={{
                    base: "sm",
                  }}
                  fontWeight="800"
                >
                  {t("Have no treatment")}
                </Text>
              )} */}
          </Flex>
          {/* {treatments && (
              <Tooltip
                hasArrow
                placement="top-start"
                label={treatments.map((treat) => {
                  return <Text fontWeight="500">{treat.name}</Text>;
                })}
                size="sm"
              >
                <AvatarGroup
                  max={3}
                  w="40%"
                  color={textColorBid}
                  spacing="-1"
                  size="sm"
                  mt={{
                    base: "0px",
                    md: "10px",
                    lg: "0px",
                    xl: "10px",
                    "2xl": "0px",
                  }}
                  fontSize="12px"
                >
                  {treatments.map((treat) => (
                    <Avatar key={treat.id} name={`${treat.name}`} />
                  ))}
                </AvatarGroup>
              </Tooltip>
            )} */}
        </Flex>
      </Flex>
    </Card>
  );
}
