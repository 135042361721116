import { useContext, useState } from 'react';
// Chakra imports
import { Box, Flex, Heading, useColorModeValue } from '@chakra-ui/react';
// Custom components
// Assets
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import Card from 'components/cards/Card';
import CreateBusinessStep1Form from 'components/forms/createBusiness/CreateBusinessStep1Form';
import CreateBusinessStep2Form from 'components/forms/createBusiness/CreateBusinessStep2Form';
import CreateBusinessStep3Form from 'components/forms/createBusiness/CreateBusinessStep3Form';
import CreateBusinessStep4Form from 'components/forms/createBusiness/CreateBusinessStep4Form';
import CreateBusinessStep5Form from 'components/forms/createBusiness/CreateBusinessStep5Form';
import CreateBusinessStep6Form from 'components/forms/createBusiness/CreateBusinessStep6Form';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { BusinessTypeEnum } from 'shared/enums';
import {
  BusinessDaysOfWeekType,
  BusinessEntityType,
  CreateBusinessStep1,
  CreateBusinessStep2,
  CreateBusinessStep3,
  CreateBusinessStep4,
  CreateBusinessStep5,
  CreateBusinessStep6,
  DaysOfBusinessType,
} from 'shared/types/app.types';

export type CreateBusinessForm = {
  name: string;
  phone: string;
  address: string;
  color: string;
  businessType: BusinessTypeEnum;
  daysOfBusiness: BusinessDaysOfWeekType;
};

interface ICreateBusinessProps {
  business?: BusinessEntityType;
  businessHours?: DaysOfBusinessType[];
  closeModal?: () => void;
}

function UpdateOrCreateBusiness({ business, closeModal, businessHours }: ICreateBusinessProps) {
  const textColor = useColorModeValue('navy.700', 'white');
  const { t } = useTranslation();
  const stepsColor = useColorModeValue('brand.500', 'brand.400');

  const [step1, setStep1] = useState<CreateBusinessStep1>({ ...business });
  const [step2, setStep2] = useState<CreateBusinessStep2>({ ...business });
  const [step3, setStep3] = useState<CreateBusinessStep3>({ ...business });
  const [step4, setStep4] = useState<CreateBusinessStep4>({ ...business });
  const [step5, setStep5] = useState<CreateBusinessStep5>({
    daysOfBusiness: businessHours ?? [],
  });
  const { createBusinessMutation, updateBusinessMutation, isSuccessUpdateBusiness } = useContext(AuthenticationContext);

  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const nextStep1 = (values: CreateBusinessStep1) => {
    setStep1(values);
    nextStep();
  };

  const nextStep2 = (values: CreateBusinessStep2) => {
    setStep2(values);
    nextStep();
  };

  const nextStep3 = (values: CreateBusinessStep3) => {
    setStep3(values);
    nextStep();
  };

  const nextStep4 = (values: CreateBusinessStep4) => {
    setStep4(values);
    nextStep();
  };

  const nextStep5 = (values: CreateBusinessStep5) => {
    setStep5(values);
    nextStep();
  };

  const nextStep6 = (values: CreateBusinessStep6) => {
    if (business && Object.keys(business).length > 0) {
      updateBusinessMutation({
        updatedBusinessDataFromForm: {
          ...step1,
          ...step2,
          ...step3,
          ...step4,
          ...step5,
          ...values,
        },
        businessId: business.id,
      });
      if (isSuccessUpdateBusiness) {
        nextStep();
      }
      setTimeout(() => closeModal(), 1000);
    } else {
      createBusinessMutation({
        businessDataFromForm: {
          ...step1,
          ...step2,
          ...step3,
          ...step4,
          ...step5,
          ...values,
        },
        nextStep,
      });
    }
  };

  const onStepClick = (step: number) => {
    if (business) {
      if (step > activeStep) {
        // Move forward to the clicked step by calling nextStep multiple times
        for (let i = activeStep; i < step; i++) {
          nextStep();
        }
      } else if (step < activeStep) {
        // Move backward to the clicked step by calling prevStep multiple times
        for (let i = activeStep; i > step; i--) {
          prevStep();
        }
      }
    }
  };

  const hasCompletedAllSteps = activeStep === 6;

  return (
    <Card p="20px" maxW="md">
      <Flex alignItems="center" justifyContent="center" flexDirection="column" mt={2}>
        <Box>
          <Heading color={textColor} fontSize="36px" mb="20px">
            {business ? t('Update') : t('Create')} {t('Your Business')}
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <Steps
            variant="circles"
            colorScheme="steps"
            orientation="vertical"
            sx={{
              '& .cui-steps__vertical-step:not(:last-child):after': {
                bg: stepsColor,
              },
            }}
            onClickStep={onStepClick}
            activeStep={activeStep}
          >
            <Step label={t('Basic Info')} key={1}>
              <CreateBusinessStep1Form nextStep1={nextStep1} step1={step1} />
            </Step>
            <Step label={t('Social')} key={2}>
              <CreateBusinessStep2Form
                nextStep2={nextStep2}
                prevStep={prevStep} // Use prevStep here
                step2={step2}
              />
            </Step>
            <Step label={t('Address')} key={3}>
              <CreateBusinessStep3Form nextStep3={nextStep3} prevStep={prevStep} step3={step3} />
            </Step>
            <Step label={t('Color & Type')} key={4}>
              <CreateBusinessStep4Form nextStep4={nextStep4} prevStep={prevStep} step4={step4} />
            </Step>
            <Step label={t('Business Operating Hours')} key={5}>
              <CreateBusinessStep5Form nextStep5={nextStep5} prevStep={prevStep} businessHours={businessHours} />
            </Step>
            <Step label={t('Settings')} key={6}>
              <CreateBusinessStep6Form
                nextStep6={nextStep6}
                prevStep={prevStep}
                business={business}
                isUpdate={business && Object.keys(business).length > 0}
              />
            </Step>
            {hasCompletedAllSteps && (
              <Box sx={{ mb: 2, rounded: 'md' }}>
                <Heading fontSize="xl" textAlign="center">
                  {business ? t('Updating your business') : t('Creating Your Business!')}
                  🎉
                </Heading>
              </Box>
            )}
          </Steps>
        </Flex>
      </Flex>
    </Card>
  );
}

export default UpdateOrCreateBusiness;
