import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getEmployees } from 'services/api/employee';
import { ACTIVE } from 'shared/consts';
import { serializeEmployees } from 'shared/helpersFunctions';
import { EmployeeSelectType } from 'shared/types/app.types';

export const useEmployees = () => {
  const { userData } = useContext(AuthenticationContext);
  const { data: employees, isLoading: isLoadingEmployees } = useQuery<EmployeeSelectType[]>(['employees'], () => getEmployees(userData.business.id), {
    select: (employees) => {
      return employees ? serializeEmployees(employees) : [];
    },
    enabled: userData.user.status.name === ACTIVE,
    refetchOnMount: false,
  });

  return { employees: employees ?? [], isLoadingEmployees };
};
