import { Icon, useToast } from '@chakra-ui/react';
import { createContext, ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCross } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { forgotPassword, login, registerUser, resetPassword } from 'services/api/auth';
import { createBusiness, updateBusiness } from 'services/api/business';
import { setBusinessPlan } from 'services/api/plans';
import axiosConfig from 'shared/axiosConfig';
import { BUSINESS_DATA_KEY, USER_DATA_KEY } from 'shared/consts';
import {
  AuthForgotPasswordDto,
  AuthRegisterLoginDto,
  AuthResetPasswordDto,
  BusinessEntityType,
  CreateBusinessDTO,
  LoginResponseType,
  UpdateBusinessDTO,
} from 'shared/types/app.types';

interface IAuthenticationContext {
  userData?: LoginResponseType;
  isLoginError: boolean;
  isErrorCreateBusiness: boolean;
  logout: () => void;
  toggleReachedLimitPlanModal: () => void;
  resetPassMutation: (resetPass: AuthResetPasswordDto) => void;
  setBusinessPlanMutation: (data: { planId: number; businessId: string }) => void;
  forgotPasswordMutation: (payload: AuthForgotPasswordDto) => void;
  isErrorForgotPassword: boolean;
  isSuccessUpdateBusiness: boolean;
  isErrorSetBusinessPlan: boolean;
  isLoadingBusinessPlan: boolean;
  isLoadingForgotPassword: boolean;
  isLoadingCreateBusiness: boolean;
  createBusinessMutation: ({ businessDataFromForm, nextStep }: { businessDataFromForm: CreateBusinessDTO; nextStep: () => void }) => void;
  isErrorResetPass: boolean;
  isLoadingResetPass: boolean;
  isRegistrationError: boolean;
  loginMutation: ({ email, password }: { email: string; password: string }) => void;
  updateBusinessMutation: ({
    updatedBusinessDataFromForm,
    businessId,
  }: {
    updatedBusinessDataFromForm: UpdateBusinessDTO;
    businessId: string;
  }) => void;
  isLoadingUpdateBusiness: boolean;
  reachedPlanModal: boolean;
  registerUserMutation: (user: AuthRegisterLoginDto) => void;
  setUser: (userData: any) => void;
  setBusiness: (businessData: any) => void;
}

const AuthenticationContext = createContext<IAuthenticationContext>({
  updateBusinessMutation: () => null,
  setBusinessPlanMutation: () => null,
  toggleReachedLimitPlanModal: () => null,
  isLoadingUpdateBusiness: false,
  isLoadingBusinessPlan: false,
  isErrorSetBusinessPlan: false,
  reachedPlanModal: false,
  isErrorCreateBusiness: false,
  forgotPasswordMutation: () => null,
  isErrorForgotPassword: false,
  isLoadingForgotPassword: false,
  isSuccessUpdateBusiness: false,
  userData: null,
  isLoginError: false,
  logout: () => '',
  loginMutation: () => null,
  registerUserMutation: () => null,
  resetPassMutation: () => null,
  createBusinessMutation: () => null,
  isErrorResetPass: false,
  isLoadingCreateBusiness: false,
  isRegistrationError: false,
  isLoadingResetPass: false,
  setUser: (userData: any) => null,
  setBusiness: (businessData: any) => null,
});

function AuthenticationProvider({ children }: { children: ReactElement }) {
  const setInitDataStorage = (key: string) => {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    }
    return null;
  };
  const { t } = useTranslation();
  const [userData, setUserData] = useState<LoginResponseType | null>(setInitDataStorage(USER_DATA_KEY));
  const [reachedPlanModal, setReachedPlanModal] = useState(false);
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const { mutate: loginMutation, isError: isLoginError } = useMutation(login, {
    onSuccess: (response: LoginResponseType) => {
      localStorage.setItem(USER_DATA_KEY, JSON.stringify(response));
      if (response.business) {
        localStorage.setItem(BUSINESS_DATA_KEY, JSON.stringify(response.business));
      }
      setUserData(response);
      axiosConfig.defaults.headers.common['Authorization'] = 'Bearer ' + response.token;
      toast({
        title: t('Login Successfully'),
        status: 'success',
        description: `${response.user.firstName} ${t('Welcome to EasyBook')}`,
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('Error logging in'),
        status: 'error',
        description: t('oops error logging in'),
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    },
  });

  const {
    mutate: setBusinessPlanMutation,
    isLoading: isLoadingBusinessPlan,
    isError: isErrorSetBusinessPlan,
  } = useMutation(setBusinessPlan, {
    onSuccess: async (response) => {
      localStorage.removeItem(USER_DATA_KEY);
      localStorage.removeItem(BUSINESS_DATA_KEY);
      setUserData(null);
      toast({
        title: t('You plan was set successfully, congratulations!'),
        description: t('You business plan was set successfully you can now log in and enjoy (EasyBook)'),
        status: 'success',
        duration: 10000,
        isClosable: false,
      });
      navigate('auth/sign-in');
    },
    onError: () => {
      toast({
        title: t('Error creating plan for your business'),
        description: t('We encountered an error creating plan for your business, try again later'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const toggleReachedLimitPlanModal = () => {
    setReachedPlanModal(!reachedPlanModal);
  };

  const {
    mutate: createBusinessMutation,
    isLoading: isLoadingCreateBusiness,
    isError: isErrorCreateBusiness,
  } = useMutation(createBusiness, {
    onMutate: (dataPack) => {
      return dataPack;
    },
    onSuccess: (response, dataPack) => {
      toast({
        title: `${t('Business')} ${dataPack.businessDataFromForm.name} ${t('Created Successfully')}`,
        description: t('Now choose a plan for your business'),
        status: 'success',
        duration: 8000,
        isClosable: true,
      });
      const newUserData = {
        ...userData,
        business: response as unknown as BusinessEntityType,
      };
      localStorage.setItem(USER_DATA_KEY, JSON.stringify(newUserData));
      setUserData(newUserData);
      setTimeout(() => {
        navigate('/business/plan');
      }, 1500);
    },
    onError: (response, dataPack) => {
      toast({
        title: t('Failed Creating Business'),
        description: t('Failed to create business it is possible that your credentials is already taken like Phone or Email'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const {
    mutate: updateBusinessMutation,
    isLoading: isLoadingUpdateBusiness,
    isSuccess: isSuccessUpdateBusiness,
  } = useMutation(updateBusiness, {
    onSuccess: async (response, dataPack) => {
      toast({
        title: t('Business Updated Successfully'),
        description: t('successfully updated business'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      await queryClient.invalidateQueries('businessHours');
      const newUserData = {
        ...userData,
        business: {
          ...userData.business,
          ...dataPack.updatedBusinessDataFromForm,
        },
      };
      setUserData(newUserData);
      localStorage.setItem(USER_DATA_KEY, JSON.stringify(newUserData));
    },
    onError: () => {
      toast({
        title: t('Oops there was an error updating business'),
        description: t('please try again later'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const {
    mutate: resetPassMutation,
    isError: isErrorResetPass,
    isLoading: isLoadingResetPass,
  } = useMutation(resetPassword, {
    onSuccess: () => {
      toast({
        title: t('Your password has been reset'),
        description: t('Your password has been reset'),
        status: 'success',
        duration: 3000,
        position: 'bottom-right',
        isClosable: false,
      });
      setTimeout(() => {
        navigate('auth/sign-in');
      }, 1000);
    },
    onError: () => {
      toast({
        title: t('Oops! Something went wrong'),
        status: 'error',
        render: () => <Icon as={FaCross} width="20px" height="20px" color="inherit" />,
        duration: 3000,
        position: 'bottom-right',
        isClosable: false,
      });
    },
  });
  const {
    mutate: forgotPasswordMutation,
    isError: isErrorForgotPassword,
    isLoading: isLoadingForgotPassword,
  } = useMutation(forgotPassword, {
    onSuccess: () => {
      toast({
        title: t('Email Sent Successfully'),
        description: t('The email was sent successfully, check out your mailbox to reset your password'),
        status: 'success',
        duration: 5000,
        position: 'bottom-right',
        isClosable: false,
      });
      setTimeout(() => {
        navigate('auth/sign-in');
      }, 1000);
    },
    onError: () => {
      toast({
        title: t('Oops! Something went wrong'),
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        isClosable: false,
      });
    },
  });

  const { mutate: registerUserMutation, isError: isRegistrationError } = useMutation(registerUser, {
    onSuccess: (data, dataSent) => {
      navigate('/auth/sign-in');
      toast({
        title: `${t('User')} ${dataSent.firstName} ${dataSent.lastName} ${t('Registered Successfully')}`,
        description: t('you successfully created a new user!'),
        status: 'success',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('Registration Failure'),
        description: t('failed to signing up'),
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      });
    },
  });

  const logout = async () => {
    localStorage.removeItem(USER_DATA_KEY);
    localStorage.removeItem(BUSINESS_DATA_KEY);
    setUserData(null);
    await queryClient.clear();
  };

  const setUser = async (newUserData: any) => {
    const oldUserData = JSON.parse(localStorage.getItem(USER_DATA_KEY));
    const updatedUserData = {
      ...oldUserData,
      user: {
        ...oldUserData.user,
        ...newUserData,
      },
    };
    setUserData(updatedUserData);
    localStorage.setItem(USER_DATA_KEY, JSON.stringify(updatedUserData));
  };

  const setBusiness = async (newBusinessData: any) => {
    const oldUserData = JSON.parse(localStorage.getItem(USER_DATA_KEY));
    const updatedUserData = {
      ...oldUserData,
      business: {
        ...oldUserData.business,
        ...newBusinessData,
      },
      user: {
        ...oldUserData.user,
        ...newBusinessData,
      },
    };
    setUserData(updatedUserData);
    localStorage.setItem(USER_DATA_KEY, JSON.stringify(updatedUserData));
  };

  useEffect(() => {
    const userData: LoginResponseType = JSON.parse(localStorage.getItem(USER_DATA_KEY));
    if (userData) {
      const currentTime = new Date().getTime();
      const userTokenExpiry = userData.tokenExpires;
      if (userTokenExpiry <= currentTime) {
        setUserData(null); // logging out user when token expires
      } else {
        setUserData(userData);
      }
    }
  }, []);

  return (
    <AuthenticationContext.Provider
      value={useMemo(
        () => ({
          userData,
          isLoginError,
          registerUserMutation,
          toggleReachedLimitPlanModal,
          logout,
          loginMutation,
          isErrorSetBusinessPlan,
          isLoadingBusinessPlan,
          reachedPlanModal,
          setBusinessPlanMutation,
          forgotPasswordMutation,
          setBusiness,
          isErrorForgotPassword,
          isLoadingForgotPassword,
          resetPassMutation,
          isErrorResetPass,
          isRegistrationError,
          isLoadingResetPass,
          isSuccessUpdateBusiness,
          createBusinessMutation,
          setUser,
          isLoadingCreateBusiness,
          isErrorCreateBusiness,
          updateBusinessMutation,
          isLoadingUpdateBusiness,
        }),
        [
          userData,
          isLoadingCreateBusiness,
          isErrorSetBusinessPlan,
          isLoadingBusinessPlan,
          reachedPlanModal,
          isErrorForgotPassword,
          isLoadingForgotPassword,
          isErrorCreateBusiness,
          isLoginError,
          isRegistrationError,
          isSuccessUpdateBusiness,
          isErrorResetPass,
          isLoadingResetPass,
          isLoadingUpdateBusiness,
          toggleReachedLimitPlanModal,
          setBusinessPlanMutation,
          setBusiness,
          updateBusinessMutation,
          createBusinessMutation,
          forgotPasswordMutation,
          resetPassMutation,
          setUser,
        ],
      )}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export { AuthenticationContext, AuthenticationProvider };
