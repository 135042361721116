// Daily Traffic Dashboards Default

export const barChartDataDailyTraffic = [
  {
    name: 'Daily Traffic',
    data: [20, 30, 40, 20, 45, 50, 30],
  },
];

export const barChartOptionsDailyTraffic: any = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: '12px',
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: '12px',
        fontFamily: undefined,
      },
    },
    theme: 'dark',
  },
  xaxis: {
    categories: ['00', '04', '08', '12', '14', '16', '18'],
    show: false,
    labels: {
      show: true,
      style: {
        colors: '#A3AED0',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    color: 'black',
    labels: {
      show: true,
      style: {
        colors: '#CBD5E0',
        fontSize: '14px',
      },
    },
  },
  grid: {
    show: false,
    strokeDashArray: 5,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      type: 'vertical',
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      colorStops: [
        [
          {
            offset: 0,
            color: '#4318FF',
            opacity: 1,
          },
          {
            offset: 100,
            color: 'rgba(67, 24, 255, 1)',
            opacity: 0.28,
          },
        ],
      ],
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: '40px',
    },
  },
};

export const barChartDataUserActivity = [
  {
    name: 'PRODUCT A',
    data: [70, 55, 41, 67, 22, 43],
  },
  {
    name: 'PRODUCT B',
    data: [90, 70, 60, 50, 80, 90],
  },
];

// User Activity

export const barChartOptionsUserActivity = {
  chart: {
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: '12px',
    },
    onDatasetHover: {
      style: {
        fontSize: '12px',
      },
    },
    theme: 'dark',
  },
  xaxis: {
    categories: ['S', 'M', 'T', 'W', 'T', 'F'],
    show: false,
    labels: {
      show: true,
      style: {
        colors: '#A3AED0',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    color: 'black',
    labels: {
      show: true,
      style: {
        colors: '#A3AED0',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
  },

  grid: {
    borderColor: 'rgba(163, 174, 208, 0.3)',
    show: true,
    yaxis: {
      lines: {
        show: true,
        opacity: 0.5,
      },
    },
    row: {
      opacity: 0.5,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: 'solid',
    colors: ['#5E37FF', '#6AD2FF'],
  },
  legend: {
    show: false,
  },
  colors: ['#5E37FF', '#6AD2FF'],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: '30px',
    },
  },
};
// Consumption Users Reports

export const barChartDataConsumption: any = [
  {
    name: 'PRODUCT A',
    data: [200, 370, 300, 390, 320, 350, 360, 320, 380],
  },
  {
    name: 'PRODUCT B',
    data: [50, 370, 330, 390, 320, 350, 360, 320, 380],
  },
  {
    name: 'PRODUCT C',
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
  },
  {
    name: 'PRODUCT D',
    data: [0, 0, 330, 390, 0, 350, 0, 320, 380],
  },
  {
    name: 'PRODUCT E',
    data: [300, 0, 0, 390, 0, 350, 0, 255, 100],
  },
  {
    name: 'PRODUCT S',
    data: [400, 0, 0, 620, 0, 43, 0, 320, 100],
  },
  {
    name: 'PRODUCT Q',
    data: [440, 0, 0, 390, 0, 350, 0, 320, 200],
  },
  {
    name: 'PRODUCT Z',
    data: [300, 220, 0, 390, 0, 25, 0, 100, 100],
  },
  {
    name: 'PRODUCT H',
    data: [0, 20, 100, 390, 0, 350, 0, 20, 100],
  },
];

export const lineOptions = (isDarkMode: boolean) => {
  const textColor = isDarkMode ? '#ffffff' : '#000000';
  return {
    options: {
      chart: {
        height: 350,
        width: 600,
        type: 'line',
      },
      plotOptions: {
        line: {
          isSlopeChart: true,
        },
      },
      tooltip: {
        followCursor: true,
        intersect: false,
        shared: true,
        theme: isDarkMode ? 'dark' : 'light', // Set the theme based on dark mode
        style: {
          backgroundColor: isDarkMode ? '#333' : '#fff', // Custom background color
        },
      },
      dataLabels: {
        background: {
          enabled: true,
        },
        formatter(val: any, opts: any) {
          const seriesName = opts.w.config.series[opts.seriesIndex].name;
          return val !== null ? seriesName : '';
        },
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          style: {
            colors: textColor,
          },
        },
      },
      xaxis: {
        position: 'bottom',
        labels: {
          style: {
            colors: textColor,
          },
        },
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'left',
      },
      stroke: {
        // width: [2, 3, 4, 2],
        // dashArray: [0, 0, 5, 2],
        curve: 'smooth',
      },
    },
  };
};

export const barSeries = (treatmentsData: any[]) => {
  // Transform treatmentsData into the desired series format
  const series = [
    {
      name: 'Sales',
      data: [0, 0, 0, 0, 0, 0],
    },
  ];
  treatmentsData.map((treatment) => {
    treatment.data.map((value: number, index: number) => {
      series[0].data[index] += value;
    });
  });

  return { series };
};
export const lineSeries = (months: string[], treatmentsData: any[]) => {
  // Transform treatmentsData into the desired series format
  const series = treatmentsData.map((treatment) => ({
    name: treatment.name,
    data: treatment.data.map((value: number, index: number) => ({
      x: months[index],
      y: value,
    })),
  }));

  return { series };
};

export const barChartOptionsConsumption: any = (categories: string[], treatmentsRevenue: any[]) => {
  return {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
        // fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: '12px',
          // fontFamily: undefined,
        },
      },
      theme: 'dark',
    },
    xaxis: {
      categories: categories,
      show: true,
      labels: {
        show: true,
        style: {
          colors: '#A3AED0',
          fontSize: '14px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: 'black',
      labels: {
        show: false,
        style: {
          colors: '#A3AED0',
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },

    grid: {
      borderColor: 'rgba(163, 174, 208, 0.3)',
      show: true,
      yaxis: {
        lines: {
          show: false,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: 'solid',
      // colors: treatmentsRevenue.map((treat) => stringToColor(treat.name, true)),
    },
    legend: {
      show: false,
    },
    // colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: '20px',
      },
    },
  };
};

export const pieChartOptions: any = (treatmentsNames: string[], grey = false) => {
  // const treatmentsColors = treatmentsNames.map((treat) => stringToColor(treat, true))
  const hoverColors = ['#ff7256', '#56ff72', '#5660ff', '#2d2eb2', '#ffcb56', '#56ffff', '#ff568f', '#963eff', '#ff5678', '#56ffb9'];
  const fillColors = ['#cc4529', '#29cc45', '#2933cc', '#000285', '#cc9e29', '#29cccc', '#cc2972', '#6929cc', '#cc294b', '#29cc8c'];
  const hoverColorsGreyish = [
    '#b47868', // Greyish shade of "#ff7256"
    '#78b487', // Greyish shade of "#56ff72"
    '#787eb4', // Greyish shade of "#5660ff"
    '#4c4d99', // Greyish shade of "#2d2eb2"
    '#b49f78', // Greyish shade of "#ffcb56"
    '#78b4b4', // Greyish shade of "#56ffff"
    '#b4788e', // Greyish shade of "#ff568f"
    '#815ab4', // Greyish shade of "#963eff"
    '#b47881', // Greyish shade of "#ff5678"
    '#78b49f', // Greyish shade of "#56ffb9"
  ];

  const fillColorsGreyishDark = [
    '#99523c', // Greyish shade of "#cc4529"
    '#4a9952', // Greyish shade of "#29cc45"
    '#4a4f99', // Greyish shade of "#2933cc"
    '#3b3b6a', // Greyish shade of "#000285"
    '#99844a', // Greyish shade of "#cc9e29"
    '#4a9999', // Greyish shade of "#29cccc"
    '#994a64', // Greyish shade of "#cc2972"
    '#5b3b99', // Greyish shade of "#6929cc"
    '#994a5d', // Greyish shade of "#cc294b"
    '#4a9975', // Greyish shade of "#29cc8c"
  ];

  return {
    labels: treatmentsNames,
    colors: grey ? hoverColorsGreyish : hoverColors,
    chart: {
      width: '200px',
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: (val: any, opts: any) => {
        return val.toFixed(1) + '%';
      },
      style: {
        colors: ['#FFFFFF'],
        fontSize: '14px',
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: '#000',
        opacity: 0.45,
      },
    },
    hover: {
      // mode: null
    },
    plotOptions: {
      donut: {
        expandOnClick: true,
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    fill: {
      colors: grey ? fillColorsGreyishDark : fillColors,
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
    },
  };
};

// Total Spent Default

export const lineChartDataTotalSpent = (data: number[]) => [
  //   {
  //     name: "Revenue",
  //     data: [50, 64, 48, 66, 49, 68],
  //   },
  {
    name: 'Appointments',
    data: data,
  },
];

export const lineChartOptionsTotalSpent: any = (isDarkMode: boolean, months: string[]) => {
  return {
    chart: {
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 10,
        left: 0,
        blur: 15,
        opacity: 0.5,
        color: isDarkMode ? 'white' : '#7551FF',
      },
    },
    colors: ['#39B8FF', '#4318FF'],
    markers: {
      size: 0,
      colors: 'white',
      strokeColors: '#7551FF',
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      // discrete: [],
      shape: 'circle',
      radius: 4,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      theme: 'dark',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      type: 'line',
    },
    xaxis: {
      type: 'numeric',
      categories: months,
      labels: {
        style: {
          colors: '#A3AED0',
          fontSize: '12px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      column: {
        color: ['#4318FF', '#39B8FF'],
        opacity: 0.5,
      },
    },
    color: [
      '#4318FF',
      //    "#39B8FF"
    ],
  };
};
