// Chakra Imports
import { Avatar, Button, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Text, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { capitalize } from 'lodash';
// Custom Components
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import { useContext } from 'react';
// Assets
import { CheckIcon } from '@chakra-ui/icons';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotificationCard from 'components/cards/NotificationCard';
import IconBox from 'components/icons/IconBox';
import mainRoutes from 'navigations';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { MdCheck, MdNotificationsNone } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { I18N_LANG } from 'shared/consts';
import { filterRoutesByPlan, filterRoutesByRole } from 'shared/helpersFunctions';
import { useNotifStatusMutation } from 'shared/hooks/mutations/useNotifStatusMutation';
import { useNotifications } from 'shared/hooks/useNotifications';
import { NotificationItem } from 'shared/types/app.types';

export default function HeaderLinks(props: { secondary: boolean }) {
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  const { logout, userData } = useContext(AuthenticationContext);
  const { updateNotificationsStatusMutation, isLoadingStatusChange } = useNotifStatusMutation();
  const { notifications } = useNotifications({
    businessId: userData.business.id,
  });

  const unreadNotifications = notifications.filter((not) => not.status === 'UNREAD');

  const handleReadAllNotificationsClick = () => {
    if (unreadNotifications.length > 0) {
      updateNotificationsStatusMutation({
        businessId: userData.business.id,
        notificationsIds: {
          ids: unreadNotifications.map((noti) => noti.id),
        },
      });
    }
  };

  const notificationsExists = unreadNotifications.length > 0;
  const navigate = useNavigate();
  if (!userData) {
    navigate('/');
  }
  const { user, business } = userData;
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    localStorage.setItem(I18N_LANG, lng);
    i18n.changeLanguage(lng);
  };

  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.700', 'white');
  const menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('brand.500', 'brand.200');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const settingBg = useColorModeValue('transparent', 'transparent');
  const shadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.18)', '14px 17px 40px 4px rgba(112, 144, 176, 0.06)');
  const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
  const filteredRoutes = filterRoutesByRole(userData.user.role, filterRoutesByPlan(userData.business.plan, mainRoutes));
  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      border={'2px solid'}
      borderColor={borderColor}
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      gap="10px"
      justifyContent={'space-around'}
      borderRadius="30px"
      boxShadow={shadow}
    >
      {/* //TODO future feature to add the search bar */}
      {/* <SearchBar
        mb={() => {
          if (secondary) {
            return { base: "10px", md: "unset" };
          }
          return "unset";
        }}
        me="10px"
        borderRadius="30px"
      /> */}
      {/* <Flex
        bg={ethBg}
        display={secondary ? "flex" : "none"}
        borderRadius="30px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      >
        <Flex
          align="center"
          justify="center"
          bg={ethBox}
          h="29px"
          w="29px"
          borderRadius="30px"
          me="7px"
        >
          <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
        </Flex>
        <Text
          w="max-content"
          color={ethColor}
          fontSize="sm"
          fontWeight="700"
          me="6px"
        >
          1,924
          <Text as="span" display={{ base: "none", md: "unset" }}>
            {" "}
            ETH
          </Text>
        </Text>
      </Flex> */}
      <SidebarResponsive routes={filteredRoutes} />
      <Menu>
        <MenuButton p="0px">
          <Icon as={MdNotificationsNone} color={navbarIcon} w="23px" h="23px" mx="10px" />
          {notificationsExists && (
            <>
              <Flex
                sx={{ position: 'absolute', bottom: 10 }}
                className="animate-ping"
                borderRadius="50%"
                bgColor="red.600"
                w="15px"
                h="15px"
                alignItems="center"
                justifyContent="center"
              ></Flex>
              <Flex
                sx={{ position: 'absolute', bottom: 10 }}
                borderRadius="50%"
                bgColor="red.600"
                w="15px"
                h="15px"
                alignItems="center"
                justifyContent="center"
              ></Flex>
            </>
          )}
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="15px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: '30px', md: 'unset' }}
          sx={{
            border: `1px solid ${userData.business.color}`,
          }}
          minW={{ base: 'unset', md: '400px', xl: '450px' }}
          maxW={{ base: '360px', md: 'unset' }}
        >
          <Flex w="100%" mb="10px" gap={2} justifyContent={'space-between'}>
            <Button
              variant="brand"
              fontSize="sm"
              fontWeight="500"
              onClick={() => navigate('notifications')}
              sx={{ alignItems: 'center' }}
              leftIcon={<Icon as={MdNotificationsNone} h="18px" w="18px" />}
              px="10px"
              py="2px"
            >
              {t('Notifications')}
            </Button>
            <Button
              variant="brand"
              color="white"
              fontSize="sm"
              fontWeight="500"
              isLoading={isLoadingStatusChange}
              onClick={handleReadAllNotificationsClick}
              sx={{ alignItems: 'center' }}
              rightIcon={<Icon as={MdCheck} h="18px" w="18px" />}
              px="10px"
              py="2px"
            >
              {t('Mark all read')}
            </Button>
            {/* <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer"
            >
            </Text> */}
          </Flex>
          <Flex flexDirection="column">
            {unreadNotifications.map((notification: NotificationItem) => {
              return <NotificationCard key={notification.id} notification={notification} displayFromNavbar />;
            })}
            {unreadNotifications.length === 0 && (
              <Text fontSize={'sm'} fontWeight={'700'}>
                {t("You don't have any unread notifications")}
              </Text>
            )}
          </Flex>
        </MenuList>
      </Menu>

      <Menu>
        <MenuButton ml="10px">
          {/* <Icon
            mt="6px"
            as={FaGlobeAsia}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          /> */}
          <IconBox
            // w="56px"
            // h="56px"
            color={navbarIcon}
            icon={<FontAwesomeIcon size="xl" color={navbarIcon} icon={faLanguage} />}
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          me={{ base: '30px', md: 'unset' }}
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          sx={{
            border: `1px solid ${userData.business.color}`,
          }}
          minW={{ base: 'unset' }}
          maxW={{ base: '360px', md: 'unset' }}
        >
          {/* <Image src={navImage} borderRadius="16px" mb="28px" /> */}
          <Flex flexDirection="column" gap={3} position="relative">
            <Button
              rightIcon={i18n.language === 'he' ? <CheckIcon color="green.500" /> : null}
              variant={i18n.language === 'he' ? 'brand' : 'action'}
              onClick={() => changeLanguage('he')}
            >
              עברית 🇮🇱
            </Button>
            <Button
              onClick={() => changeLanguage('en')}
              variant={i18n.language === 'en' ? 'brand' : 'action'}
              borderColor={borderButton}
              rightIcon={i18n.language === 'en' ? <CheckIcon color="green.500" /> : null}
            >
              English 🇺🇸
            </Button>
          </Flex>
        </MenuList>
      </Menu>

      <Button variant="no-hover" bg="transparent" p="0px" minW="unset" minH="unset" h="18px" w="max-content" onClick={toggleColorMode}>
        <Icon me="10px" w="23px" h="23px" color={navbarIcon} as={colorMode === 'light' ? IoMdMoon : IoMdSunny} />
      </Button>
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color={'white'}
            name={`${user.firstName} ${user.lastName}`}
            src={business.businessLogo?.path ?? ''}
            bg={userData.business.color}
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          gap={20}
          borderRadius="20px"
          bg={menuBg}
          sx={{
            border: `1px solid ${userData.business.color}`,
          }}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, {capitalize(user.firstName)}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ bg: ethBg }}
              _focus={{ bg: ethBg }}
              bg={settingBg}
              borderRadius="8px"
              px="14px"
              onClick={() => navigate('/admin/settings/profile')}
            >
              <Text fontSize="sm">{t('Profile')}</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: ethBg }}
              _focus={{ bg: ethBg }}
              bg={settingBg}
              borderRadius="8px"
              px="14px"
              onClick={() => navigate('/admin/settings/business')}
            >
              <Text fontSize="sm">{t('Business')}</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: ethBg }}
              _focus={{ bg: ethBg }}
              bg={settingBg}
              borderRadius="8px"
              px="14px"
              onClick={() => navigate('/admin/settings/treatments')}
            >
              <Text fontSize="sm">{t('Treatments')}</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: ethBg }}
              _focus={{ bg: ethBg }}
              bg={settingBg}
              borderRadius="8px"
              px="14px"
              onClick={() => navigate('/admin/settings/employees')}
            >
              <Text fontSize="sm">{t('Employees')}</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: ethBg }}
              _focus={{ bg: ethBg }}
              bg={settingBg}
              color="red.400"
              onClick={() => logout()}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">{t('Log out')}</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
