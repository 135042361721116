import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import Card from 'components/cards/Card';
import UserCard from 'components/cards/UserCard';
import AddUserForm from 'components/forms/AddUserForm';
import { AuthenticationContext } from 'providers/AuthProvider';
import { FunctionComponent, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose, MdExposurePlus1, MdPerson } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { deleteUser } from 'services/api/user';
import { CreateTypeEnum } from 'shared/enums';
import { canCreateBasedOnPlan } from 'shared/helpersFunctions';
import { useUsers } from 'shared/hooks/useUsers';
import { UserType } from 'shared/types/app.types';

interface UsersProps {
  businessId: string;
}

const Users: FunctionComponent<UsersProps> = ({ businessId }: UsersProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userData, toggleReachedLimitPlanModal } = useContext(AuthenticationContext);
  const { isOpen: openDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const [userToEditOrDelete, setUserToEditOrDelete] = useState<UserType | null>(null);

  const { users } = useUsers();
  const queryClient = useQueryClient();
  const toast = useToast();
  const initialRef = useRef();
  const finalRef = useRef();
  const { t } = useTranslation();
  const handleEditUsers = (user: UserType) => {
    onOpen();
    setUserToEditOrDelete(user);
  };

  const handleDeleteUsersOpenDialog = (user: UserType) => {
    onOpenDeleteDialog();
    setUserToEditOrDelete(user);
  };
  const { mutate: deleteUserMutation, isLoading: isLoadingUserDelete } = useMutation(deleteUser, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries('users');
      toast({
        title: t('User Deleted Successfully'),
        status: 'success',
        duration: 7000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('Ops error occurred try again later'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleDeleteEmployee = () => {
    if (!userToEditOrDelete) return;
    deleteUserMutation(userToEditOrDelete.id);
    onCloseDeleteDialog();
  };

  const closeModal = () => {
    setUserToEditOrDelete(null);
    onClose();
    onCloseDeleteDialog();
  };

  const newEmployeeDialog = () => {
    if (!canCreateBasedOnPlan(userData.business.plan, CreateTypeEnum.USER, users.length + 1)) {
      toggleReachedLimitPlanModal();
      return;
    }
    onOpen();
  };

  return (
    <SimpleGrid columns={{ base: 1, md: 4 }} gap="20px">
      {users &&
        users.map((user) => {
          return (
            <UserCard
              key={user.id}
              user={user}
              handleEditUser={handleEditUsers}
              handleDeleteUser={handleDeleteUsersOpenDialog}
              userFullName={`${user.firstName} ${user.lastName}`}
              image={user.photo?.path}
            />
          );
        })}
      <Card p="20px">
        <Flex flex={1} alignItems="center" justifyContent={'center'}>
          <Button
            variant="brand"
            color="white"
            fontSize="lg"
            fontWeight="700"
            borderRadius="15px"
            onClick={newEmployeeDialog}
            leftIcon={<Icon as={MdExposurePlus1} width="20px" height="20px" color="inherit" />}
            p="40px"
          >
            {t('Create User')}
          </Button>
        </Flex>
      </Card>

      {/* edit/create */}
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} closeOnOverlayClick={false} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex gap="5px" alignItems={'center'}>
              <Button ml={3} onClick={closeModal}>
                <Icon as={MdClose} width="20px" height="20px" color="inherit" />
              </Button>
              <Icon as={MdPerson} width="30px" height="30px" color="inherit" mx="7px" />
              {userToEditOrDelete ? t('Edit') : t('Create')} {t('User')}
            </Flex>
          </ModalHeader>
          <ModalBody pb={6}>
            <AddUserForm handleCloseFunction={closeModal} userToEditOrDelete={userToEditOrDelete} />
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* delete */}
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={openDeleteDialog || false} onClose={onCloseDeleteDialog}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Button ml={3} onClick={closeModal}>
              <Icon as={MdClose} width="20px" height="20px" color="inherit" />
            </Button>
            {t('Delete')}
            {userToEditOrDelete && ` ${userToEditOrDelete.firstName} ${userToEditOrDelete.lastName}`} ?
          </ModalHeader>
          <ModalBody pb={6}>
            <Box gap="30px">
              <Text>{t('are you sure you want to delete this user?')}</Text>
              <Flex gap="20px" justifyContent={'end'} mt="30px">
                <Button onClick={closeModal}>{t('Cancel')}</Button>
                <Button
                  isLoading={isLoadingUserDelete}
                  colorScheme="teal"
                  type="submit"
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  onClick={handleDeleteEmployee}
                >
                  {t('Delete')}
                </Button>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </SimpleGrid>
  );
};

export default Users;
