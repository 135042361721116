// Chakra imports
import { Avatar, AvatarGroup, Box, Button, Flex, Icon, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/cards/Card';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Assets
import { IoMdClock, IoMdTrash } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import { ADMIN } from 'shared/consts';
import { EmployeeEntityType, ScheduleType, TreatmentEntityType } from 'shared/types/app.types';

interface TreatmentCardProps {
  image: string;
  treatmentName: string;
  treatment: TreatmentEntityType;
  handleEditTreatment: (treatment: TreatmentEntityType) => void;
  handleDeleteTreatment: (treatment: TreatmentEntityType) => void;
  employees: EmployeeEntityType[];
  price: string | number;
  schedule: ScheduleType;
  time: string;
}

export default function TreatmentCard({
  image,
  treatmentName,
  treatment,
  handleEditTreatment,
  handleDeleteTreatment,
  employees,
  schedule,
  price,
  time,
}: TreatmentCardProps) {
  const editBtnColor = useColorModeValue('brandScheme.100', 'brand.400');
  const { userData } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorBid = useColorModeValue('brand.500', 'white');

  const renderSchedule = () => {
    switch (schedule) {
      case 'OPEN':
        return (
          <Box>
            <Text color="green.400">{t('Open')}</Text>
          </Box>
        );
      case 'BLOCK':
        return (
          <Box>
            <Text color="red.400">{t('Block')}</Text>
          </Box>
        );
      case 'TAKE_ACTION':
        return (
          <Box>
            <Text color="orange.500">{t('Take Action')}</Text>
          </Box>
        );

      default:
        return <></>;
    }
  };

  return (
    <Card p="20px" maxW="sm">
      <Flex direction={{ base: 'column' }} justify="center" h="100%">
        <Flex mb={{ base: '20px', '2xl': '20px' }} justifyContent="space-between">
          <Button
            bg={editBtnColor}
            _hover={{ bg: 'brand.600' }}
            _active={{ bg: 'brand' }}
            _focus={{ bg: 'brand' }}
            p="0px !important"
            borderRadius="50%"
            minW="42px"
            isDisabled={userData.user.role.name !== ADMIN}
            h="42px"
            onClick={() => handleEditTreatment(treatment)}
          >
            <Icon transition="0.2s linear" w="20px" h="20px" as={MdEdit} />
          </Button>
          <Button
            color="red.600"
            _hover={{ bg: 'red.100' }}
            _active={{ bg: 'brand' }}
            _focus={{ bg: 'brand' }}
            backdropFilter={'blur(400px)'}
            p="0px !important"
            isDisabled={userData.user.role.name !== ADMIN}
            borderRadius="50%"
            minW="42px"
            h="42px"
            onClick={() => handleDeleteTreatment(treatment)}
          >
            <Icon transition="0.2s linear" w="25px" h="25px" as={IoMdTrash} />
          </Button>
        </Flex>
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex
            justify="space-between"
            gap={2}
            flexDirection="column"
            direction={{
              base: 'row',
              md: 'column',
              lg: 'row',
              xl: 'column',
              '2xl': 'row',
            }}
          >
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize={{
                  base: 'xl',
                  md: 'lg',
                  lg: 'lg',
                  xl: 'lg',
                  '2xl': 'md',
                  '3xl': 'lg',
                }}
                mb="5px"
                fontWeight="bold"
                me="14px"
              >
                {treatmentName}
              </Text>
              {employees.length > 0 ? (
                <Text
                  color="secondaryGray.600"
                  fontSize={{
                    base: 'sm',
                  }}
                  fontWeight="400"
                >
                  {t('By Employees')}
                </Text>
              ) : (
                <Text
                  fontSize={{
                    base: 'sm',
                  }}
                  fontWeight="600"
                >
                  {t('No Employees Assigned')}
                </Text>
              )}
            </Flex>
            {employees && (
              <Flex w="100%">
                <Tooltip
                  hasArrow
                  placement="top-start"
                  label={employees.map((emp) => {
                    return (
                      <Text key={emp.id} fontWeight="500">
                        {emp.firstName} {emp.lastName}
                      </Text>
                    );
                  })}
                  size="sm"
                >
                  <AvatarGroup
                    max={5}
                    w="40%"
                    color={textColorBid}
                    spacing="-1"
                    size="md"
                    mt={{
                      base: '0px',
                      md: '10px',
                      lg: '0px',
                      xl: '10px',
                      '2xl': '0px',
                    }}
                  >
                    {employees.map((emp, key) => (
                      <Avatar key={emp.id} src={emp.photo?.path} w="45px" h="45px" name={`${emp.firstName} ${emp.lastName}`} />
                    ))}
                  </AvatarGroup>
                </Tooltip>
              </Flex>
            )}
          </Flex>
          <Flex mt={3}>{renderSchedule()}</Flex>
          <Flex
            align={{
              base: 'center',
              md: 'start',
              lg: 'center',
              xl: 'start',
              '2xl': 'center',
            }}
            justify="space-between"
            direction={{
              base: 'row',
              md: 'column',
              lg: 'row',
              xl: 'column',
              '2xl': 'row',
            }}
            mt="15px"
          >
            <Flex justifyContent={'space-between'} width="100%">
              <Button variant="brand" fontSize="sm" borderRadius="15px" leftIcon={<Icon as={IoMdClock} width="20px" height="20px" color="inherit" />}>
                <Flex>
                  <Text fontWeight="900" ml={1}>
                    {time}
                    {` ${t('Minutes')}`}
                  </Text>
                  <Text fontWeight="500">:{t('Time')}</Text>
                </Flex>
              </Button>
              <Button variant="brand" color="white" fontSize="sm" fontWeight="700" borderRadius="15px" leftIcon={<>₪</>}>
                <Flex>
                  <Text fontWeight="900" ml={1}>
                    {price}
                  </Text>
                  <Text fontWeight="500">:{t('Price')}</Text>
                </Flex>
              </Button>
            </Flex>
            {/* <Link
              href={download}
              mt={{
                base: "0px",
                md: "10px",
                lg: "0px",
                xl: "10px",
                "2xl": "0px",
              }}
            ></Link> */}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
