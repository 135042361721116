export const setURLParam = (param: string, value: string, navigate: any) => {
  // Get the current search parameters
  const params = new URLSearchParams(window.location.search);

  // Set or update the parameter
  params.set(param, value);

  // Construct the new URL with updated search parameters
  const newUrl = `${window.location.pathname}?${params.toString()}`;

  // Use navigate to change the URL without reloading the page
  navigate(newUrl);
};

export const getURLParam = (param: string) => {
  const url = new URL(window.location.href);
  return url.searchParams.get(param);
};

export const resetURLParam = (param: string) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);
  window.history.pushState({}, '', url);
};
