// Chakra imports
import { Avatar, Flex, Icon, Image, useToast } from '@chakra-ui/react';

import { MdPerson } from 'react-icons/md';

import { Button, IconButton, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import { useMutation } from 'react-query';
import { updateBusinessBgImage, updateBusinessLogo } from 'services/api/business';
import { FileEntity } from 'shared/types/app.types';

export default function Banner({
  banner,
  name,
  phone,
  color,
  businessType,
  followers,
  email,
  following,
  bgImage,
  businessId,
  logo,
  ...rest
}: {
  banner: string;
  name: string;
  color: string;
  email: string;
  businessId: string;
  phone: string;
  businessType: string;
  logo?: FileEntity;
  bgImage?: FileEntity;
  [x: string]: any;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setBusiness } = useContext(AuthenticationContext);
  const [businessLogo, setBusinessLogo] = useState<string>(logo?.path ?? '');
  const [businessBgImage, setBusinessBgImage] = useState<string>(bgImage?.path ?? '');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [editType, setEditType] = useState<'logo' | 'background' | null>(null);
  const { t } = useTranslation();
  const toast = useToast();

  const { mutate: logoMutation, isLoading: isLoadingLogoMutation } = useMutation(updateBusinessLogo, {
    onSuccess: async (response) => {
      setBusinessLogo(`${response?.url ?? banner}?t=${new Date().getTime()}`);
      setBusiness({
        businessLogo: {
          path: response.url,
        },
      });
      toast({
        title: t('Logo upload successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('Ops error occurred try again later'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const { mutate: businessBgMutation, isLoading: isLoadingBusinessBgMutation } = useMutation(updateBusinessBgImage, {
    onSuccess: async (response) => {
      setBusinessBgImage(`${response?.url ?? banner}?t=${new Date().getTime()}`);
      setBusiness({
        bgBusinessPhoto: {
          path: response.url,
        },
      });
      toast({
        title: t('Background image upload successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('Ops error occurred try again later'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleFileUpload = async () => {
    if (!selectedFile || !editType) return;

    if (editType === 'logo') {
      logoMutation({
        businessDataFromForm: { file: selectedFile },
        businessId,
      });
    } else if (editType === 'background') {
      businessBgMutation({
        businessDataFromForm: { file: selectedFile },
        businessId,
      });
    }

    onClose();
    setSelectedFile(null);
    setEditType(null);
  };

  return (
    <Flex alignItems="center" display="flex" flexDirection={'column'} {...rest}>
      {/* Background Image with Edit Icon */}
      <Flex position="relative" w="100%" h="300px">
        <Image src={businessBgImage} w="100%" h="100%" objectFit="cover" borderRadius="20px" />
        <IconButton
          aria-label={t('Edit Background')}
          icon={<MdEdit />}
          position="absolute"
          top="10px"
          right="10px"
          onClick={() => {
            setEditType('background');
            onOpen();
          }}
          variant="outline"
          bg="white"
          color="gray.600"
          borderRadius="full"
          _hover={{ bg: 'gray.100' }}
        />
      </Flex>

      {/* Avatar with Edit Icon */}
      <Flex
        alignItems={'center'}
        justifyContent="center"
        w="100%"
        sx={{
          position: 'relative',
          top: '-40px',
        }}
      >
        <Flex flexDirection={'column'} position="relative">
          <Avatar src={businessLogo} size="2xl" h="105px" w="105px" name={name} icon={<Icon as={MdPerson} />} boxShadow={`1px 1px 5px ${color}`} />
          <IconButton
            aria-label={t('Edit Logo')}
            icon={<MdEdit />}
            position="absolute"
            bottom="0"
            right="0"
            onClick={() => {
              setEditType('logo');
              onOpen();
            }}
            variant="outline"
            bg="white"
            color="gray.600"
            borderRadius="full"
            _hover={{ bg: 'gray.100' }}
          />
        </Flex>
      </Flex>

      {/* Modal for Upload */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editType === 'logo' ? `${t('Edit Logo')}` : `${t('Edit Background')}`}</ModalHeader>
          <ModalBody>
            <Input type="file" accept="image/*" onChange={(e) => setSelectedFile(e.target.files?.[0] || null)} />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              isLoading={isLoadingBusinessBgMutation || isLoadingLogoMutation}
              onClick={handleFileUpload}
              disabled={!selectedFile}
            >
              {t('Upload')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
