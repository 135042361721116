import { Box, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Tooltip } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useState } from 'react';

function SliderThumbWithTooltip({ zoomValue, setZoomValue }: { zoomValue: number; setZoomValue: Dispatch<SetStateAction<number>> }) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Box position="fixed" left="2" top="70%" transform="translateY(-50%)" height="300px" zIndex="1000">
      <Slider
        id="slider"
        defaultValue={zoomValue}
        value={zoomValue}
        orientation="vertical"
        min={0}
        max={100}
        colorScheme="brand"
        onChange={(v) => setZoomValue(v)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        height="100%"
      >
        <SliderMark value={25} ml="2" fontSize="sm">
          25%
        </SliderMark>
        <SliderMark value={50} ml="2" fontSize="sm">
          50%
        </SliderMark>
        <SliderMark value={75} ml="2" fontSize="sm">
          75%
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <Tooltip hasArrow bg="teal.500" color="white" placement="right" isOpen={showTooltip} label={`${zoomValue}%`}>
          <SliderThumb h={'20px'} w={'20px'} />
        </Tooltip>
      </Slider>
    </Box>
  );
}

export default SliderThumbWithTooltip;
