// Chakra imports
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { Field, Form, Formik, FormikErrors } from 'formik';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useOutletContext } from 'react-router-dom';
import { validateConfirmPassword, validatePassword } from 'shared/helpersFunctions';

export default function ChangePassword() {
  const hashParam = useOutletContext();
  // create email and password hook vars
  const errorColorBrand = useColorModeValue('red.500', 'red.300');
  const textColorSecondary = 'gray.400';

  const { resetPassMutation, isErrorResetPass, isLoadingResetPass } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const disableButton = (
    errors: FormikErrors<{
      newPassword: string;
      confirmNewPassword: string;
    }>,
  ) => {
    return !!Object.keys(errors).length;
  };
  const handleClick = () => setShow(!show);
  return (
    <Formik
      initialValues={{ newPassword: '', confirmNewPassword: '' }}
      onSubmit={(values, actions) => {
        // Add your logic here to handle the submission with the new password fields
        // For example, you might want to call a mutation to update the password
        resetPassMutation({
          password: values.newPassword,
          hash: hashParam as string,
        });
        actions.setSubmitting(false);
      }}
    >
      {(props) => (
        <Form>
          <Field name="newPassword" validate={(value: string) => validatePassword(value, t)}>
            {({ field, form }: { field: any; form: any }) => (
              <FormControl mb="20px" isInvalid={form.errors.newPassword && form.touched.newPassword} isRequired={true}>
                <FormLabel htmlFor="newPassword">{t('New Password')}</FormLabel>
                <InputGroup size="md">
                  <Input
                    {...field}
                    id="newPassword"
                    fontSize="sm"
                    placeholder="Min. 8 characters"
                    size="lg"
                    type={show ? 'text' : 'password'}
                    variant="auth"
                  />
                  <InputLeftElement display="flex" alignItems="center" mt="4px">
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: 'pointer' }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputLeftElement>
                </InputGroup>
                <FormErrorMessage>{form.errors.newPassword}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field
            name="confirmNewPassword"
            validate={(confirmNewPassword: string) => validateConfirmPassword(props.values.newPassword, confirmNewPassword, t)}
          >
            {({ field, form }: { field: any; form: any }) => (
              <FormControl mb="20px" isInvalid={form.errors.confirmNewPassword && form.touched.confirmNewPassword} isRequired={true}>
                <FormLabel htmlFor="confirmNewPassword">{t('Confirm New Password')}</FormLabel>
                <InputGroup size="md">
                  <Input
                    {...field}
                    id="confirmNewPassword"
                    fontSize="sm"
                    placeholder={t('Confirm new password')}
                    size="lg"
                    type={show ? 'text' : 'password'}
                    variant="auth"
                  />
                  <InputLeftElement display="flex" alignItems="center" mt="4px">
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: 'pointer' }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputLeftElement>
                </InputGroup>
                <FormErrorMessage>{form.errors.confirmNewPassword}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          {isErrorResetPass && (
            <>
              <Text color={errorColorBrand} fontSize="sm" mb="12px" fontWeight="500">
                {t('Error resetting your password')}
              </Text>
            </>
          )}
          <Button
            isLoading={props.isSubmitting || isLoadingResetPass}
            isDisabled={disableButton(props.errors)}
            colorScheme="teal"
            type="submit"
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            my="24px"
          >
            {t('Change Password')}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
