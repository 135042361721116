import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getCategories } from 'services/api/treatment';
import { ACTIVE } from 'shared/consts';
import { CategoryEntity } from 'shared/types/app.types';

export const useCategories = ({ businessId }: { businessId: string }) => {
  const { userData } = useContext(AuthenticationContext);
  const { data: categories, isLoading: isLoadingCategories } = useQuery<CategoryEntity[]>(['categories'], () => getCategories(businessId), {
    enabled: userData.user.status.name === ACTIVE,
    refetchOnMount: false,
  });

  return { categories: categories ?? [], isLoadingCategories };
};
