import { useContext } from 'react';
// Chakra imports
import { Avatar, Flex, Icon } from '@chakra-ui/react';
import { AuthenticationContext } from 'providers/AuthProvider';

import defaultImage from 'assets/img/avatars/1.png';
import { MdPerson } from 'react-icons/md';
import { BusinessType, FileEntity } from 'shared/types/app.types';

export default function Banner({
  banner,
  name,
  phone,
  color,
  businessType,
  followers,
  email,
  following,
  logo,
  ...rest
}: {
  banner: string;
  name: string;
  color: string;
  email: string;
  phone: string;
  businessType: BusinessType;
  logo?: FileEntity;
  [x: string]: any;
}) {
  const { userData } = useContext(AuthenticationContext);
  const { user } = userData;
  if (!userData) {
    return <></>;
  }

  return (
    <Flex
      alignItems="center"
      display="flex"
      flexDirection={'column'}
      // justifyContent="space-between"
      // {...rest}
    >
      <Flex
        alignItems={'center'}
        justifyContent="center"
        w="100%"
        sx={
          {
            // position: "relative",
            // top: "-40px",
          }
        }
      >
        <Flex flexDirection={'column'}>
          <Avatar
            src={logo ? logo.path : defaultImage}
            size={{ base: 'xl', md: 'xl', lg: 'xl', '3xl': '2xl' }}
            h={{ base: '120px', md: '120px', lg: '130px', '3xl': '145px' }}
            w={{ base: '120px', md: '120px', lg: '130px', '3xl': '145px' }}
            key={name}
            icon={<Icon as={MdPerson} display="flex" width="20px" height="20px" color="inherit" />}
            name={name}
            boxShadow={`1px 1px 5px ${color}`}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
