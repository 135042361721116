import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getAppointmentsByDate } from 'services/api/appointments';
import { ACTIVE } from 'shared/consts';
import { AppointmentEntity, BusinessEntityType } from 'shared/types/app.types';

export const useSerializedAppointmentsByGivenDates = ({
  businessId,
  fromDate,
  toDate,
  businessData,
}: {
  businessId: string;
  fromDate: string;
  toDate: string;
  businessData: BusinessEntityType;
}) => {
  const { userData } = useContext(AuthenticationContext);
  const { data: appointmentsOfDate, isLoading: isLoadingAppointmentsOfDate } = useQuery<any[]>(
    ['calendar', `${fromDate}-${toDate}`],
    () => getAppointmentsByDate(businessId, fromDate, toDate),
    {
      select: (appointmentsOfDate: AppointmentEntity[]) => {
        return appointmentsOfDate.map((item) => {
          return {
            ...item,
            ...{
              // title: `${item.customerId.firstName} ${item.customerId.lastName}`,
              id: item.id,
              start: item.dateOfAppointment + `T${item.startOfAppointment}`,
              end: item.dateOfAppointment + `T${item.endOfAppointment}`,
              // end:
              //   item.dateOfAppointment +
              //   `T${addMinutesToTime(
              //     item.startOfAppointment,
              //     item.treatments
              //       .map((item: any) => +item.timeOfTreatment)
              //       .reduce((acc: number, time: number) => acc + time, 0)
              //   )}`,
              // url: "https://www.fullcalendar.com",
              interactive: true,
              // businessId: businessData,
            },
          };
        });
      },
      enabled: userData.user.status.name === ACTIVE,
      staleTime: 60000,
    },
  );

  return {
    appointmentsOfDate: isLoadingAppointmentsOfDate ? [] : appointmentsOfDate,
    isLoadingAppointmentsOfDate,
  };
};
