import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import DefaultAuth from 'layouts/auth/Default';
// Assets
import illustration from 'assets/img/auth/auth.png';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { MdEmail, MdOutlineRemoveRedEye, MdPerson, MdPhone } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { validateConfirmPassword, validateEmail, validatePassword } from 'shared/helpersFunctions';
import validator from 'validator';

type SignUpForm = {
  email: string;
  fullName: string;
  phone: string;
  password: string;
  confirmPassword: string;
};

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  // create email and password hook vars
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handleClick = () => setShow(!show);
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const errorColorBrand = useColorModeValue('red.500', 'red.300');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const { registerUserMutation, isRegistrationError } = useContext(AuthenticationContext);

  const submitFormLogin = (values: SignUpForm, actions: FormikHelpers<SignUpForm>) => {
    const fullNameArr = values.fullName.trim().split(' ');
    const fullNameData =
      fullNameArr.length > 1
        ? {
            firstName: fullNameArr[0],
            lastName: fullNameArr[fullNameArr.length - 1],
          }
        : {
            firstName: fullNameArr[0],
            lastName: '',
          };
    registerUserMutation({
      ...fullNameData,
      email: values.email,
      password: values.password,
      phone: values.phone,
    });
    setTimeout(() => {
      actions.setSubmitting(false);
    }, 1000);
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '4vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            {t('Create account')}
          </Heading>
          <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
            {t('Lets create a new account for you')}
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <Formik
            initialValues={{
              email: '',
              password: '',
              confirmPassword: '',
              phone: '',
              fullName: '',
            }}
            onSubmit={submitFormLogin}
          >
            {(props) => (
              <Form>
                <Field name="fullName">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl mb="10px" isRequired={true} isInvalid={form.errors.fullName && form.touched.fullName}>
                      <FormLabel htmlFor="fullName">{t('Full Name')}</FormLabel>

                      <InputGroup size="md">
                        <Input
                          {...field}
                          id="fullName"
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: '0px', md: '0px' }}
                          type="fullName"
                          placeholder={t('Your Full Name')}
                          fontWeight="500"
                          size="lg"
                        />
                        <InputLeftElement display="flex" alignItems="center" mt="4px">
                          <Icon color={textColorSecondary} as={MdPerson} />
                        </InputLeftElement>
                      </InputGroup>
                      <FormErrorMessage>{form.errors.fullName}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email" validate={(value: string) => validateEmail(value, t)}>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl mb="10px" isRequired={true} isInvalid={form.errors.email && form.touched.email}>
                      <FormLabel htmlFor="email">{t('Email')}</FormLabel>

                      <InputGroup size="md">
                        <Input
                          {...field}
                          id="email"
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: '0px', md: '0px' }}
                          type="email"
                          placeholder="mail@simmmple.com"
                          fontWeight="500"
                          size="lg"
                        />
                        <InputLeftElement display="flex" alignItems="center" mt="4px">
                          <Icon color={textColorSecondary} as={MdEmail} />
                        </InputLeftElement>
                      </InputGroup>
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password" validate={(value: string) => validatePassword(value, t)}>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl mb="10px" isRequired={true} isInvalid={form.errors.password && form.touched.password}>
                      <FormLabel htmlFor="password">{t('Password')}</FormLabel>

                      <InputGroup size="md">
                        <Input
                          {...field}
                          id="password"
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: '0px', md: '0px' }}
                          type={show ? 'text' : 'password'}
                          placeholder={t('Min. 8 characters')}
                          fontWeight="500"
                          size="lg"
                        />
                        <InputLeftElement display="flex" alignItems="center" mt="4px">
                          <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: 'pointer' }}
                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={handleClick}
                          />
                        </InputLeftElement>
                      </InputGroup>
                      <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="confirmPassword" validate={() => validateConfirmPassword(props.values.password, props.values.confirmPassword, t)}>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl mb="10px" isRequired={true} isInvalid={form.errors.confirmPassword && form.touched.confirmPassword}>
                      <FormLabel htmlFor="confirmPassword">{t('Confirm Password')}</FormLabel>

                      <InputGroup size="md">
                        <Input
                          {...field}
                          id="confirmPassword"
                          type={show ? 'text' : 'password'}
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: '0px', md: '0px' }}
                          placeholder={t('Min. 8 characters')}
                          fontWeight="500"
                          size="lg"
                        />
                        <InputLeftElement display="flex" alignItems="center" mt="4px">
                          <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: 'pointer' }}
                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={handleClick}
                          />
                        </InputLeftElement>
                      </InputGroup>
                      <FormErrorMessage>{form.errors.confirmPassword}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="phone" validate={() => (!validator.isMobilePhone(props.values.phone, 'he-IL') ? t('Phone Number is not valid') : '')}>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl mb="20px" isInvalid={form.errors.phone && form.touched.phone} isRequired={true}>
                      <FormLabel htmlFor="phone">{t('Phone')}</FormLabel>
                      <InputGroup size="md">
                        <Input
                          {...field}
                          id="phone"
                          fontSize="sm"
                          placeholder="0502223333"
                          size="lg"
                          type={'tel'}
                          variant="auth"
                          sx={{ textAlign: 'right' }}
                        />
                        <InputLeftElement display="flex" alignItems="center" mt="4px">
                          <Icon color={textColorSecondary} as={MdPhone} />
                        </InputLeftElement>
                      </InputGroup>
                      <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {isRegistrationError && (
                  <>
                    <Text color={errorColorBrand} fontSize="sm" mb="12px" fontWeight="500">
                      {t('Email or Password are invalid')}
                    </Text>
                  </>
                )}
                <Button
                  isLoading={props.isSubmitting}
                  isDisabled={!!Object.keys(props.errors).length}
                  colorScheme="teal"
                  type="submit"
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                >
                  {t('Sign Up')}
                </Button>
              </Form>
            )}
          </Formik>

          <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" mt="0px">
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              {t('Already have an account?')}
              <NavLink to="/auth/sign-in">
                <Text px={2} color={textColorBrand} as="span" ms="5px" fontWeight="500">
                  {t('Sign in')}
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
