import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getTreatments } from 'services/api/treatment';
import { FlavorOption } from 'shared/components/SelectComponents';
import { ACTIVE } from 'shared/consts';
import { serializeTreatments } from 'shared/helpersFunctions';
import { TreatmentEntityType } from 'shared/types/app.types';

export const useTreatments = (returnAll?: boolean) => {
  const { userData } = useContext(AuthenticationContext);
  const {
    data: treatments,
    isLoading: isLoadingTreatments,
    isSuccess: isLoadingTreatmentsSuccess,
  } = useQuery<(TreatmentEntityType & FlavorOption)[]>(['treatments'], () => getTreatments(userData.business.id), {
    select: (treatments) => {
      return treatments
        ? serializeTreatments(treatments).filter((treat) => {
            return treat.employees.length || returnAll;
          })
        : [];
    },
    enabled: userData.user.status.name === ACTIVE,
    refetchOnMount: false,
  });

  return { treatments, isLoadingTreatments, isLoadingTreatmentsSuccess };
};
