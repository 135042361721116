import { Avatar, Box, Icon, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { chakraComponents, GroupBase, OptionBase, SelectComponentsConfig } from 'chakra-react-select';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { EmployeeEntityType, TreatmentSelectType } from 'shared/types/app.types';

export interface FlavorOption extends OptionBase {
  label: string;
  value: string;
  Icon: FC;
  iconColor: string;
}

export function employeesSelectWrapper({
  selectInputWidth,
  selectInputBgColor,
}: {
  selectInputWidth: any;
  selectInputBgColor: any;
}): SelectComponentsConfig<FlavorOption & EmployeeEntityType, true, GroupBase<FlavorOption & EmployeeEntityType>> {
  return {
    Option: ({ children, ...props }) => {
      const iconColor = useColorModeValue('brandScheme.700', 'brandScheme.100');
      return (
        <chakraComponents.Option {...props}>
          <Icon as={props.data.Icon} color={iconColor} mx={2} h={5} w={5} />
          {children}
        </chakraComponents.Option>
      );
    },
    SelectContainer: ({ children, ...props }) => {
      return (
        <chakraComponents.SelectContainer {...props}>
          <Box
            sx={{
              width: selectInputWidth,
              bgColor: selectInputBgColor,
              borderRadius: '15px',
            }}
          >
            {children}
          </Box>
        </chakraComponents.SelectContainer>
      );
    },
    MultiValueContainer: ({ children, ...props }) => {
      return (
        <chakraComponents.MultiValueContainer {...props}>
          <Tooltip label={props.data.label} size="sm">
            <Avatar
              key={props.data.label}
              name={props.data.label}
              src={props.data.photo?.path}
              h={{ base: '30px', md: '30px', lg: '35px', '3xl': '40px' }}
              w={{ base: '30px', md: '30px', lg: '35px', '3xl': '40px' }}
            />
          </Tooltip>
          {children}
        </chakraComponents.MultiValueContainer>
      );
    },
    MultiValueRemove: ({ children, ...props }) => (
      <chakraComponents.MultiValueRemove {...props}>
        <Icon as={MdClose} width="20px" height="20px" color="inherit" />
      </chakraComponents.MultiValueRemove>
    ),
    MultiValue: ({ children, ...props }) => (
      <chakraComponents.MultiValue {...props}>
        {/* In Order to display Avatar and not the name */}
        {/* {children} */}
      </chakraComponents.MultiValue>
    ),
  };
}

export const EmployeesSelectCustomComponents: SelectComponentsConfig<
  FlavorOption & EmployeeEntityType,
  true,
  GroupBase<FlavorOption & EmployeeEntityType>
> = {
  Option: ({ children, ...props }) => {
    const iconColor = useColorModeValue('brand.500', 'brandScheme.100');
    return (
      <chakraComponents.Option {...props}>
        <Icon as={props.data.Icon} color={iconColor} mx={2} h={5} w={5} />
        {children}
      </chakraComponents.Option>
    );
  },
  SelectContainer: ({ children, ...props }) => {
    return (
      <chakraComponents.SelectContainer {...props}>
        <Box>{children}</Box>
      </chakraComponents.SelectContainer>
    );
  },
  MultiValueContainer: ({ children, ...props }) => {
    return (
      <chakraComponents.MultiValueContainer {...props}>
        <Tooltip label={props.data.label} size="sm">
          <Avatar
            key={props.data.label}
            src={props.data.photo?.path}
            name={props.data.label}
            h={{ base: '30px', md: '30px', lg: '35px', '3xl': '40px' }}
            w={{ base: '30px', md: '30px', lg: '35px', '3xl': '40px' }}
          />
        </Tooltip>
        {children}
      </chakraComponents.MultiValueContainer>
    );
  },
  MultiValueRemove: ({ children, ...props }) => (
    <chakraComponents.MultiValueRemove {...props}>
      <Icon as={MdClose} width="20px" height="20px" color="inherit" />
    </chakraComponents.MultiValueRemove>
  ),
  MultiValue: ({ children, ...props }) => (
    <chakraComponents.MultiValue {...props}>
      {/* In Order to display Avatar and not the name */}
      {/* {children} */}
    </chakraComponents.MultiValue>
  ),
};

export const TreatmentsSelectCustomComponents: SelectComponentsConfig<TreatmentSelectType, true, GroupBase<TreatmentSelectType>> = {
  Option: ({ children, ...props }) => {
    const iconColor = useColorModeValue('brand.500', 'brandScheme.100');
    const { t } = useTranslation();
    return (
      <chakraComponents.Option {...props}>
        <Icon as={props.data.Icon} color={iconColor} mx={2} h={5} w={5} />
        {children}
        <Box p={1}>{`(${props.data.timeOfTreatment} ${t('minutes')})`}</Box>
      </chakraComponents.Option>
    );
  },
  SelectContainer: ({ children, ...props }) => (
    <chakraComponents.SelectContainer {...props}>
      <Box>{children}</Box>
    </chakraComponents.SelectContainer>
  ),
  MultiValueContainer: ({ children, ...props }) => (
    <chakraComponents.MultiValueContainer {...props}>
      <Tooltip label={props.data.label} size="sm">
        <Avatar
          key={props.data.label}
          name={props.data.label}
          h={{ base: '30px', md: '30px', lg: '35px', '3xl': '40px' }}
          w={{ base: '30px', md: '30px', lg: '35px', '3xl': '40px' }}
        />
      </Tooltip>
      {children}
    </chakraComponents.MultiValueContainer>
  ),
  ValueContainer: ({ children, ...props }) => {
    const { selectProps } = props;
    return (
      <chakraComponents.ValueContainer {...props}>
        {/* <Tooltip label={selectProps.value.label} size="sm">
          <Avatar
            key={selectProps.value.label}
            name={selectProps.value.label}
          />
        </Tooltip> */}
        {children}
      </chakraComponents.ValueContainer>
    );
  },
  MultiValueRemove: ({ children, ...props }) => (
    <chakraComponents.MultiValueRemove {...props}>
      <Icon as={MdClose} width="20px" height="20px" color="inherit" />
    </chakraComponents.MultiValueRemove>
  ),
  MultiValue: ({ children, ...props }) => (
    <chakraComponents.MultiValue {...props}>
      {/* In Order to display Avatar and not the name */}
      {/* {children} */}
    </chakraComponents.MultiValue>
  ),
};
export const SelectCustomComponents: SelectComponentsConfig<FlavorOption, false, GroupBase<FlavorOption>> = {
  Option: ({ children, ...props }) => (
    <chakraComponents.Option {...props}>
      <Icon as={props.data.Icon} color={props.data.iconColor} mx={2} h={5} w={5} />
      {children}
    </chakraComponents.Option>
  ),
  // ValueContainer: ({ children, ...props }) => (
  //   <chakraComponents.ValueContainer {...props}>
  //     <Tooltip label={props.label} size="sm">
  //       <Avatar key={props.label} name={props.label} />
  //     </Tooltip>
  //     {children}
  //   </chakraComponents.ValueContainer>
  // ),
  MultiValueRemove: ({ children, ...props }) => (
    <chakraComponents.MultiValueRemove {...props}>
      <Icon as={MdClose} width="20px" height="20px" color="inherit" />
    </chakraComponents.MultiValueRemove>
  ),
  MultiValue: ({ children, ...props }) => (
    <chakraComponents.MultiValue {...props}>
      {/* In Order to display Avatar and not the name */}
      {/* {children} */}
    </chakraComponents.MultiValue>
  ),
};
