// Chakra imports
import { Box, Button, Flex, Icon, Text, useColorMode, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/cards/Card';
import LineColorsChart from 'components/charts/LineColorsChart';
import MyBarChart from 'components/charts/MyBarChart';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FcLineChart } from 'react-icons/fc';
import { MdBarChart } from 'react-icons/md';
import { getPastMonths } from 'shared/helpersFunctions';
import { barSeries, lineOptions, lineSeries } from 'variables/charts';

export default function TreatmentsRevenue({ treatmentsRevenueData, rest }: { treatmentsRevenueData: any; [x: string]: any }) {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();
  const [lineChartDisplay, setBarChartDisplay] = useState<boolean>(false);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const options = useMemo(() => lineOptions(colorMode === 'dark'), [colorMode]);

  const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
  const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });
  return (
    <Card alignItems="center" flexDirection="column" w="100%" {...rest}>
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text me="auto" color={textColor} fontSize="xl" fontWeight="700" lineHeight="100%">
          {!lineChartDisplay ? t('Revenue Per Month') : t('Treatments Revenue')}
        </Text>
        <Box display="flex" gap={2}>
          <Button
            alignItems="center"
            justifyContent="center"
            bg={bgButton}
            isDisabled={!lineChartDisplay}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            w="37px"
            h="37px"
            lineHeight="100%"
            onClick={() => setBarChartDisplay(false)}
            borderRadius="10px"
            {...rest}
          >
            <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
          </Button>
          <Button
            alignItems="center"
            justifyContent="center"
            bg={bgButton}
            isDisabled={lineChartDisplay}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            w="37px"
            h="37px"
            lineHeight="100%"
            onClick={() => setBarChartDisplay(true)}
            borderRadius="10px"
            {...rest}
          >
            <Icon as={FcLineChart} color={iconColor} w="24px" h="24px" />
          </Button>
        </Box>
      </Flex>

      <Box h="350px" mt="auto" w="100%">
        {lineChartDisplay ? (
          <LineColorsChart key={colorMode} chartData={lineSeries(getPastMonths(), treatmentsRevenueData).series} chartOptions={options.options} />
        ) : (
          <MyBarChart chartOptions={options.options} chartData={barSeries(treatmentsRevenueData).series} />
        )}
      </Box>
    </Card>
  );
}
