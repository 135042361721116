import axiosConfig from 'shared/axiosConfig';
import { CreateAppointmentPayload, CreateUpdateAbsenceAppointmentDto, UpdateAppointmentDto } from 'shared/types/app.types';

export async function getAppointmentsByDate(businessId: string, fromDate: string, toDate: string) {
  const result = await axiosConfig.get('/appointments', {
    params: { businessId, fromDate, toDate },
  });
  return result.data;
}

export async function getEmployeeAvailabilityTimes(businessId: string, employeeId: string, sumTimeAppointments: number, givenDate: Date) {
  const result = await axiosConfig.get('/appointments/employee-availability', {
    params: {
      businessId,
      employeeId,
      sumTimeAppointments,
      givenDate: givenDate.toLocaleDateString('en-US'),
    },
  });
  return result.data;
}

export async function approveAppointment({
  approveData,
  appointmentId,
}: {
  approveData: { approve: boolean; notificationId: string };
  appointmentId: string;
}) {
  const result = await axiosConfig.patch(`/appointments/take-action/${appointmentId}`, approveData);
  return result.data;
}

export async function updateAppointment({
  updatedAppointment,
  appointmentId,
}: {
  updatedAppointment: UpdateAppointmentDto;
  appointmentId: string;
  onClose: () => void;
}) {
  const result = await axiosConfig.patch(`/appointments/${appointmentId}`, updatedAppointment);
  return result.data;
}

export async function createAppointment(payload: CreateAppointmentPayload) {
  const result = await axiosConfig.post('/appointments', payload);
  return result.data;
}

export async function createAbsence(payload: CreateUpdateAbsenceAppointmentDto) {
  const result = await axiosConfig.post('/appointments/absence', payload);
  return result.data;
}

export async function updateAbsence({ id, payload }: { id: string; payload: CreateUpdateAbsenceAppointmentDto }) {
  const result = await axiosConfig.patch(`/appointments/absence/${id}`, payload);
  return result.data;
}

export async function deleteAppointment({ appointmentId }: { appointmentId: string; onClose: () => void }) {
  const result = await axiosConfig.delete(`/appointments/${appointmentId}`);
  return result.data;
}
