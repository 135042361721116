// Chakra imports
import { Box, Button, Flex, Icon, Text, useColorMode, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/cards/Card';
import LineChart from 'components/charts/LineChart';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MdBarChart } from 'react-icons/md';
import { useQuery } from 'react-query';
import { getDashboardSixMonths } from 'services/api/business';
import { ACTIVE } from 'shared/consts';
import { getPastMonths } from 'shared/helpersFunctions';
// Assets
import { lineChartDataTotalSpent, lineChartOptionsTotalSpent } from 'variables/charts';

export default function AppointmentsPerMonth({ businessId, ...rest }: { businessId: string; [x: string]: any }) {
  const { t } = useTranslation();
  const { userData } = useContext(AuthenticationContext);
  const sevenPastMonths = getPastMonths();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { colorMode } = useColorMode();
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
  const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

  const {
    data: dashboardSixMonthsData,
    isLoading: isLoadingDashboardBasic,
    isSuccess: isSuccessDashboardBasic,
  } = useQuery<any>(['dashboard-six-months'], () => getDashboardSixMonths(businessId, sevenPastMonths), {
    enabled: userData.user.status.name === ACTIVE,
    refetchOnMount: 'always',
  });

  if (!dashboardSixMonthsData || isLoadingDashboardBasic) {
    return <Box></Box>;
  }
  const appointmentsAverage = Math.round(dashboardSixMonthsData.monthsCounts.reduce((acc: number, month: number) => acc + month, 0) / 6);

  // Chakra Color Mode

  return (
    <Card justifyContent="center" alignItems="center" flexDirection="column" w="100%" mb="0px" {...rest}>
      <Flex align="center" justify="space-between" w="100%" pe="20px" pt="5px">
        {/* <Button
          bg={boxBg}
          fontSize="sm"
          fontWeight="500"
          color={textColorSecondary}
          borderRadius="7px"
        >
          <Icon
            as={MdOutlineCalendarToday}
            color={textColorSecondary}
            me="4px"
          />
          This month
        </Button> */}
        <Flex flexDirection="row" gap={4} mx={2} me="20px" flex={2}>
          <Text color={textColor} fontSize="34px" textAlign="start" fontWeight="700" lineHeight="100%">
            {appointmentsAverage}
          </Text>
          <Flex align="center" mb="20px">
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500" mt="4px" me="12px">
              {t('Appointments per month on average')}
            </Text>
            {/* <Flex align="center">
              <Icon as={RiArrowUpSFill} color="green.500" me="2px" mt="2px" />
              <Text color="green.500" fontSize="sm" fontWeight="700">
                +2.45%
              </Text>
            </Flex> */}
          </Flex>

          {/* <Flex align="center">
            <Icon as={IoCheckmarkCircle} color="green.500" me="4px" />
            <Text color="green.500" fontSize="md" fontWeight="700">
              On track
            </Text>
          </Flex> */}
        </Flex>
        <Button
          ms="auto"
          alignItems="center"
          justifyContent="center"
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w="37px"
          h="37px"
          lineHeight="100%"
          borderRadius="10px"
          {...rest}
        >
          <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
        </Button>
      </Flex>
      <Flex w="100%" flexDirection={{ base: 'column', lg: 'row' }} flex={10}>
        <Box minH="320px" flex={8} mt="auto">
          {!isLoadingDashboardBasic && (
            <LineChart
              chartData={lineChartDataTotalSpent(dashboardSixMonthsData.monthsCounts ?? [0, 0, 0, 0, 0, 0])}
              chartOptions={lineChartOptionsTotalSpent(colorMode === 'dark', sevenPastMonths)}
            />
          )}
        </Box>
      </Flex>
    </Card>
  );
}
