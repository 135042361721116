import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import DefaultAuth from 'layouts/auth/Default';
// Assets
import illustration from 'assets/img/auth/recover-account.jpeg';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { MdEmail } from 'react-icons/md';
import { validateEmail } from 'shared/helpersFunctions';

export default function ResetPassword() {
  // create email and password hook vars

  const textColorSecondary = 'gray.400';
  const { t } = useTranslation();
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const errorColorBrand = useColorModeValue('red.500', 'red.300');
  const textColor = useColorModeValue('navy.700', 'white');
  const { forgotPasswordMutation, isErrorForgotPassword, isLoadingForgotPassword } = useContext(AuthenticationContext);

  const [show, setShow] = useState(false);
  const disableButton = (
    errors: FormikErrors<{
      email: string;
    }>,
  ) => {
    return !!Object.keys(errors).length;
  };

  const handleClick = () => setShow(!show);
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '4vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            {t('Account Recovery')}
          </Heading>
          <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
            {t('Enter your email to recover your account!')}
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <Formik
            initialValues={{ email: '' }}
            onSubmit={(values, actions) => {
              // Add your logic here to handle the submission with the new password fields
              // For example, you might want to call a mutation to update the password
              forgotPasswordMutation({
                email: values.email,
              });
              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <Form>
                <Field name="email" validate={(value: string) => validateEmail(value, t)}>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl mb="20px" isInvalid={form.errors.email && form.touched.email} isRequired={true}>
                      <FormLabel htmlFor="email">{t('Email Recovery')}</FormLabel>
                      <InputGroup size="md">
                        <Input {...field} id="email" fontSize="sm" placeholder="email@gmail.com" size="lg" variant="auth" />
                        <InputLeftElement display="flex" alignItems="center" mt="4px">
                          <Icon color={textColorSecondary} _hover={{ cursor: 'pointer' }} as={MdEmail} onClick={handleClick} />
                        </InputLeftElement>
                      </InputGroup>
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {isErrorForgotPassword && (
                  <>
                    <Text color={errorColorBrand} fontSize="sm" mb="12px" fontWeight="500">
                      {t('Email does not exist')}
                    </Text>
                  </>
                )}
                <Button
                  isLoading={props.isSubmitting || isLoadingForgotPassword}
                  isDisabled={disableButton(props.errors)}
                  colorScheme="teal"
                  type="submit"
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  my="24px"
                >
                  {t('Reset Password')}
                </Button>
              </Form>
            )}
          </Formik>
          <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" gap={2} mt="0px">
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              {t('Know the password?')}
              <NavLink to="/auth/sign-in">
                <Text px={2} color={textColorBrand} as="span" ms="5px" fontWeight="500">
                  {t('Sign in')}
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}
